import shock from "../../assets/img/shock.jpg";
import comfort2 from "../../assets/img/comfort2.svg";
import ser2 from "../../assets/img/ser2.svg";
import ico1 from "../../assets/img/ico1.svg";
import ico2 from "../../assets/img/ico2.svg";
import ico3 from "../../assets/img/ico3.svg";
import ico4 from "../../assets/img/ico4.svg";
import ico5 from "../../assets/img/ico5.svg";
import ico6 from "../../assets/img/ico6.svg";
import ico7 from "../../assets/img/ico7.svg";
import ico8 from "../../assets/img/ico8.svg";
import about from "../../assets/img/about.png";
import gallery from "../../assets/img/gallery.png";
import detailing_ico from "../../assets/img/detailing-ico.svg";
import paint_ico from "../../assets/img/paint-ico.svg";
import suspension_ico from "../../assets/img/suspension-ico.svg";
import absorber_ico from "../../assets/img/absorber-ico.svg";
import engine_ico from "../../assets/img/engine-ico.svg";
import ac_ico from "../../assets/img/ac-ico.svg";
import brakes_ico from "../../assets/img/brakes-ico.svg";
import bumper_ico from "../../assets/img/bumper-ico.svg";
import far_ico from "../../assets/img/lights-ico.svg";
import noiseoff_ico from "../../assets/img/mute-ico.svg";
import salon_ico from "../../assets/img/salon-ico.svg";
import rihtovka_ico from "../../assets/img/rihtovka-ico.svg";
import atm_ico from "../../assets/img/automatic-transmission-ico.svg";
import car_filter_ico from "../../assets/img/car-filter-ico.svg";
import car_key_ico from "../../assets/img/car-key-ico.svg";
import car_manual_gearbox_ico from "../../assets/img/car-manual-gearbox-ico.svg";
import diagnostic_ico from "../../assets/img/diagnostic-ico.svg";
import hybrid_ico from "../../assets/img/hybrid-ico.svg";
import oil_change_ico from "../../assets/img/oil-change-ico.svg";
import PC_diagnostics_ico from "../../assets/img/PC-diagnostics-ico.svg";
import car_lock_ico from "../../assets/img/car-lock-ico.svg";
import turbine_ico from "../../assets/img/turbine-ico.svg";
import volan_ico from "../../assets/img/volan-ico.svg";
import wheel_ico from "../../assets/img/wheel-ico.svg";
import chip_tuning_ico from "../../assets/img/tuning-chip-ico.svg";
import catalyst_ico from "../../assets/img/catalyst-ico.svg";
import energy_ico from "../../assets/img/energy-ico.svg";
import img1 from "../../assets/img/img1.jpg";
import img2 from "../../assets/img/img2.jpg";
import img3 from "../../assets/img/img3.jpg";
import img4 from "../../assets/img/img2.jpg";
import img5 from "../../assets/img/img3.jpg";
import amort_preview from "../../assets/img/amort-preview.png";
import amort_big from "../../assets/img/amort-big.png";
import amort_img1 from "../../assets/img/amort-img1.png";
import amort_img2 from "../../assets/img/amort-img2.png";
import amort_img3 from "../../assets/img/amort-img3.png";
import amort_img4 from "../../assets/img/amort-img4.png";
import amort_img5 from "../../assets/img/amort-img5.png";
import ww_preview from "../../assets/img/ww-preview.png";
import ww_big from "../../assets/img/ww-big.png";
import ww_img1 from "../../assets/img/ww-img1.png";
import ww_img2 from "../../assets/img/ww-img2.png";
import ww_img3 from "../../assets/img/ww-img3.png";
import ww_img4 from "../../assets/img/ww-img4.png";
import ww_img5 from "../../assets/img/ww-img5.png";
import hl_preview from "../../assets/img/hl-preview.png";
import hl_big from "../../assets/img/hl-big.png";
import hl_img1 from "../../assets/img/hl-img1.png";
import hl_img2 from "../../assets/img/hl-img2.png";
import hl_img3 from "../../assets/img/hl-img3.png";
import hl_img4 from "../../assets/img/hl-img4.png";
import hl_img5 from "../../assets/img/hl-img5.png";
import key_auto_preview from "../../assets/img/key-auto-preview.png";
import key_auto_big from "../../assets/img/key-auto-big.png";
import key_auto_img1 from "../../assets/img/key-auto-img1.png";
import key_auto_img2 from "../../assets/img/key-auto-img2.png";
import key_auto_img3 from "../../assets/img/key-auto-img3.png";
import key_auto_img4 from "../../assets/img/key-auto-img4.png";
import key_auto_img5 from "../../assets/img/key-auto-img5.png";
import simple_s_preview from "../../assets/img/simple-s-preview.png";
import simple_s_big from "../../assets/img/simple-s-big.png";
import simple_s_img1 from "../../assets/img/simple-s-img1.png";
import simple_s_img2 from "../../assets/img/simple-s-img2.png";
import simple_s_img3 from "../../assets/img/simple-s-img3.png";
import simple_s_img4 from "../../assets/img/simple-s-img4.png";
import simple_s_img5 from "../../assets/img/simple-s-img5.png";
import fcs_preview from "../../assets/img/fcs-preview.png";
import fcs_big from "../../assets/img/fcs-big.png";
import fcs_img1 from "../../assets/img/fcs-img1.png";
import fcs_img2 from "../../assets/img/fcs-img2.png";
import fcs_img3 from "../../assets/img/fcs-img3.png";
import fcs_img4 from "../../assets/img/fcs-img4.png";
import fcs_img5 from "../../assets/img/fcs-img5.png";
import autof_preview from "../../assets/img/autof-preview.png";
import autof_big from "../../assets/img/autof-big.png";
import autof_img1 from "../../assets/img/autof-img1.png";
import autof_img2 from "../../assets/img/autof-img2.png";
import autof_img3 from "../../assets/img/autof-img3.png";
import autof_img4 from "../../assets/img/autof-img4.png";
import autof_img5 from "../../assets/img/autof-img5.png";
import complex_preview from "../../assets/img/complex-preview.png";
import complex_big from "../../assets/img/complex-big.png";
import complex_img1 from "../../assets/img/complex-img1.png";
import complex_img2 from "../../assets/img/complex-img2.png";
import complex_img3 from "../../assets/img/complex-img3.png";
import complex_img4 from "../../assets/img/complex-img4.png";
import complex_img5 from "../../assets/img/complex-img5.png";
import sst_preview from "../../assets/img/sst-preview.png";
import sst_big from "../../assets/img/sst-big.png";
import sst_img1 from "../../assets/img/sst-img1.png";
import sst_img2 from "../../assets/img/sst-img2.png";
import sst_img3 from "../../assets/img/sst-img3.png";
import sst_img4 from "../../assets/img/sst-img4.png";
import sst_img5 from "../../assets/img/sst-img5.png";
import starterp_preview from "../../assets/img/starterp-preview.png";
import starterp_big from "../../assets/img/starterp-big.png";
import starterp_img1 from "../../assets/img/starterp-img1.png";
import starterp_img2 from "../../assets/img/starterp-img2.png";
import starterp_img3 from "../../assets/img/starterp-img3.png";
import starterp_img4 from "../../assets/img/starterp-img4.png";
import starterp_img5 from "../../assets/img/starterp-img5.png";
import mireco_preview from "../../assets/img/mireco-preview.png";
import mireco_big from "../../assets/img/mireco-big.png";
import mireco_img1 from "../../assets/img/mireco-img1.png";
import mireco_img2 from "../../assets/img/mireco-img2.png";
import mireco_img3 from "../../assets/img/mireco-img3.png";
import mireco_img4 from "../../assets/img/mireco-img4.png";
import mireco_img5 from "../../assets/img/mireco-img5.png";
import ecot_preview from "../../assets/img/ecot-preview.png";
import ecot_big from "../../assets/img/ecot-big.png";
import ecot_img1 from "../../assets/img/ecot-img1.png";
import ecot_img2 from "../../assets/img/ecot-img2.png";
import ecot_img3 from "../../assets/img/ecot-img3.png";
import ecot_img4 from "../../assets/img/ecot-img4.png";
import ecot_img5 from "../../assets/img/ecot-img5.png";
import sim_preview from "../../assets/img/sim-preview.png";
import sim_big from "../../assets/img/sim-big.png";
import sim_img1 from "../../assets/img/sim-img1.png";
import sim_img2 from "../../assets/img/sim-img2.png";
import sim_img3 from "../../assets/img/sim-img3.png";
import sim_img4 from "../../assets/img/sim-img4.png";
import sim_img5 from "../../assets/img/sim-img5.png";
import bogcar_preview from "../../assets/img/bogcar-preview.png";
import bogcar_big from "../../assets/img/bogcar-big.png";
import bogcar_img1 from "../../assets/img/bogcar-img1.png";
import bogcar_img2 from "../../assets/img/bogcar-img2.png";
import bogcar_img3 from "../../assets/img/bogcar-img3.png";
import bogcar_img4 from "../../assets/img/bogcar-img4.png";
import bogcar_img5 from "../../assets/img/bogcar-img5.png";
import bampermd_preview from "../../assets/img/bampermd-preview.png";
import bampermd_big from "../../assets/img/bampermd-big.png";
import bampermd_img1 from "../../assets/img/bampermd-img1.png";
import bampermd_img2 from "../../assets/img/bampermd-img2.png";
import bampermd_img3 from "../../assets/img/bampermd-img3.png";
import bampermd_img4 from "../../assets/img/bampermd-img4.png";
import bampermd_img5 from "../../assets/img/bampermd-img5.png";
import olds_preview from "../../assets/img/olds-preview.png";
import olds_big from "../../assets/img/olds-big.png";
import olds_img1 from "../../assets/img/olds-img1.png";
import olds_img2 from "../../assets/img/olds-img2.png";
import olds_img3 from "../../assets/img/olds-img3.png";
import olds_img4 from "../../assets/img/olds-img4.png";
import olds_img5 from "../../assets/img/olds-img5.png";
import detl_preview from "../../assets/img/detl-preview.png";
import detl_big from "../../assets/img/detl-big.png";
import detl_img1 from "../../assets/img/detl-img1.png";
import detl_img2 from "../../assets/img/detl-img2.png";
import detl_img3 from "../../assets/img/detl-img3.png";
import detl_img4 from "../../assets/img/detl-img4.png";
import detl_img5 from "../../assets/img/detl-img5.png";
import detg_preview from "../../assets/img/detg-preview.png";
import detg_big from "../../assets/img/detg-big.png";
import detg_img1 from "../../assets/img/detg-img1.png";
import detg_img2 from "../../assets/img/detg-img2.png";
import detg_img3 from "../../assets/img/detg-img3.png";
import detg_img4 from "../../assets/img/detg-img4.png";
import detg_img5 from "../../assets/img/detg-img5.png";
import bizon_preview from "../../assets/img/bizon-preview.png";
import bizon_big from "../../assets/img/bizon-big.png";
import bizon_img1 from "../../assets/img/bizon-img1.png";
import bizon_img2 from "../../assets/img/bizon-img2.png";
import bizon_img3 from "../../assets/img/bizon-img3.png";
import bizon_img4 from "../../assets/img/bizon-img4.png";
import bizon_img5 from "../../assets/img/bizon-img5.png";
import reikamd_preview from "../../assets/img/reikamd-preview.png";
import reikamd_big from "../../assets/img/reikamd-big.png";
import reikamd_img1 from "../../assets/img/reikamd-img1.png";
import reikamd_img2 from "../../assets/img/reikamd-img2.png";
import reikamd_img3 from "../../assets/img/reikamd-img3.png";
import reikamd_img4 from "../../assets/img/reikamd-img4.png";
import reikamd_img5 from "../../assets/img/reikamd-img5.png";
import esapserv_preview from "../../assets/img/esapserv-preview.png";
import esapserv_big from "../../assets/img/esapserv-big.png";
import esapserv_img1 from "../../assets/img/esapserv-img1.png";
import esapserv_img2 from "../../assets/img/esapserv-img2.png";
import esapserv_img3 from "../../assets/img/esapserv-img3.png";
import esapserv_img4 from "../../assets/img/esapserv-img4.png";
import esapserv_img5 from "../../assets/img/esapserv-img5.png";
import turbopro_preview from "../../assets/img/turbopro-preview.png";
import turbopro_big from "../../assets/img/turbopro-big.png";
import turbopro_img1 from "../../assets/img/turbopro-img1.png";
import turbopro_img2 from "../../assets/img/turbopro-img2.png";
import turbopro_img3 from "../../assets/img/turbopro-img3.png";
import turbopro_img4 from "../../assets/img/turbopro-img4.png";
import turbopro_img5 from "../../assets/img/turbopro-img5.png";
import busglass_preview from "../../assets/img/busglass-preview.png";
import busglass_big from "../../assets/img/busglass-big.png";
import busglass_img1 from "../../assets/img/busglass-img1.png";
import busglass_img2 from "../../assets/img/busglass-img2.png";
import busglass_img3 from "../../assets/img/busglass-img3.png";
import busglass_img4 from "../../assets/img/busglass-img4.png";
import busglass_img5 from "../../assets/img/busglass-img5.png";
import repairpeu_preview from "../../assets/img/repairpeu-preview.png";
import repairpeu_big from "../../assets/img/repairpeu-big.png";
import repairpeu_img1 from "../../assets/img/repairpeu-img1.png";
import repairpeu_img2 from "../../assets/img/repairpeu-img2.png";
import repairpeu_img3 from "../../assets/img/repairpeu-img3.png";
import repairpeu_img4 from "../../assets/img/repairpeu-img4.png";
import repairpeu_img5 from "../../assets/img/repairpeu-img5.png";
import resormd_preview from "../../assets/img/resormd-preview.png";
import resormd_big from "../../assets/img/resormd-big.png";
import resormd_img1 from "../../assets/img/resormd-img1.png";
import resormd_img2 from "../../assets/img/resormd-img2.png";
import resormd_img3 from "../../assets/img/resormd-img3.png";
import resormd_img4 from "../../assets/img/resormd-img4.png";
import resormd_img5 from "../../assets/img/resormd-img5.png";
import glass_preview from "../../assets/img/glass-preview.png";
import glass_big from "../../assets/img/glass-big.png";
import glass_img1 from "../../assets/img/glass-img1.png";
import glass_img2 from "../../assets/img/glass-img2.png";
import glass_img3 from "../../assets/img/glass-img3.png";
import glass_img4 from "../../assets/img/glass-img4.png";
import glass_img5 from "../../assets/img/glass-img5.png";
import electric_preview from "../../assets/img/electric-preview.png";
import electric_big from "../../assets/img/electric-big.png";
import electric_img1 from "../../assets/img/electric-img1.png";
import electric_img2 from "../../assets/img/electric-img2.png";
import electric_img3 from "../../assets/img/electric-img3.png";
import electric_img4 from "../../assets/img/electric-img4.png";
import electric_img5 from "../../assets/img/electric-img5.png";
import tonirovka_preview from "../../assets/img/tonirovka-preview.png";
import tonirovka_big from "../../assets/img/tonirovka-big.png";
import tonirovka_img1 from "../../assets/img/tonirovka-img1.png";
import tonirovka_img2 from "../../assets/img/tonirovka-img2.png";
import tonirovka_img3 from "../../assets/img/tonirovka-img3.png";
import tonirovka_img4 from "../../assets/img/tonirovka-img4.png";
import tonirovka_img5 from "../../assets/img/tonirovka-img5.png";
import ex_pipe_ico from "../../assets/img/ex-pipe-ico.svg";
import car_wind_ico from "../../assets/img/car-wind-ico.svg";


export const jsonDataRo = {
    "orase": [
      {
        "nume": "Chișinău",
        "raioane": [
          "Botanica",
          "Centru",
          "Buiucani",
		  "Sculeni",
          "Rîșcani",
          "Ciocana",
          "Aeroport",
		  "Durlești",
        ]
      }
    ],
    "raioane": {}
  };

const array = [
 {
    "id": 1,
    "url": "amortmd",
    "name": "Amort.md — Reparații amortizoare",
    "image": amort_preview,
    "bigImage": amort_big,
    "description": "Amort.md — partenerul tău profesional de service, specializat în reparația amortizoarelor. Înțelegem că funcționarea eficientă a sistemului de suspensie joacă un rol esențial în confortul și siguranța mașinii tale.",
    "phone": "+373 67 309 001",
    "address": "mun. Chișinău, Botanica, str. Muncești 255A",
    "regiune": ["Chișinău"],
    "sector": ["Botanica"],
    "mapAddress": "https://maps.app.goo.gl/ABH69WASQcoF1oWs5",
    "views": 1462,
    "orar": "Lu-Vi: 08:30 — 17:30",
    "gallery": [amort_img1, amort_img2, amort_img3, amort_img4, amort_img5],
    "iframeSrc": "https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d240.58570989066718!2d28.89737042963562!3d46.98297288692172!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x40c9798f495a902d%3A0x17a872ae590bff95!2zQW1vcnQubWQg4oCUINCg0LXQvNC-0L3RgiDQsNC80L7RgNGC0LjQt9Cw0YLQvtGA0L7Qsg!5e0!3m2!1sen!2s!4v1721456456865!5m2!1sen!2s",
    "icons": [
        {
            "url": ico1,
            "available": true
        },
        {
            "url": ico2,
            "available": true
        },
        {
            "url": ico3,
            "available": true
        },
        {
            "url": ico4,
            "available": true
        },
        {
            "url": ico5,
            "available": true
        },
        {
            "url": ico6,
            "available": false
        }
    ],
    "serviceTypes": [
        {
            "title": "Amortizoare/suspensie",
            "icon": absorber_ico,
            "services": {
                "service1": "Reglarea amortizoarelor",
                "service2": "Reparația amortizoarelor",
                "service3": "Diagnosticarea amortizoarelor",
                "service4": "Reparația suspensiei pneumatice",
                "service5": "Înlocuirea amortizoarelor",
                "service6": "Reparația sistemelor pneumatice",
                "service7": "Reparația compresorului pneumatic"
            }
        }
    ]
},
  {
    id: 2,
	 url: "ww" ,
    name: "White Wolf — reparația motorului și șasiului",
    image: ww_preview,  
		bigImage: ww_big,
    description: "Oferim diagnosticare și reparații complete ale motorului, precum și înlocuirea pieselor uzate ale șasiului și alte lucrări de service pentru mașina dumneavoastră. Specialiștii noștri folosesc doar piese de schimb de înaltă calitate și metode moderne de reparații. Garantam fiabilitatea si longevitatea lucrarilor efectuate.",
    phone: "+373 79 480 316",
    address: "or. Chișinău, Râșcani, str. Petricani 141/1",
	 regiune: ["Chișinău"],
	 sector: ["Râșcani"],
	 mapAddress: "https://maps.app.goo.gl/aVSsZ5bhHZZti9X96",
    views: 1462,
	 orar: "Lu-Vi: 08:00 — 19:00, Sâ: 08:00 — 15:00",
		 gallery: [ww_img1, ww_img2, ww_img3, ww_img4, ww_img5],

	 iframeSrc:"https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d1359.271852208284!2d28.822486760031108!3d47.049183888776085!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x40c97d007ef27887%3A0x6b41219f514812a1!2zV2hpdGUgV29sZiAtINGA0LXQvNC-0L3RgiDQtNCy0LjQs9Cw0YLQtdC70Y8g0Lgg0YXQvtC00L7QstC-0Lk!5e0!3m2!1sen!2s!4v1725774321163!5m2!1sen!2s" ,
    icons: [
		{
			url: ico1,
			available: false
		}, 
		{
			url: ico2,
			available: true
		}, 
		{
			url: ico3,
			available: true
		}, 
		{
			url: ico4,
			available: false
		}, 
		{
			url: ico5,
			available: false
		}, 
		{
			url: ico6,
			available: true
		}, 
		],
    serviceTypes: [
      {
        title: "Șasiu",
		  icon: suspension_ico,
		  services: {
			  service1: "Diagnostarea șasiului (verificare hodovaia)",
			  service2: "Înlocuirea tijei, bucșei (șarovaia, tiaga, soldatic)",
			  service3: "Înlocuirea silent block-urilor (sailen bloc)",
			  service4: "Alinierea roților (razval)", 
			  service5: "Înlocuirea rulmenților roților",
			  service6: "Înlocuirea discurilor și plăcuțelor de frână",
			  service7: "Înlocuirea planetarei (privod)",
			  service8: "Înlocuirea articulațiilor / crucii cardanice (granata)",
			}
      },
      {
        title: "Cutie de viteze/Transmisie ",
		  icon: car_manual_gearbox_ico,
		  services: {
			  service1: "Reparația cutiei de viteze manuale",
			  service2: "Diagnosticarea transmisiei manuale",
			  service3: "Înlocuirea ambreiajului (cutie manuală)",
			  service4: "Reglarea ambreiajului  (cutie manuală)",
			}
      },
      {
        title: "Diagnosticare",
		  icon: diagnostic_ico,
		  services: {
			  service1: "Diagnostarea motorului",
			  service2: "Diagnosticarea șasiului",
			  service3: "Diagnosticarea sistemului de frânare",
			}
      },
      {
        title: "Caseta de direcție",
		  icon: volan_ico,
		  services: {
			  service1: "Diagnosticarea casetei de direcție",
			  service2: "Înlocuirea casetei de direcție",
			  service3: "Repararea casetei de direcție",
			}
      },
      {
        title: "Amortizoare/suspensie",
		  icon: absorber_ico,
		  services: {
			  service1: "Înlocuirea amortizoarelor",
			  service2: "Înlocuirea arcurilor",
			
			}
      },
      {
        title: "Jante/Roți",
		  icon: wheel_ico,
		  services: {
			  service1: "Shimbarea pneurilor (scaturilor)",
			  service2: "Balansarea roților",
			}
      },
	  {
        title: "Motor",
		  icon: engine_ico,
		  services: {
			  service1: "Diagnostarea motorului",
			  service2: "Reparația capitală a motorului",
			  service3: "Verificarea bujiilor",
			  service4: "Diagnosticarea sistemului de aprindere",
			  service5: "Înlocuirea bujiilor",
			  service6: "Înlocuirea motorului",
			  service7: "Înlocuirea lanțului / curelei de distribuție",
			  service8: "Diagnosticarea si repararea sistemului de răcire",
			  service9: "Reglarea supapei",
			  service10: "Înlocuirea garniturii chiulasei (galovca cilindrilor)",
			  service11: "Înlocuirea garniturii carterului",
			}
      },
	  {
        title: "Diagnosticare computerizată",
		  icon: PC_diagnostics_ico,
		  services: {
			  service1: "Diagnosticare completă a sistemelor electronice auto",
			  service2: "Ștergerea erorilor",
			}
      },
	  {
        title: "Schimbarea filtrelor",
		  icon: car_filter_ico,
		  services: {
			  service1: "Înlocuirea filtrului de combustibil",
			  service2: "Înlocuirea filtrului de aer",
			  service3: "Înlocuirea filtrului de salon",
			  service4: "Înlocuirea filtrului de ulei",
			  service5: "Înlocuirea filtrului cutiei de viteze",
			}
      },
	  {
        title: "Ulei și lichiduri",
		  icon: oil_change_ico,
		  services: {
			  service1: "Schimbarea uleiului din motor",
			  service2: "Schimbarea uleiului din cutie mecanică",
			  service3: "Schimbarea uleiului din cutie automată",
			  service4: "Schimbarea uleiului servodirecției",
			  service5: "Înlocuirea tosol / antifriz ",
			  service6: "Înlocuirea lichidului de frână",
			}
      },
    ],
  },
  {
    id: 3,
	 url: "hybrid-lider" ,
    name: "Hybrid Lider — reparația automobilelor electrice și hybride",
    image: hl_preview,
		bigImage: hl_big,
    description: "Hybrid Lider — reparații de primă clasă de mașini hibride și electrice. Diagnosticarea și repararea completă a sistemelor hibride și electrice ale mașinii dvs. Specialiștii noștri calificați folosesc cele mai noi tehnologii și piese de schimb originale. Oferim fiabilitate și calitate excelentă a lucrărilor efectuate.",
    phone: "+373 60 998 800",
    address: "or. Chișinău, Botanica, str. Muncești 271a",
	 regiune: ["Chișinău"],
	 sector: ["Botanica"],
	 mapAddress: "https://maps.app.goo.gl/ngyCHHQWbbjDU9YZA",
    views: 1462,
	 orar: "Lu-Vi: 08:00 — 19:00",
	 gallery: [hl_img1, hl_img2, hl_img3, hl_img4, hl_img5],
	 iframeSrc:"https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2722.0177886817805!2d28.901709076765968!3d46.98098523096903!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x40c979136072ec09%3A0x83088199f15e3d72!2sHibrit%20Lider!5e0!3m2!1sen!2s!4v1721555465695!5m2!1sen!2s" ,
    icons: [
		{
			url: ico1,
			available: false
		}, 
		{
			url: ico2,
			available: true
		}, 
		{
			url: ico3,
			available: true
		}, 
		{
			url: ico4,
			available: true
		}, 
		{
			url: ico5,
			available: true
		}, 
		{
			url: ico6,
			available: false
		}, 
		],
    serviceTypes: [
      {
        title: "Echipamente hibride/electrice",
		  icon: hybrid_ico,
		  services: {
			  service1: "Diagnosticarea bateriei (hybrid, plug-in)",
			  service2: "Repararea bateriei",
			  service3: "Înlocuirea bateriei",
			  service4: "Calibrarea, echilibrarea bateriilor",
			}
      },
    ],
  },
  {
    id: 4,
	 url: "key-auto" ,
    name: "Key Auto — reparația cheilor auto",
    image: key_auto_preview,
		bigImage: key_auto_big,
    description: "Key Auto — Autoervice-ul nostru oferă o gamă largă de servicii de reparare a cheilor auto, inclusiv înlocuirea carcasei și tăierea cheilor. Oferim și reparația profesională a cheilor și înlocuire bateriei pentru toate tipurile de chei auto. De asemenea: reparații și întreținere a lacătelor pentru a asigura siguranța și securitatea vehiculului dumneavoastră. Experții noștri sunt pregătiți să vă ajute în orice situație legată de cheile și lacătele auto.",
    phone: "+373 60 299 285",
    address: "or. Chișinău, Botanica, str. Melestiu 24/7",
	 regiune: ["Chișinău"],
	 sector: ["Botanica"],
	 mapAddress: "https://maps.app.goo.gl/NHpmUUEXaBcRDL9s6",
    views: 1462,
	 orar: "Lu-Vi: 09.00 — 18.00, Sâ: 09.00 — 15:00",
	 gallery: [key_auto_img1, key_auto_img2, key_auto_img3, key_auto_img4, key_auto_img5],
	 iframeSrc:"https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d680.2479198890907!2d28.84216909724483!3d47.00113633271606!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x40c97eb00068d995%3A0x9cafc86ac72127c3!2sCarKeys!5e0!3m2!1sen!2s!4v1721510493405!5m2!1sen!2s" ,
    icons: [
		{
			url: ico1,
			available: true
		}, 
		{
			url: ico2,
			available: true
		}, 
		{
			url: ico3,
			available: true
		}, 
		{
			url: ico4,
			available: true
		}, 
		{
			url: ico5,
			available: false
		}, 
		{
			url: ico6,
			available: true
		}, 
		],
    serviceTypes: [
      {
        title: "Chei auto",
		  icon: car_key_ico,
		  services: {
			  service1: "Schimbarea corpului a cheii",
			  service2: "Tăierea cheilor",
			  service3: "Reparația cheilor",
			  service4: "Crearea cheii noi",
			  service5: "Schimbarea bateriei din cheie",
			  service6: "Программирование ключа",
			  service7: "Dublarea cheii auto",
			}
      },
      {
        title: "Lacăt auto",
		  icon: car_lock_ico,
		  services: {
			  service1: "Repararea lacătului auto",
			  service2: "Profilactica lacătului auto",
			}
      },
    ],
  },
  {
    id: 5,
	 url: "bogcar-service",
    name: "BogCar - reparații de șasiu și condiționer auto",
    image: bogcar_preview,  
		bigImage: bogcar_big,
    description: "Folosim doar piese de schimb verificate, garantând o muncă de calitate. Încrede-ne mașina ta și ne vom ocupa de ea la cel mai înalt nivel! Suntem specializați în reparațiile șasiului și reparațiile complexe de condiționere auto. Mecanicii noștri experimentați vor oferi servicii de calitate pentru mașina ta.",
    phone: "+373 79 225 502",
    address: "or. Chișinău, Botanica, str. Dacia 2",
	 regiune: ["Chișinău"],
	 sector: ["Botanica"],
	 mapAddress: "https://maps.app.goo.gl/Sdzwg9TB8WxYDvfA9",
    views: 1462,
	 orar: "Lu-Vi: 09:00 — 18:00, Sâ: 09:00 — 14:00",
		 gallery: [bogcar_img1, bogcar_img2, bogcar_img3, bogcar_img4, bogcar_img5],

	 iframeSrc:"https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d1360.681714467699!2d28.845740577692002!3d46.993836547735356!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x40c97f88764d5029%3A0x11ff185429ab9487!2sAutoServise!5e0!3m2!1sen!2s!4v1722087989646!5m2!1sen!2s" ,
    icons: [
		{
			url: ico1,
			available: true
		}, 
		{
			url: ico2,
			available: true
		}, 
		{
			url: ico3,
			available: true
		}, 
		{
			url: ico4,
			available: false
		}, 
		{
			url: ico5,
			available: false
		}, 
		{
			url: ico6,
			available: true
		}, 
		],
    serviceTypes: [
      {
        title: "Șasiu",
		  icon: suspension_ico,
		  services: {
			  service1: "Diagnostarea șasiului (verificare hodovaia)",
			  service2: "Înlocuirea tijei, bucșei (șarovaia, tiaga, soldatic)",
			  service3: "Înlocuirea silent block-urilor (sailen bloc)",
			  service4: "Alinierea roților (razval)",
			  service5: "Înlocuirea rulmenților roților",
			  service6: "Înlocuirea discurilor și plăcuțelor de frână",
			  service7: "Înlocuirea planetarei (privod)",
			  service8: "Înlocuirea articulațiilor / crucii cardanice (granata)",
			}
      },
	  {
        title: "Condiționer/încălzire",
		  icon: ac_ico,
		  services: {
			  service1: "Încărcarea condiționerului cu freon",
			  service2: "Înlocuirea radiatorului de condiționer",
			  service3: "Repararea țevilor condiționerului auto",
			  service4: "Curățarea radiatorului de încălzire",
			  service5: "Repararea furtunurilor condiționerului auto",
			}
      },
	  {
        title: "Schimbarea filtrelor",
		  icon: car_filter_ico,
		  services: {
			  service1: "Înlocuirea filtrului de combustibil",
			  service2: "Înlocuirea filtrului de aer",
			  service3: "Înlocuirea filtrului de salon",
			  service4: "Înlocuirea filtrului de ulei",
			  service5: "Înlocuirea filtrului cutiei de viteze",
			}
      },
	  {
        title: "Ulei și lichiduri",
		  icon: oil_change_ico,
		  services: {
			  service1: "Schimbarea uleiului din motor",
			  service2: "Schimbarea uleiului din cutie mecanică",
			  service3: "Schimbarea uleiului din cutie automată",
			  service4: "Schimbarea uleiului servodirecției",
			  service5: "Înlocuirea tosol / antifriz ",
			  service6: "Înlocuirea lichidului de frână",
			}
      },
	  {
        title: "Jante/Roți",
		  icon: wheel_ico,
		  services: {
			  service1: "Shimbarea pneurilor (scaturilor)",
			  service2: "Balansarea roților",
			  service3: "Shimbarea roților",
			}
      },
	  {
        title: "Amortizoare/suspensie",
		  icon: absorber_ico,
		  services: {
			  service1: "Înlocuirea amortizoarelor",
			  service2: "Înlocuirea arcurilor",
			}
      },
    ],
  },
  {
    id: 6,
	 url: "remont-motora-conditionera" ,
    name: "Repararea motorului, șasiului și condiționerului ",
    image: simple_s_preview,
		bigImage: simple_s_big,
    description: "Autoservice-ul nostru de reparații auto oferă servicii de aliniere 3D a roților, alimentare a condiționerului, reparației șasiului, cutie de viteze și motoarelor. Folosim echipamente profesionale și materiale de înaltă calitate ce ne ajută să efectuăm lucrări de reparație auto optime. Specialiștii noștri garantează o performanță profesională a lucrării, permițându-vă să vă bucurați de confort și încredere la volan. Contactați-ne!",
    phone: "+373 78 003 003",
    address: "o. Chișinău, Ciocana, str. Uzinelor 141/1",
	 regiune: ["Chișinău"],
	 sector: ["Ciocana"],
	 mapAddress: "https://maps.app.goo.gl/uQHx6AZRDh7UdDqJ7",
    views: 1462,
	 orar: "Lu-Vi: 08:00 — 19:00, Sâ: 08:00 — 15:00",
	 gallery:  [simple_s_img1, simple_s_img2, simple_s_img3, simple_s_img4, simple_s_img5],
	 iframeSrc:"https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d680.2576661259757!2d28.89359395337314!3d47.000370853019646!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x40c97be5ba36e94b%3A0x8f3e46f76acf700a!2sAutoservice!5e0!3m2!1sen!2sus!4v1721561170671!5m2!1sen!2sus" ,
    icons: [
		{
			url: ico1,
			available: false
		}, 
		{
			url: ico2,
			available: true
		}, 
		{
			url: ico3,
			available: false
		}, 
		{
			url: ico4,
			available: true
		}, 
		{
			url: ico5,
			available: false
		}, 
		{
			url: ico6,
			available: true
		}, 
		],
    serviceTypes: [
		 {
        title: "Șasiu",
		  icon: suspension_ico,
		  services: {
			  service1: "Diagnostarea șasiului (verificare hodovaia)",
			  service2: "Înlocuirea tijei, bucșei (șarovaia, tiaga, soldatic)",
			  service3: "Înlocuirea silent block-urilor (sailen bloc)",
			  service4: "Alinierea roților (razval)",
			  service5: "Înlocuirea rulmenților roților",
			  service6: "Înlocuirea discurilor și plăcuțelor de frână",
			  service7: "Înlocuirea planetarei (privod)",
			  service8: "Înlocuirea articulațiilor / crucii cardanice (granata)",
			}
      },
      {
        title: "Cutie de viteze/Transmisie ",
		  icon: car_manual_gearbox_ico,
		  services: {
			  service1: "Reparația cutiei de viteze manuale",
			  service2: "Diagnosticarea transmisiei manuale",
			  service3: "Înlocuirea ambreiajului (cutie manuală)",
			  service4: "Reglarea ambreiajului  (cutie manuală)",
			  service5: "Repararea mânerului de schimbare a vitezei",
			}
      },
	  {
        title: "Motor",
		  icon: engine_ico,
		  services: {
			  service1: "Diagnostarea motorului",
			  service2: "Reparația capitală a motorului",
			  service3: "Verificarea bujiilor",
			  service4: "Diagnosticarea sistemului de aprindere",
			  service5: "Înlocuirea bujiilor",
			  service6: "Înlocuirea motorului",
			  service7: "Înlocuirea lanțului / curelei de distribuție",
			  service8: "Diagnosticarea si repararea sistemului de răcire",
			  service9: "Reglarea supapei",
			  service10: "Înlocuirea garniturii chiulasei (galovca cilindrilor)",
			  service11: "Înlocuirea garniturii carterului",
			}
      },
	  {
        title: "Ulei și lichiduri",
		  icon: oil_change_ico,
		  services: {
			  service1: "Schimbarea uleiului din motor",
			  service2: "Schimbarea uleiului din cutie mecanică",
			  service3: "Schimbarea uleiului din cutie automată",
			  service4: "Schimbarea uleiului servodirecției",
			  service5: "Înlocuirea tosol / antifriz ",
			  service6: "Înlocuirea lichidului de frână",
			}
      },
      {
        title: "Sistem de frânare",
		  icon: brakes_ico,
		  services: {
			  service1: "Înlocuirea lichidului de frână",
			  service2: "Înlocuirea plăcuțelor de frană",
			  service3: "Reparație frână de mână",
			}
      },
	  {
        title: "Amortizoare/suspensie",
		  icon: absorber_ico,
		  services: {
			  service1: "Înlocuirea amortizoarelor",
			  service2: "Înlocuirea arcurilor",
			}
      },
      {
        title: "Turbină/Turbocompresor",
		  icon: turbine_ico,
		  services: {
			  service1: "Schimbarea turbinei",
			  service2: "Verificarea tuburilor sistemului de supraalimentare",
			}
      },
	  	  {
        title: "Schimbarea filtrelor",
		  icon: car_filter_ico,
		  services: {
			  service1: "Înlocuirea filtrului de combustibil",
			  service2: "Înlocuirea filtrului de aer",
			  service3: "Înlocuirea filtrului de salon",
			  service4: "Înlocuirea filtrului de ulei",
			  service5: "Înlocuirea filtrului cutiei de viteze",
			}
      },
	  {
        title: "Diagnosticare computerizată",
		  icon: PC_diagnostics_ico,
		  services: {
			  service1: "Diagnosticare completă a sistemelor electronice auto",
			  service2: "Ștergerea erorilor",
			}
      },
	  {
        title: "Caseta de direcție",
		  icon: volan_ico,
		  services: {
			  service1: "Diagnosticarea casetei de direcție",
			  service2: "Înlocuirea casetei de direcție",
			  service3: "Repararea casetei de direcție",
			}
      },
      {
        title: "Condiționer/încălzire",
		  icon: ac_ico,
		  services: {
			  service1: "Încărcarea condiționerului cu freon",
			}
      },
    ],
  },
  {
    id: 7,
	 url: "force-car-service" ,
    name: "Force Car Service — servicii complexe de deservire auto",
    image: fcs_preview,
		bigImage: fcs_big,
    description: "Expert în repararea motoarelor auto. Diagnosticare și reparare completă a motoarelor de orice complexitate. Mecanicii noștri folosesc tehnologie modernă și piese de schimb de înaltă calitate. Garantăm performanțe excelente și durabilitate a motorului dvs.",
    phone: "+373 68 584 828",
    address: "or. Chișinău, Râșcani, str. Ismail 103",
	 regiune: ["Chișinău"],
	 sector: ["Râșcani"],
	 mapAddress: "https://maps.app.goo.gl/B2uZZKuz6H3m3brX6",
    views: 1462,
	 orar: "Lu-Vi: 08:00 — 19:00, Sâ: 08:00 — 15:00",
	 gallery: [fcs_img1, fcs_img2, fcs_img3, fcs_img4, fcs_img5],
	 iframeSrc:"https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d4574.106868060407!2d28.86533113289558!3d47.024918985334835!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x40c97df17df45481%3A0x1e0185095d8b8fe2!2sForce_car!5e0!3m2!1sen!2s!4v1721539511957!5m2!1sen!2s" ,
    icons: [
		{
			url: ico1,
			available: true
		}, 
		{
			url: ico2,
			available: true
		}, 
		{
			url: ico3,
			available: true
		}, 
		{
			url: ico4,
			available: true
		}, 
		{
			url: ico5,
			available: false
		}, 
		{
			url: ico6,
			available: true
		}, 
		],
    serviceTypes: [
		  {
			title: "Motor",
			  icon: engine_ico,
			  services: {
				  service1: "Diagnostarea motorului",
				  service2: "Reparația capitală a motorului",
				  service3: "Verificarea bujiilor",
				  service4: "Diagnosticarea sistemului de aprindere",
				  service5: "Înlocuirea bujiilor",
				  service6: "Înlocuirea motorului",
				  service7: "Înlocuirea lanțului / curelei de distribuție",
				  service8: "Diagnosticarea si repararea sistemului de răcire",
				  service9: "Reglarea supapei",
				  service10: "Înlocuirea garniturii chiulasei (galovca cilindrilor)",
				  service11: "Înlocuirea garniturii carterului",
				}
		  },
		  {
			title: "Șasiu",
			  icon: suspension_ico,
			  services: {
				  service1: "Diagnostarea șasiului (verificare hodovaia)",
				  service2: "Înlocuirea tijei, bucșei (șarovaia, tiaga, soldatic)",
				  service3: "Înlocuirea silent block-urilor (sailen bloc)",
				  service4: "Alinierea roților (razval)",
				  service5: "Înlocuirea rulmenților roților",
				  service6: "Înlocuirea discurilor și plăcuțelor de frână",
				  service7: "Înlocuirea planetarei (privod)",
				  service8: "Înlocuirea articulațiilor / crucii cardanice (granata)",
				}
		  },
		  {
			title: "Cutie de viteze/Transmisie ",
			  icon: car_manual_gearbox_ico,
			  services: {
				  service1: "Reparația cutiei de viteze manuale",
				  service2: "Diagnosticarea transmisiei manuale",
				  service3: "Înlocuirea ambreiajului (cutie manuală)",
				  service4: "Reglarea ambreiajului  (cutie manuală)",
				}
		  },
		  {
			title: "Diagnosticare",
			  icon: diagnostic_ico,
			  services: {
				  service1: "Diagnostarea motorului",
				  service2: "Diagnosticarea șasiului",
				  service3: "Diagnosticarea sistemului de frânare",
				}
		  },
		  {
			title: "Caseta de direcție",
			  icon: volan_ico,
			  services: {
				  service1: "Diagnosticarea casetei de direcție",
				  service2: "Înlocuirea casetei de direcție",
				  service3: "Repararea casetei de direcție",
				}
		  },
		  {
			title: "Schimbarea filtrelor",
			  icon: car_filter_ico,
			  services: {
				  service1: "Înlocuirea filtrului de combustibil",
				  service2: "Înlocuirea filtrului de aer",
				  service3: "Înlocuirea filtrului de salon",
				  service4: "Înlocuirea filtrului de ulei",
				  service5: "Înlocuirea filtrului cutiei de viteze",
				}
		  },
		  {
			title: "Diagnosticare computerizată",
			  icon: PC_diagnostics_ico,
			  services: {
				  service1: "Diagnosticare completă a sistemelor electronice auto",
				  service2: "Ștergerea erorilor",
				}
		  },
		  {
			title: "Amortizoare/suspensie",
			  icon: absorber_ico,
			  services: {
				  service1: "Înlocuirea amortizoarelor",
				  service2: "Înlocuirea arcurilor",
				
				}
		  },
		  {
			title: "Ulei și lichiduri",
			  icon: oil_change_ico,
			  services: {
				  service1: "Schimbarea uleiului din motor",
				  service2: "Schimbarea uleiului din cutie mecanică",
				  service3: "Schimbarea uleiului din cutie automată",
				  service4: "Schimbarea uleiului servodirecției",
				  service5: "Înlocuirea tosol / antifriz ",
				  service6: "Înlocuirea lichidului de frână",
				}
		  },
		  {
			title: "Detailing/Lustruire",
			  icon: detailing_ico,
			  services: {
				  service1: "Lustruirea caroseriei",
				  service2: "Curățarea și lustruirea jantelor",
				  service3: "Lustruirea farurilor",
				}
		  },
		  {
			title: "Vopsire",
			  icon: paint_ico,
			  services: {
				  service1: "Vopsirea caroseriei",
				  service2: "Vopsirea jantelor",
				}
		  },
		  {
			title: "Jante/Roți",
			  icon: wheel_ico,
			  services: {
				  service1: "Reparația jantelor (sudare)",
				  service2: "Lustruirea farurilor",
				}
		  },
    ],
  },
  {
    id: 8,
	 url: "auto-fast" ,
    name: "Vulcanizarea AutoFast",
    image: autof_preview,
		bigImage: autof_big,
    description: "Oferim servicii profesionale de vulcanizare si reparații de anvelope. Reșapăm anvelope folosind materiale de calitative. Specialiștii noștri vor face totul rapid, eficient și sigur.",
    phone: "+373 69 256 301",
    address: "or. Chișinău, Botanica, str. Grenoble 134/2",
	 regiune: ["Chișinău"],
	 sector: ["Botanica"],
	 mapAddress: "https://maps.app.goo.gl/WtVvkCgiSrCBGDtM8",
    views: 1462,
	 orar: "Lu-Du: 08:00 — 22:00",
	 gallery: [autof_img1, autof_img2, autof_img3, autof_img4, autof_img5],
	 iframeSrc:"https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d680.6106075041856!2d28.84922053172323!3d46.97264403616815!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x40c97932187254e7%3A0xc2fbe1509b894ea1!2sVulcanizare%20AutoFast!5e0!3m2!1sen!2s!4v1722011278559!5m2!1sen!2s" ,
    icons: [
		{
			url: ico1,
			available: false
		}, 
		{
			url: ico2,
			available: true
		}, 
		{
			url: ico3,
			available: true
		}, 
		{
			url: ico4,
			available: false
		}, 
		{
			url: ico5,
			available: false
		}, 
		{
			url: ico6,
			available: true
		}, 
		],
    serviceTypes: [
      {
        title: "Jante/Roți",
		  icon: wheel_ico,
		  services: {
			  service1: "Balansarea roților",
			  service2: "Shimbarea pneurilor (scaturilor)",
			  service3: "Reparația pneuriloe (scaturilor)",
			}
      },
    ],
  },
  {
    id: 9,
	 url: "complex" ,
    name: "Complex — reparația motorului și suspensiei",
    image: complex_preview,  
		bigImage: complex_big,
    description: "Diagnosticarea și repararea completă a motorului și șasiului pentru automobilul dvs.",
    phone: "+373 68 946 666",
    address: "or. Chișinău, Râșcani, str. Petricani 21",
	 regiune: ["Chișinău"],
	 sector: ["Râșcani"],
	 mapAddress: "https://maps.app.goo.gl/6b9gQTj1HoagyyMs7",
    views: 1462,
	 orar: "Lu-Vi: 09:00 — 18:00, Sâ: 09:00 — 14:00",
		 gallery: [complex_img1, complex_img2, complex_img3, complex_img4, complex_img5],

	 iframeSrc:"https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d961.1039627085811!2d28.817781729580087!3d47.05175769035963!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x40c97d9f1fcf3851%3A0x63e353bbd8071d7d!2zU3RyYWRhIFBldHJpY2FuaSAyMSwgTUQtMjA2OCwgQ2hpyJlpbsSDdSwg0JzQvtC70LTQvtCy0LA!5e0!3m2!1sru!2s!4v1725964755502!5m2!1sru!2s" ,
    icons: [
		{
			url: ico1,
			available: true
		}, 
		{
			url: ico2,
			available: true
		}, 
		{
			url: ico3,
			available: true
		}, 
		{
			url: ico4,
			available: true
		}, 
		{
			url: ico5,
			available: false
		}, 
		{
			url: ico6,
			available: true
		}, 
		],
    serviceTypes: [
      {
        title: "Șasiu",
		  icon: suspension_ico,
		  services: {
			  service1: "Diagnostarea șasiului (verificare hodovaia)",
			  service2: "Înlocuirea tijei, bucșei (șarovaia, tiaga, soldatic)",
			  service3: "Înlocuirea silent block-urilor (sailen bloc)",
			  service4: "Alinierea roților (razval)",
			  service5: "Înlocuirea rulmenților roților",
			  service6: "Înlocuirea discurilor și plăcuțelor de frână",
			  service7: "Înlocuirea planetarei (privod)",
			  service8: "Înlocuirea articulațiilor / crucii cardanice (granata)",
			}
      },
      {
        title: "Cutie de viteze/Transmisie ",
		  icon: car_manual_gearbox_ico,
		  services: {
			  service1: "Reparația cutiei de viteze manuale",
			  service2: "Diagnosticarea transmisiei manuale",
			  service3: "Înlocuirea ambreiajului (cutie manuală)",
			  service4: "Reglarea ambreiajului  (cutie manuală)",
			}
      },
      {
        title: "Diagnosticare",
		  icon: diagnostic_ico,
		  services: {
			  service1: "Diagnostarea motorului",
			  service2: "Diagnosticarea șasiului",
			  service3: "Diagnosticarea sistemului de frânare",
			}
      },
      {
        title: "Caseta de direcție",
		  icon: volan_ico,
		  services: {
			  service1: "Diagnosticarea casetei de direcție",
			  service2: "Înlocuirea casetei de direcție",
			  service3: "Repararea casetei de direcție",
			}
      },
      {
        title: "Amortizoare/suspensie",
		  icon: absorber_ico,
		  services: {
			  service1: "Înlocuirea amortizoarelor",
			  service2: "Înlocuirea arcurilor",
			
			}
      },
      {
        title: "Jante/Roți",
		  icon: wheel_ico,
		  services: {
			  service1: "Shimbarea pneurilor (scaturilor)",
			  service2: "Balansarea roților",
			}
      },
	  {
        title: "Motor",
		  icon: engine_ico,
		  services: {
			  service1: "Diagnostarea motorului",
			  service2: "Reparația capitală a motorului",
			  service3: "Verificarea bujiilor",
			  service4: "Diagnosticarea sistemului de aprindere",
			  service5: "Înlocuirea bujiilor",
			  service6: "Înlocuirea motorului",
			  service7: "Înlocuirea lanțului / curelei de distribuție",
			  service8: "Diagnosticarea si repararea sistemului de răcire",
			  service9: "Reglarea supapei",
			  service10: "Înlocuirea garniturii chiulasei (galovca cilindrilor)",
			  service11: "Înlocuirea garniturii carterului",
			}
      },
	  {
        title: "Condiționer/încălzire",
		  icon: ac_ico,
		  services: {
			  service1: "Încărcarea condiționerului cu freon",
			  service2: "Înlocuirea radiatorului de condiționer",
			  service3: "Înlocuirea/repararea ventilatorului interior",
			  service4: "Curățarea radiatorului de încălzire",
			  service5: "Repararea/înlocuirea servomotorului clapetelor",
			  service6: "Inlocuire/reparare evaporator aer conditionat",
			  service7: "Reparația / înlocuirea carcasei radiatorului de încălzire, clapetelor, orificiilor de drenaj",
			  service8: "Repararea motorului condiționerului",
			}
      },
	  {
        title: "Diagnosticare computerizată",
		  icon: PC_diagnostics_ico,
		  services: {
			  service1: "Diagnosticare completă a sistemelor electronice auto",
			  service2: "Ștergerea erorilor",
			}
      },
	  {
        title: "Schimbarea filtrelor",
		  icon: car_filter_ico,
		  services: {
			  service1: "Înlocuirea filtrului de combustibil",
			  service2: "Înlocuirea filtrului de aer",
			  service3: "Înlocuirea filtrului de salon",
			  service4: "Înlocuirea filtrului de ulei",
			  service5: "Înlocuirea filtrului cutiei de viteze",
			}
      },
	  {
        title: "Ulei și lichiduri",
		  icon: oil_change_ico,
		  services: {
			  service1: "Schimbarea uleiului din motor",
			  service2: "Schimbarea uleiului din cutie mecanică",
			  service3: "Schimbarea uleiului din cutie automată",
			  service4: "Schimbarea uleiului servodirecției",
			  service5: "Înlocuirea tosol / antifriz ",
			  service6: "Înlocuirea lichidului de frână",
			}
      },
    ],
  },
  {
    id: 10,
	 url: "SST-Chiptuning" ,
    name: "SST-Chiptuning — chip tuning și deconectarea EGR ",
    image: sst_preview,
		bigImage: sst_big,
    description: "SST-CHIPTUNING —  SST-CHIPTUNING —  atelier specializat pe tuning, pentru reconfigurarea si personalizarea automobilelor. Ne focusăm pe chip tuning și modificarea parametrilor auto. De asemenea, oferim soluții pentru dezactivarea diferitelor sisteme de motor și pentru a rezolva probleme de mediu, cum ar fi DPF (filtru de particule diesel), EGR, AdBlue și probleme similare. În studioul nostru oferim servicii de editare a fișierelor, pe care le modificăm în funcție de nevoile dumneavoastră. Tindem doar spre a vă oferi cele mai bune rezultate. Serviciile noastre sunt concepute pentru clienții care își prețuiesc vehiculele și se străduiesc să obțină performanțe optime. Toate lucrările noastre sunt efectuate folosind echipamente originale și costisitoare care garantează siguranță și stabilitate.",
    phone: "+373 60 705 050",
    address: "or. Chișinău, Râșcani, str. Doina 189",
	 regiune: ["Chișinău"],
	 sector: ["Râșcani"],
	 mapAddress: "https://maps.app.goo.gl/g5xXfgTwgKq6JV5J7",
    views: 1462,
	 orar: "Lu-Vi: 08:00 — 18:00, Sâ: 08:00 — 15:00",
	 gallery: [sst_img1, sst_img2, sst_img3, sst_img4, sst_img5],
	 iframeSrc:"https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d3843.7735174838863!2d28.83485292678854!3d47.06066795455975!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x40c97db8df0443df%3A0x9f247feeec426036!2sSST-CHIPTUNING%20%26%20Autoservice!5e0!3m2!1sru!2sus!4v1722012759000!5m2!1sru!2sus" ,
    icons: [
		{
			url: ico1,
			available: true
		}, 
		{
			url: ico2,
			available: true
		}, 
		{
			url: ico3,
			available: true
		}, 
		{
			url: ico4,
			available: true
		}, 
		{
			url: ico5,
			available: false
		}, 
		{
			url: ico6,
			available: true
		}, 
		],
    serviceTypes: [
      {
        title: "EGR și filtru de particule",
		  icon: catalyst_ico,
		  services: {
			  service1: "Diagnosticarea supapei EGR",
			  service2: "Deconectarea computerizată a supapei EGR",
			  service3: "Îndepărtarea (tăierea) filtrului de particule",
			}
      },
      {
        title: "Chip tuning",
		  icon: chip_tuning_ico,
		  services: {
			  service1: "Chip tuning",
			  service2: "Chip tuning stage 1",
			}
      },
    ],
  },
  {
    id: 11,
	 url: "starter-plus" ,
    name: "Starter plus — Reparații de startere și generatoare",
    image: starterp_preview,
		bigImage: starterp_big,
    description: "Firma noastră este una dintre primele din Moldova specializată în repararea demaroarelor și generatoarelor de autoturisme și camioane. Datorită multor ani de experiență, vă putem garanta cu încredere calitatea serviciilor pe care le oferim!",
    phone: "+373 79 307 708",
    address: "or. Chișinău, Botanica, str. Uzinelor 8/1",
	 regiune: ["Chișinău"],
	 sector: ["Botanica"],
	 mapAddress: "https://maps.app.goo.gl/N21G9Jky8gLG1beg6",
    views: 1462,
	 orar: "Lu-Vi: 08:00 — 18:00, Sâ: 08:00 — 15:00",
	 gallery: [starterp_img1, starterp_img2, starterp_img3, starterp_img4, starterp_img5],
	 iframeSrc:"https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d1360.2263084389472!2d28.87217893894353!3d47.01171999278539!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x40c97c0930d302c9%3A0x7aa475426a8ec60c!2sStarter%20Plus%20SRL!5e0!3m2!1sen!2s!4v1722015185460!5m2!1sen!2s" ,
    icons: [
		{
			url: ico1,
			available: true
		}, 
		{
			url: ico2,
			available: true
		}, 
		{
			url: ico3,
			available: true
		}, 
		{
			url: ico4,
			available: true
		}, 
		{
			url: ico5,
			available: false
		}, 
		{
			url: ico6,
			available: true
		}, 
		],
    serviceTypes: [
      {
        title: "Electrica auto",
		  icon: energy_ico,
		  services: {
			  service1: "Diagnosticarea cablajului",
			  service2: "Repararea cablajului",
			  service3: "Înlocuirea siguranțelor și releelor ",
			  service4: "Verificarea și înlocuirea acumulatorului",
			  service5: "Diagnosticarea generatorului",
			  service6: "Ремонт генератора",
			  service7: "Înlocuirea generatorului",
			  service8: "Diagnosticarea starterului",
			  service9: "Repararea starterului",
			  service10: "Înlocuirea starterului",
			  service11: "Instalare și configurare a echipamentelor suplimentare (camera, Android auto)",
			}
      },
      {
        title: "Diagnosticare computerizată",
		  icon: PC_diagnostics_ico,
		  services: {
			  service1: "Diagnosticarea sistemelor electronice auto",
			  service2: "Ștergerea erorilor",
			}
      },
    ],
  },
  {
    id: 12,
	 url: "mireco-service" ,
    name: "Mireco Service — motor, electrică, șasiu",
    image: mireco_preview,
	 bigImage: mireco_big,
    description: "Mireco oferă o gamă largă de servicii de întreținere și reparații pentru mașini ale tuturor mărcilor auto.",
    phone: "+373 61 183 183",
    address: "or. Chișinău, Centru, str. Columna 170",
	 regiune: ["Chișinău"],
	 sector: ["Centru"],
	 mapAddress: "https://maps.app.goo.gl/zxVvfncStsnxuwEQ9",
    views: 1462,
	 orar: "Lu-Sâ: 08:00 — 18:00",
	 gallery: [mireco_img1, mireco_img2, mireco_img3, mireco_img4, mireco_img5],
	 iframeSrc:"https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d1359.5584315322353!2d28.8199121!3d47.0379376!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x40c97d001193326b%3A0xbecaafe7577c297c!2sMireco%20Auto-Service%2FVulcanizare!5e0!3m2!1sen!2s!4v1722053883140!5m2!1sen!2s" ,
    icons: [
		{
			url: ico1,
			available: true
		}, 
		{
			url: ico2,
			available: true
		}, 
		{
			url: ico3,
			available: true
		}, 
		{
			url: ico4,
			available: true
		}, 
		{
			url: ico5,
			available: true
		}, 
		{
			url: ico6,
			available: true
		}, 
		],
    serviceTypes: [
	  {
		title: "Motor",
		  icon: engine_ico,
		  services: {
			  service1: "Diagnostarea motorului",
			  service2: "Reparația capitală a motorului",
			  service3: "Verificarea bujiilor",
			  service4: "Diagnosticarea sistemului de aprindere",
			  service5: "Înlocuirea bujiilor",
			  service6: "Înlocuirea motorului",
			  service7: "Înlocuirea lanțului / curelei de distribuție",
			  service8: "Diagnosticarea si repararea sistemului de răcire",
			  service9: "Reglarea supapei",
			  service10: "Înlocuirea garniturii chiulasei (galovca cilindrilor)",
			  service11: "Înlocuirea garniturii carterului",
			  service11: "Decarbonizarea si spalarea motorului",
			}
	  },
	  {
        title: "Electrica auto",
		  icon: energy_ico,
		  services: {
			  service1: "Diagnosticarea cablajului",
			  service2: "Repararea cablajului",
			  service3: "Înlocuirea siguranțelor și releelor ",
			  service4: "Verificarea și înlocuirea acumulatorului",
			  service5: "Diagnosticarea generatorului",
			  service6: "Instalare și configurare a echipamentelor suplimentare (camera, Android auto)",
			  service7: "Înlocuirea generatorului",
			  service8: "Diagnosticarea starterului",
			  service9: "Înlocuirea starterului",
			}
      },
	  {
		title: "Șasiu",
		  icon: suspension_ico,
		  services: {
			  service1: "Diagnostarea șasiului (verificare hodovaia)",
			  service2: "Înlocuirea tijei, bucșei (șarovaia, tiaga, soldatic)",
			  service3: "Înlocuirea silent block-urilor (sailen bloc)",
			  service4: "Alinierea roților (razval)",
			  service5: "Înlocuirea rulmenților roților",
			  service6: "Înlocuirea discurilor și plăcuțelor de frână",
			  service7: "Înlocuirea planetarei (privod)",
			  service8: "Înlocuirea articulațiilor / crucii cardanice (granata)",
			}
	  },
	  {
		title: "Cutie de viteze/Transmisie ",
		  icon: car_manual_gearbox_ico,
		  services: {
			  service1: "Reparația cutiei de viteze manuale",
			  service2: "Diagnosticarea transmisiei manuale",
			}
	  },
	  {
		title: "Diagnosticare",
		  icon: diagnostic_ico,
		  services: {
			  service1: "Diagnostarea motorului",
			  service2: "Diagnosticarea șasiului",
			  service3: "Diagnosticarea sistemului de frânare",
			}
	  },
	  {
		title: "Caseta de direcție",
		  icon: volan_ico,
		  services: {
			  service1: "Diagnosticarea casetei de direcție",
			  service2: "Înlocuirea casetei de direcție",
			  service3: "Repararea casetei de direcție",
			}
	  },
	  {
		title: "Schimbarea filtrelor",
		  icon: car_filter_ico,
		  services: {
			  service1: "Înlocuirea filtrului de combustibil",
			  service2: "Înlocuirea filtrului de aer",
			  service3: "Înlocuirea filtrului de salon",
			  service4: "Înlocuirea filtrului de ulei",
			}
	  },
	  {
        title: "Diagnosticare computerizată",
		  icon: PC_diagnostics_ico,
		  services: {
			  service1: "Diagnosticarea sistemelor electronice auto",
			  service2: "Ștergerea erorilor",
			}
      },
	  {
        title: "Amortizoare/suspensie",
		  icon: absorber_ico,
		  services: {
			  service5: "Înlocuirea amortizoarelor",
			}
      },     
    
    ],
  }, 
  {
    id: 13,
	 url: "eco-tuning" ,
    name: "Eco Tuning — Deconectarea filtrului de particule, Adblue",
    image: ecot_preview,
		bigImage: ecot_big,
    description: "Oferim servicii pentru dezactivarea și îndepărtarea filtrului de particule, EGR, Adblue și de asemenea, efectuăm chip tuning de înaltă calitate pentru mașina dvs., optimizând puterea acesteia cu 20-30%.",
    phone: "+373 78 092 869",
    address: "or. Chișinău, Aeroport, str. Dacia 65",
	 regiune: ["Chișinău"],
	 sector: ["Aeroport"],
	 mapAddress: "https://maps.app.goo.gl/gHQCBaMi8uFMxhjM8",
    views: 1462,
	 orar: "Lu-Vi: 09:00 — 18:00",
	 gallery: [ecot_img1, ecot_img2, ecot_img3, ecot_img4, ecot_img5],
	 iframeSrc:"https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d680.9467846790748!2d28.922636686010943!3d46.94622255198563!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x40c9790bbacb48eb%3A0x5c1e41e4321b16ff!2sDacia%20Blvd%2065%2C%20MD-2026%2C%20Chi%C8%99in%C4%83u%2C%20Moldova!5e0!3m2!1sen!2s!4v1722057163728!5m2!1sen!2s" ,
    icons: [
		{
			url: ico1,
			available: true
		}, 
		{
			url: ico2,
			available: true
		}, 
		{
			url: ico3,
			available: true
		}, 
		{
			url: ico4,
			available: true
		}, 
		{
			url: ico5,
			available: false
		}, 
		{
			url: ico6,
			available: true
		}, 
		],
    serviceTypes: [
      {
        title: "EGR și filtru de particule",
		  icon: catalyst_ico,
		  services: {
			  service1: "Diagnosticarea supapei EGR",
			  service2: "Reparația supapei EGR",
			  service3: "Deconectarea supapei EGR",
			  service4: "Deconectarea computerizată a supapei EGR",
			  service5: "Îndepărtarea (tăierea) filtrului de particule",
			  service6: "Deconectarea Adblue",
			}
      },
      {
        title: "Diagnosticare computerizată",
		  icon: PC_diagnostics_ico,
		  services: {
			  service1: "Diagnosticarea sistemelor electronice auto",
			  service2: "Ștergerea erorilor",
			}
      },
    ],
  },
  {
    id: 14,
	 url: "tonirovka",
    name: "Tonirovka.md - Tonarea ferestrelor, curățătorie chimică interioară",
    image: tonirovka_preview,
		bigImage: tonirovka_big,
    description: "Servicii complexe de detaliere și îngrijire pentru mașina dumneavoastră: curățare chimică interioară, tonarea ferestrelor, ambalare faruri, tapițerie tavan, reumplere aer condiționat, ceață uscată, lustruire faruri.",
    phone: "+373 60 196 199",
    address: "or. Chișinău, Sculeni, str. Milano 19/1 (Box 260)",
	 regiune: ["Chișinău"],
	 sector: ["Sculeni"],
	 mapAddress: "https://maps.app.goo.gl/KeWhrpP87y8sisVn6",
    views: 1462,
	 orar: "Lu-Vi: 09:00 — 17:00, Sâ: 09:00 — 14:00",
	 gallery: [tonirovka_img1, tonirovka_img2, tonirovka_img3, tonirovka_img4, tonirovka_img5],
	 iframeSrc:"https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d961.3211161740801!2d28.790349193307353!3d47.039706475881545!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x40c97df2bd0fc545%3A0x8a40e96f98cfdd60!2s1%2C%20Strada%20Milano%2019%2C%20MD-2069%2C%20Chi%C8%99in%C4%83u%2C%20Moldova!5e0!3m2!1sen!2s!4v1725686769086!5m2!1sen!2s" ,
    icons: [
		{
			url: ico1,
			available: true
		}, 
		{
			url: ico2,
			available: false
		}, 
		{
			url: ico3,
			available: true
		}, 
		{
			url: ico4,
			available: true
		}, 
		{
			url: ico5,
			available: false
		}, 
		{
			url: ico6,
			available: false
		}, 
		],
    serviceTypes: [
      {
        title: "Detailing/Lustruire",
		  icon: detailing_ico,
		  services: {
			  service1: "Spălarea și degresarea caroseriei",
			  service2: "Acoperirea farurilor cu peliculă de protecție",
			  service3: "Lustruirea farurilor",
			  service4: "Curățătorie chimică a salonului",
			  service5: "Ozonarea salonului",
			  service6: "Tonarea ferestrelor",
			}
      },
	  {
        title: "Restaurarea interiorului",
		  icon: salon_ico,
		  services: {
			  service1: "Restaurarea podului (tapițeria podului)",
			}
      },
	  {
        title: "Condiționer",
		  icon: ac_ico,
		  services: {
			  service1: "Încărcarea condiționerului cu freon",
			}
      },
    ],
  },
   {
    id: 15,
	 url: "bamper-md" ,
    name: "Bamper.md — reparația barelor de protectie si pieselor din plastic",
    image: bampermd_preview,
		bigImage: bampermd_big,
    description: "Efectuăm toate tipurile de sudare din plastic, reparații și restaurare a oricăror piese auto din plastic și a pieselor din fibră de sticlă. Reparația și sudarea materialelor plastice se efectuează folosind materiale bune și echipamente speciale. Evaluăm reparațiile folosind fotografii în Viber/WhatsApp, consultanță gratuită, meșteri cu experiență. Toate tipurile de sudare din plastic, refacem orice piese din plastic!",
    phone: "+373 78 784 808",
    address: "or. Chișinău, Râșcani, str. Petru Rareș 62",
	  regiune: ["Chișinău"],
	 sector: ["Râșcani"],
	 mapAddress: "https://maps.app.goo.gl/AQKvroV3fAbZWNVe7",
    views: 1462,
	 orar: "Lu-Vi: 08:00 — 20:00",
	 gallery: [bampermd_img1, bampermd_img2, bampermd_img3, bampermd_img4, bampermd_img5],
	 iframeSrc:"https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d285.81142520127275!2d28.841806572839012!3d47.03803610693516!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x40c97c52ad58e8d7%3A0xa8ef534de5fd6f7b!2sStrada%20Petru%20Rare%C8%99%2062%2C%20MD-2005%2C%20Chi%C8%99in%C4%83u%2C%20Moldova!5e0!3m2!1sen!2s!4v1722543813775!5m2!1sen!2s" ,
    icons: [
		{
			url: ico1,
			available: true
		}, 
		{
			url: ico2,
			available: true
		}, 
		{
			url: ico3,
			available: true
		}, 
		{
			url: ico4,
			available: false
		}, 
		{
			url: ico5,
			available: false
		}, 
		{
			url: ico6,
			available: true
		}, 
		],
    serviceTypes: [
      {
        title: "Plastic",
		  icon: bumper_ico,
		  services: {
			  service1: "Repararea barei de protecție",
			  service2: "Sudarea plasticului",
			  service3: "Repararea aripilor",
			  service4: "Repararea îndoiturilor",
			  service5: "Repararea fixatorilor",
			  service6: "Repararea plasticului de la oglinzi",
			  service7: "Sudarea vaselor de expansiune",
			  service8: "Repararea grilei",
			  service9: "Repararea pieselor din fibră de sticlă",
			}
      },
	  {
        title: "Faruri/Optică",
		  icon: far_ico,
		  services: {
			  service1: "Schimbarea becurilor",
			  service2: "Lustruirea farurilor",
			  service3: "Reparația farurilor",
			  service4: "Schimbarea farurilor",
			}
      },
	  {
        title: "Vopsire",
		  icon: paint_ico,
		  services: {
			  service1: "Vopsirea barei de protecție",
			}
      },
    ],
  },
  {
    id: 16,
	 url: "old-style" ,
    name: "Old Style — Lustruirea farurilor și caroseriei",
    image: olds_preview,
		bigImage: olds_big,
    description: "Dacă vizibilitatea s-a deteriorat când conduceți pe timp de noapte sau mașina nu mai arată la fel de plăcută din punct de vedere estetic ca înainte, vă așteptăm la lustruirea farurilor și caroseriei.",
    phone: "+373 69 880 770",
    address: "or. Chișinău, Durlești, str. Chimiștilor 17",
	  regiune: ["Chișinău"],
	 sector: ["Buiucani"],
	 mapAddress: "https://maps.app.goo.gl/9NjYKCx7BotUj6dVA",
    views: 1462,
	 orar: "Lu-Vi: 10:00 — 19:00",
	 gallery: [olds_img1, olds_img2, olds_img3, olds_img4, olds_img5],
	 iframeSrc:"https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d1359.8624754801483!2d28.773708388360575!3d47.0260036955912!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x40c97dfd4027c90d%3A0x27145a3ce05e6bd7!2sStrada%20Chimi%C8%99tilor%2017%2C%20Durle%C5%9Fti!5e0!3m2!1sen!2s!4v1722578261428!5m2!1sen!2s" ,
    icons: [
		{
			url: ico1,
			available: true
		}, 
		{
			url: ico2,
			available: true
		}, 
		{
			url: ico3,
			available: true
		}, 
		{
			url: ico4,
			available: false
		}, 
		{
			url: ico5,
			available: false
		}, 
		{
			url: ico6,
			available: false
		}, 
		],
    serviceTypes: [
      {
        title: "Detailing/Lustruire",
		  icon: detailing_ico,
		  services: {
			  service1: "Lustruirea caroseriei",
			  service2: "Acoperirea caroseriei cu peliculă de protecție",
			  service3: "Acoperirea caroseriei cu peliculă de vinil",
			  service4: "Acoperirea farurilor cu peliculă de protecție",
			  service5: "Acoperirea caroseriei cu nanoceramică",
			  service6: "Acoperirea caroseriei cu ceară",
			  service7: "Lustruirea farurilor",
			  service8: "Curățătorie chimică a salonului",
			  service9: "Ozonarea salonului",
			}
      },
    ],
  },
  {
    id: 17,
	 url: "detailing-lux-18" ,
    name: "Detailing Lux 18 — Vopsire, lustruire auto",
    image: detl_preview,
		bigImage: detl_big,
    description: "Aspectul determinant al succesului picturii este etapa de prevopsire, în care se pregătește suprafața de vopsit. Calitatea și tipul vopselei folosite este condiția principală pentru obținerea vopselei stabile în timp si rezistentă întregului proces de aplicare. Pentru mai multe detalii și înscrieri, nu ezitați să ne contactați. Lustruirea se realizează cu instrumente profesionale, folosind materiale abrazive și paste de lustruit speciale. Vă așteptăm fără excepție, avem cafea delicioasă și dispoziție bine dispusă!",
    phone: "+373 78 856 836",
    address: "or. Chișinău, Botanica, str. Uzinelor 245/2",
	 regiune: ["Chișinău"],
	 sector: ["Botanica"],
	 mapAddress: "https://maps.app.goo.gl/VAcR4LBfR6hCLKen7",
    views: 1462,
	 orar: "Lu-Vi: 10:00 — 19:00",
	 gallery: [detl_img1, detl_img2, detl_img3, detl_img4, detl_img5],
	 iframeSrc:"https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d1360.6960220776307!2d28.911720915151584!3d46.99327461453499!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x40c97becc21edbcb%3A0xb0459eebba3054c5!2sDetailing%20Service%20Studio!5e0!3m2!1sru!2s!4v1722606071208!5m2!1sru!2s" ,
    icons: [
		{
			url: ico1,
			available: true
		}, 
		{
			url: ico2,
			available: true
		}, 
		{
			url: ico3,
			available: true
		}, 
		{
			url: ico4,
			available: false
		}, 
		{
			url: ico5,
			available: false
		}, 
		{
			url: ico6,
			available: false
		}, 
		],
    serviceTypes: [
      {
        title: "Detailing/Lustruire",
		  icon: detailing_ico,
		  services: {
			  service1: "Lustruirea caroseriei",
			  service2: "Acoperirea caroseriei cu peliculă de protecție",
			  service3: "Acoperirea caroseriei cu peliculă de vinil",
			  service4: "Acoperirea farurilor cu peliculă de protecție",
			  service5: "Acoperirea caroseriei cu nanoceramică",
			  service6: "Acoperirea caroseriei cu ceară",
			  service7: "Lustruirea farurilor",
			  service8: "Curățătorie chimică a salonului",
			  service9: "Ozonarea salonului",
			}
      },
	  {
        title: "Jante/Roți",
		  icon: wheel_ico,
		  services: {
			  service1: "Reparația jantelor (sudare)",
			  service2: "Shimbarea roților",
			  service3: "Îndreptarea jantelor",
			}
      },
	  {
        title: "Restaurarea interiorului",
		  icon: salon_ico,
		  services: {
			  service1: "Restaurarea volanului (tapițeria volanului)",
			  service2: "Restaurarea interiorului (tapițeria salonului)",
			  service3: "Restaurarea podului (tapițeria podului)",
			}
      },
	  {
        title: "Vopsire",
		  icon: paint_ico,
		  services: {
			  service1: "Vopsirea caroseriei",
			  service2: "Vopsirea barei de protecție",
			  service3: "Vopsirea jantelor",
			}
      },
    ],
  },
  {
    id: 18,
	 url: "detail-garage" ,
    name: "Detail Garage — vopsirea caroseriei și jantelor",
    image: detg_preview,
		bigImage: detg_big,
    description: "Serviciul nostru de detailing vă oferă servicii precum renovarea interiorului mașinii, lustruirea mașinii, îndepărtarea îndoiturilor fără vopsire etc. Izolarea fonică a unei mașini este cea mai bună modalitate de a vă face mașina mai premium și de a obține un confort maxim la condus.",
    phone: "+373 78 887 788",
    address: "or. Chișinău, Botanica, str. Uzinelor 210/2",
	 regiune: ["Chișinău"],
	 sector: ["Botanica"],
	 mapAddress: "https://maps.app.goo.gl/pd7sfXrEmS9nYAFd7",
    views: 1462,
	 orar: "Lu-Vi: 09:00 — 18:00",
	 gallery: [detg_img1, detg_img2, detg_img3, detg_img4, detg_img5],
	 iframeSrc:"https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d340.1903937835918!2d28.912810443835223!3d46.9906999441576!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x40c97b2e0cef32f5%3A0x7a02888ecf642536!2sgaraj!5e0!3m2!1sen!2s!4v1722575003113!5m2!1sen!2s" ,
    icons: [
		{
			url: ico1,
			available: true
		}, 
		{
			url: ico2,
			available: true
		}, 
		{
			url: ico3,
			available: true
		}, 
		{
			url: ico4,
			available: false
		}, 
		{
			url: ico5,
			available: false
		}, 
		{
			url: ico6,
			available: false
		}, 
		],
    serviceTypes: [
      {
        title: "Detailing/Lustruire",
		  icon: detailing_ico,
		  services: {
			  service1: "Lustruirea caroseriei",
			  service2: "Acoperirea caroseriei cu peliculă de protecție",
			  service3: "Acoperirea caroseriei cu peliculă de vinil",
			  service4: "Acoperirea farurilor cu peliculă de protecție",
			  service5: "Acoperirea caroseriei cu nanoceramică",
			  service6: "Curățarea și lustruirea jantelor",
			  service7: "Lustruirea farurilor",
			  service8: "Curățătorie chimică a salonului",
			  service9: "Curățarea (spălarea) motorului",
			  service10: "Tonarea ferestrelor",
			  service11: "Îndreptarea loviturilor fără vopsire",
			}
      },
	  {
        title: "Jante/Roți",
		  icon: wheel_ico,
		  services: {
			  service1: "Reparația jantelor (sudare)",
			  service2: "Shimbarea roților",
			  service3: "Îndreptarea jantelor",
			}
      },
	  {
        title: "Restaurarea interiorului",
		  icon: salon_ico,
		  services: {
			  service1: "Restaurarea volanului (tapițeria volanului)",
			  service2: "Restaurarea interiorului (tapițeria salonului)",
			  service3: "Restaurarea podului (tapițeria podului)",
			}
      },
	  {
        title: "Izolarea fonică",
		  icon: noiseoff_ico,
		  services: {
			  service1: "Izolarea fonică a caroseriei",
			}
      },
    ],
  },
  {
    id: 19,
	 url: "bizon" ,
    name: "Bizon — Lustruirea farurilor și ferestrelor auto",
    image: bizon_preview,
		bigImage: bizon_big,
    description: "Suntem unul dintre puținii de pe piața moldovenească care oferă lustruirea ferestrelor auto cu adevărat profesională. Chiar și în ciuda celor 10 ani de experiență în acest domeniu, ne îmbunătățim constant. Participăm la seminarie de creștere profesională. Poate că nu avem cele mai mici prețuri, dar suntem responsabili pentru calitate, iar asta înseamnă pentru siguranța dvs! ",
    phone: "+373 60 372 742",
    address: "or. Chișinău, Botanica, str. Muncești 81",
	 regiune: ["Chișinău"],
	 sector: ["Botanica"],
	 mapAddress: "https://maps.app.goo.gl/inf5NuyMUzJbUFc89",
    views: 1462,
	 orar: "Lu-Sâ: 09:00 — 18:00",
	 gallery: [bizon_img1, bizon_img2, bizon_img3, bizon_img4, bizon_img5],
	 iframeSrc:"https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d572.0051828831514!2d28.87002268684609!3d47.002336938052295!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x40c97c00c723fcd9%3A0xba32e067d2760164!2zyJhvc2VhdWEgTXVuY2XImXRpIDgxLCBDaGnImWluxIN1LCBNb2xkb3Zh!5e0!3m2!1sen!2s!4v1722575653086!5m2!1sen!2s" ,
    icons: [
		{
			url: ico1,
			available: true
		}, 
		{
			url: ico2,
			available: true
		}, 
		{
			url: ico3,
			available: true
		}, 
		{
			url: ico4,
			available: false
		}, 
		{
			url: ico5,
			available: false
		}, 
		{
			url: ico6,
			available: true
		}, 
		],
    serviceTypes: [
      {
        title: "Detailing/Lustruire",
		  icon: detailing_ico,
		  services: {
			  service1: "Lustruirea farurilor",
			  service2: "Lustruirea ferestrelor auto",
			}
      },
    ],
  },
  {
    id: 20,
	 url: "reikamd" ,
    name: "Reika.md — Reparația casetei de direcție pentru toate mărcile auto",
    image: reikamd_preview,
		bigImage: reikamd_big,
    description: "Efectuăm reparații de restaurare a cremalierelor de direcție de toate mărcile de mașini. Revizia cremaliera de directie este o alternativa mai profitabila la achizitionarea unuia nou, dar nu este de calitate inferioara in timpul reviziei, fiind si inlocuirea unor elemente cu altele ranforsate, mai potrivite conditiilor noastre de functionare; Efectuăm reparații la cremalierele de direcție de toate mărcile de mașini la cererea dumneavoastră, putem verifica pompa servodirecției, furtunurile servodirecției, scoatem, înlocuim sau instalam cremaliera de direcție pe mașina dumneavoastră, putem schimba o cremalieră de direcție defectă cu una restaurată sau ți-l vând. Reparațiile cremalierelor de direcție se efectuează cu echipamente specializate. Se folosesc doar componente originale de la producători: ZF; TRW ; Koyo; Smi ; NSK; Mando, etc. Dacă suportul de direcție este defect, atunci nu trebuie să cumpărați unul nou (uneori prețul unei unități noi depășește așteptările dumneavoastră cele mai nebunești). Crema de direcție poate fi restaurată și vă va servi încă mulți ani!",
    phone: "+373 78 335 335",
    address: "or. Chișinău, Râșcani, str. Bucovinei 9",
	 regiune: ["Chișinău"],
	 sector: ["Râșcani"],
	 mapAddress: "https://maps.app.goo.gl/1kgWsfG7FBQmwZAQ7",
    views: 1462,
	 orar: "Lu-Vi: 09:00 — 17:00, Sâ: 09:00 — 13:00",
	 gallery: [reikamd_img1, reikamd_img2, reikamd_img3, reikamd_img4, reikamd_img5],
	 iframeSrc:"https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d1358.7736198912671!2d28.870053585665588!3d47.068730126391266!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x40c97da41ce45305%3A0x5ed4a5d2ed0419b5!2sREIKA%20AUTOSERVICE!5e0!3m2!1sru!2s!4v1722946885324!5m2!1sru!2s" ,
    icons: [
		{
			url: ico1,
			available: true
		}, 
		{
			url: ico2,
			available: true
		}, 
		{
			url: ico3,
			available: true
		}, 
		{
			url: ico4,
			available: false
		}, 
		{
			url: ico5,
			available: false
		}, 
		{
			url: ico6,
			available: false
		}, 
		],
    serviceTypes: [
      {
        title: "Caseta de direcție",
		  icon: volan_ico,
		  services: {
			  service1: "Diagnosticarea casetei de direcție",
			  service2: "Înlocuirea casetei de direcție",
			  service3: "Repararea casetei de direcție",
			}
      },
      {
        title: "Șasiu",
		  icon: suspension_ico,
		  services: {
			  service1: "Diagnostarea șasiului (verificare hodovaia)",
			  service2: "Alinierea roților (razval)",
			}
      },
    ],
  },
  {
    id: 21,
	 url: "esapament-service" ,
    name: "Esapament Service - Reparația eșapamentului",
    image: esapserv_preview,
		bigImage: esapserv_big,
    description: "Eşapament Service, avînd o esperienţă de peste 20 ani şi fiind primul atelier specializat din Moldova, vă poate ajuta să rezolvaţi orice problemă legată de sistemele de eşapament, catalizatoare, filtre de particule şi multe altele. De-a lungul activităţii sale, Eşapament Service a avut cîteva scopuri şi principii: să menţină cel mai înalt nivel de calitate, să nu lase dezamăgit nici un client şi să nu existe probleme pe care să nu le poată rezolva.",
    phone: "+373 67 440 371",
    address: "or. Chișinău, Botanica, str. Fântânilor 21",
	 regiune: ["Chișinău"],
	 sector: ["Botanica"],
	 mapAddress: "https://maps.app.goo.gl/LKCdrnSFeEkhgtLF8",
    views: 1462,
	 orar: "Lu-Vi: 09:00 — 17.00",
	 gallery: [esapserv_img1, esapserv_img2, esapserv_img3, esapserv_img4, esapserv_img5],
	 iframeSrc:"https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d680.1500069336213!2d28.856749851829246!3d47.00882599017623!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x40c97c1ba6c7560f%3A0x130f267a45d2085a!2zU3RyLiBGw6JudMOibmlsb3IgMjEsIENoaciZaW7Eg3UsIE1vbGRvdmE!5e0!3m2!1sen!2s!4v1723396608688!5m2!1sen!2s" ,
    icons: [
		{
			url: ico1,
			available: true
		}, 
		{
			url: ico2,
			available: true
		}, 
		{
			url: ico3,
			available: true
		}, 
		{
			url: ico4,
			available: false
		}, 
		{
			url: ico5,
			available: false
		}, 
		{
			url: ico6,
			available: false
		}, 
		],
    serviceTypes: [
      {
        title: "Eșapament",
		  icon: ex_pipe_ico,
		  services: {
			  service1: "Reparația eșapamentului",
			  service2: "Sudarea eșapamentului",
			  service3: "Schimbarea eșapamentului",
			  service4: "Diagnostica eșapamentului",
			  service5: "Tuning eșapament (eșapament sportiv)",
			}
      },
    ],
  },
  {
    id: 22,
	 url: "turbo-profi" ,
    name: "Reparația turbinelor — Turbo-profi",
    image: turbopro_preview,
		bigImage: turbopro_big,
    description: "Acceptăm turbine din alte orașe, le reparăm și le trimitem proprietarului. Oferim piese de schimb pentru toate tipurile de turbine. Reparatii toate tipurile de turbine, echipamente speciale. Cel mai scurt timp posibil. Preturi mici, garantie de calitate.",
    phone: "+373 78 571 702",
    address: "or. Chișinău, Râșcani, str. Petricani 17/5",
	 regiune: ["Chișinău"],
	 sector: ["Râșcani"],
	 mapAddress: "https://maps.app.goo.gl/MMuXXM6aq78pmUuc9",
    views: 1462,
	 orar: "Lu-Vi: 09:00 — 18:00, Sâ: 09:00 — 14:00",
	 gallery: [turbopro_img1, turbopro_img2, turbopro_img3, turbopro_img4, turbopro_img5],
	 iframeSrc:"https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d1359.3988205713763!2d28.83189352275421!3d47.04420149791443!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x40c97daf6869a6fb%3A0x73c5d3823d48f25e!2sPetricani%20St%2017%2C%20Chi%C8%99in%C4%83u%2C%20Moldova!5e0!3m2!1sen!2s!4v1723781414820!5m2!1sen!2s" ,
    icons: [
		{
			url: ico1,
			available: true
		}, 
		{
			url: ico2,
			available: true
		}, 
		{
			url: ico3,
			available: true
		}, 
		{
			url: ico4,
			available: false
		}, 
		{
			url: ico5,
			available: false
		}, 
		{
			url: ico6,
			available: false
		}, 
		],
    serviceTypes: [
      {
        title: "Turbină/Turbocompresor",
		  icon: turbine_ico,
		  services: {
			  service1: "Diagnosticarea turbinei",
			  service2: "Verificarea și reglarea presiunii de supraalimentare",
			  service3: "Reparația turbinei",
			  service4: "Schimbarea turbinei",
			  service5: "Verificarea tuburilor sistemului de supraalimentare",
			  service6: "Reglarea geometriei turbinei",
			}
      },
    ],
  },
  {
    id: 23,
	 url: "bus-glass" ,
    name: "Ferestre Auto - preparare și instalare",
    image: busglass_preview,
		bigImage: busglass_big,
    description: "Ferestre auto pentru autoturisme și microbuze, față, spate, laterale.",
    phone: "+373 68 623 085",
    address: "or. Chișinău, Botanica, str. Sarmizegetusa 12",
	 regiune: ["Chișinău"],
	 sector: ["Botanica"],
	 mapAddress: "https://maps.app.goo.gl/xQasEUjUbXjDM4or6",
    views: 1462,
	 orar: "Lu-Vi: 09:00 — 18:00",
	 gallery: [busglass_img1, busglass_img2, busglass_img3, busglass_img4, busglass_img5],
	 iframeSrc:"https://www.google.com/maps/embed?pb=!1m10!1m8!1m3!1d1360.738722232871!2d28.86506451861001!3d46.99159752994075!3m2!1i1024!2i768!4f13.1!5e0!3m2!1sru!2s!4v1725965207015!5m2!1sru!2s" ,
    icons: [
		{
			url: ico1,
			available: true
		}, 
		{
			url: ico2,
			available: true
		}, 
		{
			url: ico3,
			available: true
		}, 
		{
			url: ico4,
			available: false
		}, 
		{
			url: ico5,
			available: false
		}, 
		{
			url: ico6,
			available: false
		}, 
		],
    serviceTypes: [
      {
        title: "Ferestre auto",
		  icon: car_wind_ico,
		  services: {
			  service1: "Înlocuirea parbrizului",
			  service2: "Înlocuirea ferestrelor auto",
			  service3: "Tăierea ferestrelor auto",
			}
      },
    ],
  },
	{
    id: 24,
	 url: "repairpe" ,
    name: "Repararea fasciei Peugeot. Reparația șasiului",
    image: repairpeu_preview,
		bigImage: repairpeu_big,
    description: "Restaurarea fasciculului spate la Peugeot 206, Citroen berlingo, Peugeor partner, Xsara, Picasso. Restabilirea completă a grinzii la starea din fabrică, înlocuirea bolțurilor, rulmenților, înlocuirea pârghiilor și a barelor de torsiune, silent blocks de pârghii, silent block flotante, toate piesele de schimb sunt originale, franțuzești, prețuri rezonabile, sunați-ne și vom negocia. După reparație, fasciculul este ca nou, păstrând în același timp calitatea din fabrică.",
    phone: "+373 79 239 689",
    address: "or. Chișinău, Botanica, str. Salcamilor 5A",
	 regiune: ["Chișinău"],
	 sector: ["Botanica"],
	 mapAddress: "https://maps.app.goo.gl/QBU9c3s48GY7vVT87",
    views: 1462,
	 orar: "Lu-Vi: 08:00 — 18:00",
	 gallery: [repairpeu_img1, repairpeu_img2, repairpeu_img3, repairpeu_img4, repairpeu_img5],
	 iframeSrc:"https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d1361.098950475886!2d28.896941432183844!3d46.977447438869895!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x40c9796fc7062057%3A0x2875ca59df760c0!2zU3RyYWRhIFNhbGPDom1pbG9yIDUsIENoaciZaW7Eg3UsINCc0L7Qu9C00L7QstCw!5e0!3m2!1sru!2s!4v1725965408001!5m2!1sru!2s" ,
    icons: [
		{
			url: ico1,
			available: true
		}, 
		{
			url: ico2,
			available: true
		}, 
		{
			url: ico3,
			available: true
		}, 
		{
			url: ico4,
			available: false
		}, 
		{
			url: ico5,
			available: false
		}, 
		{
			url: ico6,
			available: false
		}, 
		],
    serviceTypes: [
      {
        title: "Caseta de direcție",
		  icon: volan_ico,
		  services: {
			  service1: "Diagnosticarea casetei de direcție",
			  service2: "Înlocuirea casetei de direcție",
			  service3: "Repararea casetei de direcție",
			}
      },
      {
        title: "Șasiu",
		  icon: suspension_ico,
		  services: {
			  service1: "Diagnostarea șasiului (verificare hodovaia)",
			  service2: "Înlocuirea tijei, bucșei (șarovaia, tiaga, soldatic)",
			  service3: "Înlocuirea silent block-urilor (sailen bloc)",
			  service4: "Alinierea roților (razval)",
			  service5: "Înlocuirea rulmenților roților",
			  service6: "Înlocuirea discurilor și plăcuțelor de frână",
			  service7: "Înlocuirea planetarei (privod)",
			  service8: "Înlocuirea articulațiilor / crucii cardanice (granata)",
			  service9: "Repararea fasciculului",
			  service10: "Înlocuirea fasciculului",
			}
      },
	  {
        title: "Motor",
		  icon: engine_ico,
		  services: {
			  service1: "Înlocuirea lanțului / curelei GRM",
			  service2: "Înlocuirea pompei",
			}
      },
    ],
  },
  {
    id: 25,
	 url: "resormd" ,
    name: "Resor.md – Reparații autoturisme si camioane",
    image: resormd_preview,
		bigImage: resormd_big,
    description: "Oferim cele mai bune servicii pentru întreținerea și repararea autoturismelor si camioanelor.",
    phone: "+373 60 522 222",
    address: "or. Chișinău, Ciocana, str. Milescu Spătarul 38/1",
	 regiune: ["Chișinău"],
	 sector: ["Ciocana"],
	 mapAddress: "https://maps.app.goo.gl/9ysGnwUHfRuafUHM8",
    views: 1462,
	 orar: "Lu-Vi: 08:00 — 18:00, Sâ: 08:00 — 14:00",
	 gallery: [resormd_img1, resormd_img2, resormd_img3, resormd_img4, resormd_img5],
	 iframeSrc:"https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d12927.065634638155!2d28.89542305562332!3d47.06807322377689!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x40c97d149628de3b%3A0x4dff2a07a2cc40c4!2sRESOR.MD!5e0!3m2!1sen!2s!4v1725511659382!5m2!1sen!2s" ,
    icons: [
		{
			url: ico1,
			available: true
		}, 
		{
			url: ico2,
			available: true
		}, 
		{
			url: ico3,
			available: true
		}, 
		{
			url: ico4,
			available: false
		}, 
		{
			url: ico5,
			available: false
		}, 
		{
			url: ico6,
			available: false
		}, 
		],
    serviceTypes: [
      {
        title: "Motor",
		  icon: engine_ico,
		  services: {
			  service1: "Diagnostarea motorului",
			  service2: "Reparația capitală a motorului",
			  service3: "Verificarea bujiilor",
			  service4: "Diagnosticarea sistemului de aprindere",
			  service5: "Înlocuirea bujiilor",
			  service6: "Înlocuirea motorului",
			  service7: "Înlocuirea lanțului / curelei GRM",
			  service8: "Înlocuirea pompei",
			  service9: "Diagnosticarea și repararea sistemului de răcire",
			  service10: "Reglarea supapei",
			  service11: "Înlocuirea garniturii chiulasei (galovca cilindrilor)",
			}
      },
	  {
        title: "Sistem de frânare",
		  icon: brakes_ico,
		  services: {
			  service1: "Înlocuirea lichidului de frână",
			  service2: "Înlocuirea plăcuțelor de frană",
			  service3: "Reparație frână de mână",
			  service4: "Reglarea frânei de mână",
			  service5: "Reparația frânei",
			  service6: "Înlocuirea discurilor de frână",
			  service7: "Canelarea discurilor de frână",
			  service8: "Aerarea sistemului de frânare",
			  service9: "Înlocuirea furtunurilor de frână",
			  service10: "Înlocuirea cilindrilor de frână",
			  service11: "Reparația support de frână",
			}
      },
	  {
        title: "Cutie de viteze/Transmisie",
		  icon: car_manual_gearbox_ico,
		  services: {
			  service1: "Diagnosticarea transmisiei manuale",
			  service2: "Reparația cutiei de viteze manuale",
			  service3: "Reparația cutiei de viteze automate",
			  service4: "Înlocuirea cutiei de viteze manuale",
			  service5: "Înlocuirea ambreiajului (cutie manuală)",
			  service6: "Reglarea ambreiajului (cutie manuală)",
			  service7: "Repararea mânerului de schimbare a vitezei",
			}
      },
	  {
        title: "Electrica auto",
		  icon: energy_ico,
		  services: {
			  service1: "Verificarea și înlocuirea acumulatorului",
			  service2: "Diagnosticarea generatorului",
			  service3: "Repararea generatorului",
			  service4: "Înlocuirea generatorului",
			  service5: "Diagnosticarea starterului",
			  service6: "Repararea starterului",
			  service7: "Înlocuirea starterului",
			}
      },
	  {
        title: "Caseta de direcție",
		  icon: volan_ico,
		  services: {
			  service1: "Diagnosticarea casetei de direcție",
			  service2: "Înlocuirea casetei de direcție",
			  service3: "Repararea casetei de direcție",
			}
      },
	  {
        title: "Condiționer",
		  icon: ac_ico,
		  services: {
			  service1: "Încărcarea condiționerului cu freon",
			}
      },
	  {
        title: "Schimbarea filtre",
		  icon: car_filter_ico,
		  services: {
			  service1: "Înlocuirea filtrului de combustibil",
			  service2: "Înlocuirea filtrului de aer",
			  service3: "Înlocuirea filtrului de salon",
			  service4: "Înlocuirea filtrului de ulei",
			  service5: "Înlocuirea filtrului cutiei de viteze",
			}
      },
    ],
  },
  {
    id: 26,
	 url: "glass" ,
    name: "Schimbarea ferestrelor auto - lucrăm doar la comandă, venim la voi",
    image: glass_preview,
		bigImage: glass_big,
    description: "Înlocuire, dezmembrare, montaj, vanzari si toate tipurile de servicii in domeniul parbrizelor, geamurilor laterale și spate auto. Selectia si vanzarea sticlă personalizată, sticlă atermică (cameleon) si multe altele. Lucrăm prin toată Moldova, reduceri bune pentru clienții frecvenți.",
    phone: "+373 68 900 488",
    address: "or. Chișinău, Doar la comandă",
	 regiune: ["Chișinău"],
	 sector: ["Botanica"],
	 mapAddress: "https://maps.app.goo.gl/cLrPwQHykkccB9K17",
    views: 1462,
	 orar: "Lu-Sâ: 08:00 — 18:00",
	 gallery: [glass_img1, glass_img2, glass_img3, glass_img4, glass_img5],
	 iframeSrc:"https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d87055.34048087192!2d28.8382612059755!3d47.01119438650962!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x40c97c3628b769a1%3A0x37d1d6305749dd3c!2zQ2hpyJlpbsSDdSwgTW9sZG92YQ!5e0!3m2!1sen!2s!4v1725769275383!5m2!1sen!2s" ,
    icons: [
		{
			url: ico1,
			available: false
		}, 
		{
			url: ico2,
			available: false
		}, 
		{
			url: ico3,
			available: false
		}, 
		{
			url: ico4,
			available: false
		}, 
		{
			url: ico5,
			available: false
		}, 
		{
			url: ico6,
			available: true
		}, 
		],
    serviceTypes: [
      {
        title: "Ferestre auto",
		  icon: car_wind_ico,
		  services: {
			  service1: "Înlocuirea parbrizului",
			  service2: "Înlocuirea ferestrelor auto",
			}
      },
    ],
  },
  {
    id: 27,
	 url: "electric" ,
    name: "Electrician auto la comandă",
    image: electric_preview,
		bigImage: electric_big,
    description: "Oferim o gamă largă de servicii electrice auto cuprinzătoare: diagnosticare auto, eliminarea erorilor, dezactivarea alarmei, restaurarea cablajului, înlocuirea siguranțelor, înlocuirea becurilor și farurilor, mufelor, conectorilor. Reparatii electricieni auto Electronica auto, diagnosticare, reparatii unitati ECU, ELV, BSM, ABS, tablouri de bord, reparatii cablaj compartiment motor, schimbare iluminare instrument, transfer date, codare, adaptare, piese de schimb in stoc, laborator propriu, 21 ani la subiect, rezolvam orice problema la nivel hardware. Rezolvăm problema volanului Roșu pe toate generațiile de VAG Repar sistemul KEY LESS, repar unități de control al ventilatoarelor, lucrez cu osciloscop, rezolvăm situații de fund. Găsim o scurgere de curent, inclusiv una asociată cu magistrala CAN care nu adoarme. Vom instala orice echipament suplimentar. Sunați sau scrieți. Plecare. Deprotejarea componentelor.",
    phone: "+373 78 284 508",
    address: "or. Chișinău, Centru, str. București 6",
	 regiune: ["Chișinău"],
	 sector: ["Centru"],
	 mapAddress: "https://maps.app.goo.gl/Wod3sEzaQD9HyGwA9",
    views: 1462,
	 orar: "Lu-Sâ: 08:00 — 18:00",
	 gallery: [electric_img1, electric_img2, electric_img3, electric_img4, electric_img5],
	 iframeSrc:"https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d961.8125211551273!2d28.8423942101532!3d47.01242659704182!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x40c97c23c00e1b69%3A0xd5f07948a2ecb039!2zU3RyYWRhIEJ1Y3VyZciZdGkgNiwgTUQtMjAwMSwgQ2hpyJlpbsSDdSwg0JzQvtC70LTQvtCy0LA!5e0!3m2!1sru!2s!4v1725965556147!5m2!1sru!2s" ,
    icons: [
		{
			url: ico1,
			available: true
		}, 
		{
			url: ico2,
			available: false
		}, 
		{
			url: ico3,
			available: true
		}, 
		{
			url: ico4,
			available: true
		}, 
		{
			url: ico5,
			available: false
		}, 
		{
			url: ico6,
			available: false
		}, 
		],
    serviceTypes: [
      {
        title: "Electrica auto",
		  icon: energy_ico,
		  services: {
			  service1: "Diagnosticarea cablajului",
			  service2: "Repararea cablajului",
			  service3: "Înlocuirea siguranțelor și releelor ",
			  service4: "Verificarea și înlocuirea acumulatorului",
			  service5: "Diagnosticarea generatorului",
			  service6: "Diagnosticarea starterului",
			  service7: "Instalare și configurare a echipamentelor suplimentare (camera, Android auto)",
			  service8: "Verificarea si repararea corpurilor de iluminat",
			  service9: "Repararea tabloului de bord (vitezometru, spidometr)",
			}
      },
	  {
        title: "EGR și filtru de particule",
		  icon: catalyst_ico,
		  services: {
			  service1: "Deconectarea computerizată a supapei EGR",
			  service2: "Îndepărtarea (tăierea) filtrului de particule",
			}
      },
    ],
  },
];


// randomizer
function shuffleArray(array) {
  let shuffledArray = array.slice();
  for (let i = shuffledArray.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1));
    [shuffledArray[i], shuffledArray[j]] = [shuffledArray[j], shuffledArray[i]];
  }
  return shuffledArray;
}
const servicesRo = shuffleArray(array);


export default servicesRo;
