import React from "react";
import Router from "./router/router";
import "./assets/css/style.css";
import "./components/i18";
import { BrowserRouter } from "react-router-dom";

function App() {
  return (
    <div className="">
      <BrowserRouter>
      <Router />
    </BrowserRouter>
    </div>
  );
}

export default App;
