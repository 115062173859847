import React, { useEffect } from "react";
import { Routes, Route, useNavigate, useLocation } from "react-router-dom";

import Home from "../pages/Home";
import ServiceDetails from "../pages/ServiceDetails.js"; 
import ServiceList from "../pages/ServiceList.js"; 

const Router = () => {
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    const savedLanguage = localStorage.getItem("selectedLanguage") || "ro";
    const currentPath = location.pathname;
    const pathSegments = currentPath.split('/').filter(Boolean);

    if (!pathSegments[0] || !["ro", "en", "ru"].includes(pathSegments[0])) {
      navigate(`/${savedLanguage}${currentPath}`, { replace: true });
    }
  }, [location, navigate]);

  return (
    <Routes>
      <Route path="/:lang">
        <Route index element={<Home />} /> 
        <Route path="list" element={<ServiceList />} /> 
        <Route path=":serviceName" element={<ServiceDetails />} /> 
      </Route>
    </Routes>
  );
};

export default Router;
