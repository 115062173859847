import React, { useEffect, useState, useContext } from "react";
import { Link, useNavigate, useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import search_01__1 from "../../assets/img/search-01 (1).svg";
import search_01 from "../../assets/img/search-01.svg";
import logo_fixauto from "../../assets/img/logo-fixauto.svg";
import sparkles from "../../assets/img/sparkles.svg";
import Polygon from "../../assets/img/Polygon.svg";
import language from "../../assets/img/Vector.svg";
import Cancel from "../../assets/img/cancel.svg";
import "./header.css";
import { useDropDown } from "../../contexts/dropDown.context";
import { LanguageContext } from "../../contexts/language.context";

const ServiceDetailHeader = () => {
  const [isDrop, setIsDrop] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [isSearch, setIsSearch] = useState(false);
  
  const { resetSelectedOptions } = useDropDown();
  const { selectedLanguage, changeLanguage } = useContext(LanguageContext);
  const navigate = useNavigate();
  const location = useLocation();
  const query = new URLSearchParams(location.search).get("query");

  const { t } = useTranslation();
  const languages = [
    { code: "en", lang: "EN" },
    { code: "ro", lang: "RO" },
    { code: "ru", lang: "RU" },
  ];

  useEffect(() => {
    if (query) {
      setSearchTerm(query);
    }
  }, [query]);

  useEffect(() => {
    const currentPath = location.pathname;
    const pathSegments = currentPath.split('/').filter(Boolean);

    if (pathSegments[0] !== selectedLanguage) {
      const newPath = `/${selectedLanguage}/${pathSegments.slice(1).join('/')}`;
      navigate(newPath, { replace: true });
    }
  }, [selectedLanguage, location, navigate]);

  const toggleSearch = () => {
    setIsSearch(!isSearch);
  };

  const toggleDrop = () => {
    setIsDrop(!isDrop);
  };

  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value);
  };

  const handleSearchSubmit = (event) => {
    event.preventDefault();
    resetSelectedOptions();

    if (searchTerm) {
      const currentLanguage = selectedLanguage.toLowerCase();
      navigate(`/${currentLanguage}/list?query=${searchTerm}`);
    }
  };

  const handleLanguageChange = (lng) => {
    changeLanguage(lng);
    setIsDrop(false);

    const currentPath = location.pathname;
    const pathSegments = currentPath.split('/').filter(Boolean);

    if (["en", "ro", "ru"].includes(pathSegments[0])) {
      pathSegments.shift();
    }

    const newPath = `/${lng}/${pathSegments.join('/')}`;
    navigate(newPath, { replace: true });
  };

  return (
    <div className="main-header">
      <div className="top-banner">
        <a
          href="https://t.me/fixautomd"
          target="_blank"
          className="max-width for-desk banner-data"
        >
          <img src={sparkles} alt="" />
          <p className="plce">{t("header.topBanner")}</p>
          <p className="plce2">
            <span>{t("header.cta")}</span> <span className="bn">→</span>
          </p>
        </a>
        <a
          href="https://t.me/fixautomd"
          target="_blank"
          className="max-width for-mbl fsw banner-data"
        >
          <p className="plce">{t("header.topBanner")}</p>
          <p className="plce2">→</p>
        </a>
      </div>
      <div className="nav-bar">
        <div className="max-width for-service inner-bar">
          {isSearch ? (
            <form
              className="search-input ad-in search-hm"
              onSubmit={handleSearchSubmit}
            >
              <img src={search_01} alt="" />
              <input
                className="searcher"
                value={searchTerm}
                onChange={handleSearchChange}
                type="text"
                placeholder={t("homePage.banner.searchPlaceholder")}
              />
              <input
                type="submit"
                className="search-btn hide"
                value={t("homePage.banner.searchBtn")}
              />
              <img
                onClick={toggleSearch}
                className="cancel"
                src={Cancel}
                alt=""
              />
            </form>
          ) : (
            <>
              <div onClick={toggleSearch} className="for-mbl searchMobile">
                <img src={search_01__1} alt="" />
              </div>
              <div className="fix-br">
                <Link className="flexb" to="/">
                  <img src={logo_fixauto} alt="" />
                </Link>
                <form
                  className="search-input for-desk bar-input"
                  onSubmit={handleSearchSubmit}
                >
                  <img src={search_01} alt="" />
                  <input
                    className="searcher"
                    value={searchTerm}
                    onChange={handleSearchChange}
                    type="text"
                    placeholder={t("homePage.banner.searchPlaceholder")}
                  />
                  <input
                    type="submit"
                    className="search-btn"
                    value={t("homePage.banner.searchBtn")}
                  />
                </form>
              </div>
              <div className="drop-down">
                <div onClick={toggleDrop} className="lang-drop">
                  <img src={language} alt="Language" />
                  <span className="flex uppercase">
                    {selectedLanguage.toUpperCase()}{" "}
                    <img
                      src={Polygon}
                      className={isDrop ? "uactive" : "unactive"}
                      alt="Dropdown"
                    />
                  </span>
                </div>
                {isDrop && (
                  <div className="active-drop">
                    {languages.map((lng) => (
                      <div
                        key={lng.code}
                        onClick={() => handleLanguageChange(lng.code)}
                        className="selector"
                      >
                        {lng.lang}
                      </div>
                    ))}
                  </div>
                )}
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default ServiceDetailHeader;
