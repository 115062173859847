import shock from "../../assets/img/shock.jpg";
import comfort2 from "../../assets/img/comfort2.svg";
import ser2 from "../../assets/img/ser2.svg";
import ico1 from "../../assets/img/ico1.svg";
import ico2 from "../../assets/img/ico2.svg";
import ico3 from "../../assets/img/ico3.svg";
import ico4 from "../../assets/img/ico4.svg";
import ico5 from "../../assets/img/ico5.svg";
import ico6 from "../../assets/img/ico6.svg";
import ico7 from "../../assets/img/ico7.svg";
import ico8 from "../../assets/img/ico8.svg";
import about from "../../assets/img/about.png";
import gallery from "../../assets/img/gallery.png";
import detailing_ico from "../../assets/img/detailing-ico.svg";
import paint_ico from "../../assets/img/paint-ico.svg";
import suspension_ico from "../../assets/img/suspension-ico.svg";
import absorber_ico from "../../assets/img/absorber-ico.svg";
import engine_ico from "../../assets/img/engine-ico.svg";
import ac_ico from "../../assets/img/ac-ico.svg";
import brakes_ico from "../../assets/img/brakes-ico.svg";
import bumper_ico from "../../assets/img/bumper-ico.svg";
import far_ico from "../../assets/img/lights-ico.svg";
import noiseoff_ico from "../../assets/img/mute-ico.svg";
import salon_ico from "../../assets/img/salon-ico.svg";
import rihtovka_ico from "../../assets/img/rihtovka-ico.svg";
import atm_ico from "../../assets/img/automatic-transmission-ico.svg";
import car_filter_ico from "../../assets/img/car-filter-ico.svg";
import car_key_ico from "../../assets/img/car-key-ico.svg";
import car_manual_gearbox_ico from "../../assets/img/car-manual-gearbox-ico.svg";
import diagnostic_ico from "../../assets/img/diagnostic-ico.svg";
import hybrid_ico from "../../assets/img/hybrid-ico.svg";
import oil_change_ico from "../../assets/img/oil-change-ico.svg";
import PC_diagnostics_ico from "../../assets/img/PC-diagnostics-ico.svg";
import car_lock_ico from "../../assets/img/car-lock-ico.svg";
import turbine_ico from "../../assets/img/turbine-ico.svg";
import volan_ico from "../../assets/img/volan-ico.svg";
import wheel_ico from "../../assets/img/wheel-ico.svg";
import chip_tuning_ico from "../../assets/img/tuning-chip-ico.svg";
import catalyst_ico from "../../assets/img/catalyst-ico.svg";
import energy_ico from "../../assets/img/energy-ico.svg";
import amort_preview from "../../assets/img/amort-preview.png";
import amort_big from "../../assets/img/amort-big.png";
import amort_img1 from "../../assets/img/amort-img1.png";
import amort_img2 from "../../assets/img/amort-img2.png";
import amort_img3 from "../../assets/img/amort-img3.png";
import amort_img4 from "../../assets/img/amort-img4.png";
import amort_img5 from "../../assets/img/amort-img5.png";
import ww_preview from "../../assets/img/ww-preview.png";
import ww_big from "../../assets/img/ww-big.png";
import ww_img1 from "../../assets/img/ww-img1.png";
import ww_img2 from "../../assets/img/ww-img2.png";
import ww_img3 from "../../assets/img/ww-img3.png";
import ww_img4 from "../../assets/img/ww-img4.png";
import ww_img5 from "../../assets/img/ww-img5.png";
import hl_preview from "../../assets/img/hl-preview.png";
import hl_big from "../../assets/img/hl-big.png";
import hl_img1 from "../../assets/img/hl-img1.png";
import hl_img2 from "../../assets/img/hl-img2.png";
import hl_img3 from "../../assets/img/hl-img3.png";
import hl_img4 from "../../assets/img/hl-img4.png";
import hl_img5 from "../../assets/img/hl-img5.png";
import key_auto_preview from "../../assets/img/key-auto-preview.png";
import key_auto_big from "../../assets/img/key-auto-big.png";
import key_auto_img1 from "../../assets/img/key-auto-img1.png";
import key_auto_img2 from "../../assets/img/key-auto-img2.png";
import key_auto_img3 from "../../assets/img/key-auto-img3.png";
import key_auto_img4 from "../../assets/img/key-auto-img4.png";
import key_auto_img5 from "../../assets/img/key-auto-img5.png";
import simple_s_preview from "../../assets/img/simple-s-preview.png";
import simple_s_big from "../../assets/img/simple-s-big.png";
import simple_s_img1 from "../../assets/img/simple-s-img1.png";
import simple_s_img2 from "../../assets/img/simple-s-img2.png";
import simple_s_img3 from "../../assets/img/simple-s-img3.png";
import simple_s_img4 from "../../assets/img/simple-s-img4.png";
import simple_s_img5 from "../../assets/img/simple-s-img5.png";
import fcs_preview from "../../assets/img/fcs-preview.png";
import fcs_big from "../../assets/img/fcs-big.png";
import fcs_img1 from "../../assets/img/fcs-img1.png";
import fcs_img2 from "../../assets/img/fcs-img2.png";
import fcs_img3 from "../../assets/img/fcs-img3.png";
import fcs_img4 from "../../assets/img/fcs-img4.png";
import fcs_img5 from "../../assets/img/fcs-img5.png";
import autof_preview from "../../assets/img/autof-preview.png";
import autof_big from "../../assets/img/autof-big.png";
import autof_img1 from "../../assets/img/autof-img1.png";
import autof_img2 from "../../assets/img/autof-img2.png";
import autof_img3 from "../../assets/img/autof-img3.png";
import autof_img4 from "../../assets/img/autof-img4.png";
import autof_img5 from "../../assets/img/autof-img5.png";
import complex_preview from "../../assets/img/complex-preview.png";
import complex_big from "../../assets/img/complex-big.png";
import complex_img1 from "../../assets/img/complex-img1.png";
import complex_img2 from "../../assets/img/complex-img2.png";
import complex_img3 from "../../assets/img/complex-img3.png";
import complex_img4 from "../../assets/img/complex-img4.png";
import complex_img5 from "../../assets/img/complex-img5.png";
import sst_preview from "../../assets/img/sst-preview.png";
import sst_big from "../../assets/img/sst-big.png";
import sst_img1 from "../../assets/img/sst-img1.png";
import sst_img2 from "../../assets/img/sst-img2.png";
import sst_img3 from "../../assets/img/sst-img3.png";
import sst_img4 from "../../assets/img/sst-img4.png";
import sst_img5 from "../../assets/img/sst-img5.png";
import starterp_preview from "../../assets/img/starterp-preview.png";
import starterp_big from "../../assets/img/starterp-big.png";
import starterp_img1 from "../../assets/img/starterp-img1.png";
import starterp_img2 from "../../assets/img/starterp-img2.png";
import starterp_img3 from "../../assets/img/starterp-img3.png";
import starterp_img4 from "../../assets/img/starterp-img4.png";
import starterp_img5 from "../../assets/img/starterp-img5.png";
import mireco_preview from "../../assets/img/mireco-preview.png";
import mireco_big from "../../assets/img/mireco-big.png";
import mireco_img1 from "../../assets/img/mireco-img1.png";
import mireco_img2 from "../../assets/img/mireco-img2.png";
import mireco_img3 from "../../assets/img/mireco-img3.png";
import mireco_img4 from "../../assets/img/mireco-img4.png";
import mireco_img5 from "../../assets/img/mireco-img5.png";
import ecot_preview from "../../assets/img/ecot-preview.png";
import ecot_big from "../../assets/img/ecot-big.png";
import ecot_img1 from "../../assets/img/ecot-img1.png";
import ecot_img2 from "../../assets/img/ecot-img2.png";
import ecot_img3 from "../../assets/img/ecot-img3.png";
import ecot_img4 from "../../assets/img/ecot-img4.png";
import ecot_img5 from "../../assets/img/ecot-img5.png";
import bogcar_preview from "../../assets/img/bogcar-preview.png";
import bogcar_big from "../../assets/img/bogcar-big.png";
import bogcar_img1 from "../../assets/img/bogcar-img1.png";
import bogcar_img2 from "../../assets/img/bogcar-img2.png";
import bogcar_img3 from "../../assets/img/bogcar-img3.png";
import bogcar_img4 from "../../assets/img/bogcar-img4.png";
import bogcar_img5 from "../../assets/img/bogcar-img5.png";
import bampermd_preview from "../../assets/img/bampermd-preview.png";
import bampermd_big from "../../assets/img/bampermd-big.png";
import bampermd_img1 from "../../assets/img/bampermd-img1.png";
import bampermd_img2 from "../../assets/img/bampermd-img2.png";
import bampermd_img3 from "../../assets/img/bampermd-img3.png";
import bampermd_img4 from "../../assets/img/bampermd-img4.png";
import bampermd_img5 from "../../assets/img/bampermd-img5.png";
import olds_preview from "../../assets/img/olds-preview.png";
import olds_big from "../../assets/img/olds-big.png";
import olds_img1 from "../../assets/img/olds-img1.png";
import olds_img2 from "../../assets/img/olds-img2.png";
import olds_img3 from "../../assets/img/olds-img3.png";
import olds_img4 from "../../assets/img/olds-img4.png";
import olds_img5 from "../../assets/img/olds-img5.png";
import detl_preview from "../../assets/img/detl-preview.png";
import detl_big from "../../assets/img/detl-big.png";
import detl_img1 from "../../assets/img/detl-img1.png";
import detl_img2 from "../../assets/img/detl-img2.png";
import detl_img3 from "../../assets/img/detl-img3.png";
import detl_img4 from "../../assets/img/detl-img4.png";
import detl_img5 from "../../assets/img/detl-img5.png";
import detg_preview from "../../assets/img/detg-preview.png";
import detg_big from "../../assets/img/detg-big.png";
import detg_img1 from "../../assets/img/detg-img1.png";
import detg_img2 from "../../assets/img/detg-img2.png";
import detg_img3 from "../../assets/img/detg-img3.png";
import detg_img4 from "../../assets/img/detg-img4.png";
import detg_img5 from "../../assets/img/detg-img5.png";
import bizon_preview from "../../assets/img/bizon-preview.png";
import bizon_big from "../../assets/img/bizon-big.png";
import bizon_img1 from "../../assets/img/bizon-img1.png";
import bizon_img2 from "../../assets/img/bizon-img2.png";
import bizon_img3 from "../../assets/img/bizon-img3.png";
import bizon_img4 from "../../assets/img/bizon-img4.png";
import bizon_img5 from "../../assets/img/bizon-img5.png";
import reikamd_preview from "../../assets/img/reikamd-preview.png";
import reikamd_big from "../../assets/img/reikamd-big.png";
import reikamd_img1 from "../../assets/img/reikamd-img1.png";
import reikamd_img2 from "../../assets/img/reikamd-img2.png";
import reikamd_img3 from "../../assets/img/reikamd-img3.png";
import reikamd_img4 from "../../assets/img/reikamd-img4.png";
import reikamd_img5 from "../../assets/img/reikamd-img5.png";
import esapserv_preview from "../../assets/img/esapserv-preview.png";
import esapserv_big from "../../assets/img/esapserv-big.png";
import esapserv_img1 from "../../assets/img/esapserv-img1.png";
import esapserv_img2 from "../../assets/img/esapserv-img2.png";
import esapserv_img3 from "../../assets/img/esapserv-img3.png";
import esapserv_img4 from "../../assets/img/esapserv-img4.png";
import esapserv_img5 from "../../assets/img/esapserv-img5.png";
import turbopro_preview from "../../assets/img/turbopro-preview.png";
import turbopro_big from "../../assets/img/turbopro-big.png";
import turbopro_img1 from "../../assets/img/turbopro-img1.png";
import turbopro_img2 from "../../assets/img/turbopro-img2.png";
import turbopro_img3 from "../../assets/img/turbopro-img3.png";
import turbopro_img4 from "../../assets/img/turbopro-img4.png";
import turbopro_img5 from "../../assets/img/turbopro-img5.png";
import busglass_preview from "../../assets/img/busglass-preview.png";
import busglass_big from "../../assets/img/busglass-big.png";
import busglass_img1 from "../../assets/img/busglass-img1.png";
import busglass_img2 from "../../assets/img/busglass-img2.png";
import busglass_img3 from "../../assets/img/busglass-img3.png";
import busglass_img4 from "../../assets/img/busglass-img4.png";
import busglass_img5 from "../../assets/img/busglass-img5.png";
import repairpeu_preview from "../../assets/img/repairpeu-preview.png";
import repairpeu_big from "../../assets/img/repairpeu-big.png";
import repairpeu_img1 from "../../assets/img/repairpeu-img1.png";
import repairpeu_img2 from "../../assets/img/repairpeu-img2.png";
import repairpeu_img3 from "../../assets/img/repairpeu-img3.png";
import repairpeu_img4 from "../../assets/img/repairpeu-img4.png";
import repairpeu_img5 from "../../assets/img/repairpeu-img5.png";
import resormd_preview from "../../assets/img/resormd-preview.png";
import resormd_big from "../../assets/img/resormd-big.png";
import resormd_img1 from "../../assets/img/resormd-img1.png";
import resormd_img2 from "../../assets/img/resormd-img2.png";
import resormd_img3 from "../../assets/img/resormd-img3.png";
import resormd_img4 from "../../assets/img/resormd-img4.png";
import resormd_img5 from "../../assets/img/resormd-img5.png";
import glass_preview from "../../assets/img/glass-preview.png";
import glass_big from "../../assets/img/glass-big.png";
import glass_img1 from "../../assets/img/glass-img1.png";
import glass_img2 from "../../assets/img/glass-img2.png";
import glass_img3 from "../../assets/img/glass-img3.png";
import glass_img4 from "../../assets/img/glass-img4.png";
import glass_img5 from "../../assets/img/glass-img5.png";
import electric_preview from "../../assets/img/electric-preview.png";
import electric_big from "../../assets/img/electric-big.png";
import electric_img1 from "../../assets/img/electric-img1.png";
import electric_img2 from "../../assets/img/electric-img2.png";
import electric_img3 from "../../assets/img/electric-img3.png";
import electric_img4 from "../../assets/img/electric-img4.png";
import electric_img5 from "../../assets/img/electric-img5.png";
import tonirovka_preview from "../../assets/img/tonirovka-preview.png";
import tonirovka_big from "../../assets/img/tonirovka-big.png";
import tonirovka_img1 from "../../assets/img/tonirovka-img1.png";
import tonirovka_img2 from "../../assets/img/tonirovka-img2.png";
import tonirovka_img3 from "../../assets/img/tonirovka-img3.png";
import tonirovka_img4 from "../../assets/img/tonirovka-img4.png";
import tonirovka_img5 from "../../assets/img/tonirovka-img5.png";
import ex_pipe_ico from "../../assets/img/ex-pipe-ico.svg"
import car_wind_ico from "../../assets/img/car-wind-ico.svg";
export const jsonDataEn = {
  orase: [
    {
      nume: "Chisinau",
      raioane: [
        "Botanica",
        "Centru",
        "Buiucani",
        "Sculeni",
        "Riscani",
        "Ciocana",
        "Airport",
        "Durlesti",
      ],
    },
  ],
  raioane: {},
};

const array = [
  {
    id: 1,
    url: "amortmd",
    name: "Amort.md — Shock Absorber Repair",
    image: amort_preview,
    bigImage: amort_big,
    description:
      "Amort.md — your professional service partner specializing in shock absorber repairs. We understand that the efficient operation of the suspension system plays a crucial role in the comfort and safety of your vehicle.",
    phone: "+373 67 309 001",
    address: "Chisinau, sec. Botanica, Muncesti St. 255A",
    regiune: ["Chisinau"],
    sector: ["Botanica"],
    mapAddress: "https://maps.app.goo.gl/ABH69WASQcoF1oWs5",
    views: 1462,
    orar: "Mon-Fri:: 08:30 — 17:30",
    gallery: [amort_img1, amort_img2, amort_img3, amort_img4, amort_img5],
    iframeSrc:
      "https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d240.58570989066718!2d28.89737042963562!3d46.98297288692172!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x40c9798f495a902d%3A0x17a872ae590bff95!2zQW1vcnQubWQg4oCUINCg0LXQvNC-0L3RgiDQsNC80L7RgNGC0LjQt9Cw0YLQvtGA0L7Qsg!5e0!3m2!1sen!2s!4v1721456456865!5m2!1sen!2s",
    icons: [
      {
        url: ico1,
        available: true,
      },
      {
        url: ico2,
        available: true,
      },
      {
        url: ico3,
        available: true,
      },
      {
        url: ico4,
        available: true,
      },
      {
        url: ico5,
        available: true,
      },
      {
        url: ico6,
        available: false,
      },
    ],
    serviceTypes: [
      {
        title: "Shock Absorbers/Suspension",
        icon: absorber_ico,
        services: {
          service1: "Shock Absorber Adjustment",
          service2: "Shock Absorber Repair",
          service3: "Shock Absorber Diagnostics",
          service4: "Air Suspension Repair",
          service5: "Shock Absorber Replacement",
          service6: "Air System Repair",
          service7: "Air Compressor Repair",
        },
      },
    ],
  },
  {
    id: 2,
    url: "ww",
    name: "White Wolf — engine and chassis repair",
    image: ww_preview,
    bigImage: ww_big,
    description:
      "We offer full engine diagnostics and repairs, as well as replacement of worn chassis parts, and other service work for your car. Our specialists use only high-quality spare parts and modern repair methods. We guarantee the reliability and long-term nature of the work performed.",
    phone: "+373 79 480 316",
    address: "Chisinau, sec. Riscani, St. Petricani 141/1",
    regiune: ["Chisinau"],
    sector: ["Riscani"],
    mapAddress: "https://maps.app.goo.gl/aVSsZ5bhHZZti9X96",
    views: 1462,
    orar: "Mon-Fri: 08:00 — 19:00, Sat: 08:00 — 15:00",
    gallery: [ww_img1, ww_img2, ww_img3, ww_img4, ww_img5],

    iframeSrc:
      "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d1359.271852208284!2d28.822486760031108!3d47.049183888776085!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x40c97d007ef27887%3A0x6b41219f514812a1!2zV2hpdGUgV29sZiAtINGA0LXQvNC-0L3RgiDQtNCy0LjQs9Cw0YLQtdC70Y8g0Lgg0YXQvtC00L7QstC-0Lk!5e0!3m2!1sen!2s!4v1725774321163!5m2!1sen!2s",
    icons: [
      {
        url: ico1,
        available: false,
      },
      {
        url: ico2,
        available: true,
      },
      {
        url: ico3,
        available: true,
      },
      {
        url: ico4,
        available: false,
      },
      {
        url: ico5,
        available: false,
      },
      {
        url: ico6,
        available: true,
      },
    ],
    serviceTypes: [
      {
        title: "Chassis",
        icon: suspension_ico,
        services: {
          service1: "Chassis diagnostics/check",
          service2: "Ball joints, rods, bushings replacement",
          service3: "Silent blocks replacement",
          service4: "Wheel alignment",
          service5: "Brake discs and pads replacement",
          service6: "Replacement of brake discs and pads и колодок",
          service7: "Drive replacement",
          service8: "Joints/grenades replacement",
        },
      },
      {
        title: "Gearbox/Transmission",
        icon: car_manual_gearbox_ico,
        services: {
          service1: "Gearbox repair (manual)",
          service2: "Manual gearbox diagnostics",
          service3: "Clutch replacement (manual)",
          service4: "Clutch adjustment (manual)",
        },
      },
      {
        title: "Diagnostics",
        icon: diagnostic_ico,
        services: {
          service1: "Engine diagnostics",
          service2: "Chassis diagnostics",
          service3: "Brake system diagnostics",
        },
      },
      {
        title: "Steering rack",
        icon: volan_ico,
        services: {
          service1: "Steering rack diagnostics",
          service2: "Steering rack replacement",
          service3: "Steering rack repair",
        },
      },
      {
        title: "Shock absorbers/suspension",
        icon: absorber_ico,
        services: {
          service1: "Shock absorber replacement",
          service2: "Spring replacement",
        },
      },
      {
        title: "Disks/wheels",
        icon: wheel_ico,
        services: {
          service1: "Tire replacement (change of tires)",
          service2: "Wheel balancing",
        },
      },
      {
        title: "Engine (Motor)",
        icon: engine_ico,
        services: {
          service1: "Engine diagnostics",
          service2: "Engine (motor) overhaul",
          service3: "Checking spark plugs",
          service4: "Ignition system diagnostics",
          service5: "Spark plug replacement",
          service6: "Engine replacement",
          service7: "Timing chain/belt replacement",
          service8: "Cooling system diagnostics and repair",
          service9: "Valve adjustment",
          service10: "Cylinder head gasket replacement",
          service11: "Crankcase gasket replacement",
        },
      },
      {
        title: "Computer diagnostics",
        icon: PC_diagnostics_ico,
        services: {
          service1: "Full diagnostics of electronic vehicle systems",
          service2: "Error removal",
        },
      },
      {
        title: "Filters",
        icon: car_filter_ico,
        services: {
          service1: "Fuel filter replacement",
          service2: "Air filter replacement",
          service3: "Cabin filter replacement",
          service4: "Oil filter replacement",
          service5: "Gearbox filter replacement",
        },
      },
      {
        title: "Oils and liquids",
        icon: oil_change_ico,
        services: {
          service1: "Engine oil change",
          service2: "Manual transmission oil change",
          service3: "Automatic transmission oil change",
          service4: "Power steering oil change",
          service5: "Tosol/antifreeze change",
          service6: "Brake fluid change",
        },
      },
    ],
  },
  {
    id: 3,
    url: "hybrid-lider",
    name: "Hybrid Lider — repair of hybrid and electric cars",
    image: hl_preview,
    bigImage: hl_big,
    description:
      "Hybrid Lider — first-class repair of hybrid and electric cars. Complete diagnostics and repair of hybrid and electric systems of your car. Highly qualified specialists use the latest technologies and original spare parts. We provide reliability and excellent quality of the work performed.",
    phone: "+373 60 998 800",
    address: "Chisinau, sec. Botanica, St. Muncesti 271a",
    regiune: ["Chisinau"],
    sector: ["Botanica"],
    mapAddress: "https://maps.app.goo.gl/ngyCHHQWbbjDU9YZA",
    views: 1462,
    orar: "Mon-Fri:: 08:00 — 19:00",
    gallery: [hl_img1, hl_img2, hl_img3, hl_img4, hl_img5],
    iframeSrc:
      "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2722.0177886817805!2d28.901709076765968!3d46.98098523096903!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x40c979136072ec09%3A0x83088199f15e3d72!2sHibrit%20Lider!5e0!3m2!1sen!2s!4v1721555465695!5m2!1sen!2s",
    icons: [
      {
        url: ico1,
        available: false,
      },
      {
        url: ico2,
        available: true,
      },
      {
        url: ico3,
        available: true,
      },
      {
        url: ico4,
        available: true,
      },
      {
        url: ico5,
        available: true,
      },
      {
        url: ico6,
        available: false,
      },
    ],
    serviceTypes: [
      {
        title: "Hybrid/electric equipment",
        icon: hybrid_ico,
        services: {
          service1: "Battery diagnostics (hybrid, plug-in)",
          service2: "Battery repair",
          service3: "Battery replacement",
          service4: "Battery/section calibration, balancing",
        },
      },
    ],
  },
  {
    id: 4,
    url: "key-auto",
    name: "Key Auto — car key repair",
    image: key_auto_preview,
    bigImage: key_auto_big,
    description:
      "Key Auto — the service provides a wide range of car key repair services, including case replacement and key cutting. We also offer professional key repair and battery replacement for all types of car keys. We also repair and maintain locks to ensure the safety and reliability of your car. Our team of experts is ready to help you in any situation related to car keys and locks.",
    phone: "+373 60 299 285",
    address: "Chisinau, sec. Botanica, St. Melestiu 24/7",
    regiune: ["Chisinau"],
    sector: ["Botanica"],
    mapAddress: "https://maps.app.goo.gl/NHpmUUEXaBcRDL9s6",
    views: 1462,
    orar: "Mon-Fri: 09.00 — 18.00, Sat: 09.00 — 15:00",
    gallery: [
      key_auto_img1,
      key_auto_img2,
      key_auto_img3,
      key_auto_img4,
      key_auto_img5,
    ],
    iframeSrc:
      "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d680.2479198890907!2d28.84216909724483!3d47.00113633271606!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x40c97eb00068d995%3A0x9cafc86ac72127c3!2sCarKeys!5e0!3m2!1sen!2s!4v1721510493405!5m2!1sen!2s",
    icons: [
      {
        url: ico1,
        available: true,
      },
      {
        url: ico2,
        available: true,
      },
      {
        url: ico3,
        available: true,
      },
      {
        url: ico4,
        available: true,
      },
      {
        url: ico5,
        available: false,
      },
      {
        url: ico6,
        available: true,
      },
    ],
    serviceTypes: [
      {
        title: "Car keys",
        icon: car_key_ico,
        services: {
          service1: "Key case replacement",
          service2: "Key cutting",
          service3: "Key repair",
          service4: "New key preparing",
          service5: "Key battery replacement",
          service6: "Key programming",
          service7: "Car key duplicate",
        },
      },
      {
        title: "Car locks",
        icon: car_lock_ico,
        services: {
          service1: "Lock repair",
          service2: "Lock maintenance",
        },
      },
    ],
  },
  {
    id: 5,
    url: "bogcar-service",
    name: "BogCar - chassis and air conditioning repair",
    image: bogcar_preview,
    bigImage: bogcar_big,
    description:
      "We use only proven spare parts and the latest technologies, guaranteeing high quality of work and efficiency. Trust us with your car, and we will take care of it at the highest level! We specialize in chassis repair and complete air conditioning repair. Our experienced craftsmen will provide high-quality service for your car.",
    phone: "+373 79 225 502",
    address: "Chisinau, sec. Botanica, St. Dacia 2",
    regiune: ["Chisinau"],
    sector: ["Botanica"],
    mapAddress: "https://maps.app.goo.gl/Sdzwg9TB8WxYDvfA9",
    views: 1462,
    orar: "Mon-Fri: 09:00 — 18:00, Sat: 09:00 — 14:00",
    gallery: [bogcar_img1, bogcar_img2, bogcar_img3, bogcar_img4, bogcar_img5],

    iframeSrc:
      "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d1360.681714467699!2d28.845740577692002!3d46.993836547735356!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x40c97f88764d5029%3A0x11ff185429ab9487!2sAutoServise!5e0!3m2!1sen!2s!4v1722087989646!5m2!1sen!2s",
    icons: [
      {
        url: ico1,
        available: true,
      },
      {
        url: ico2,
        available: true,
      },
      {
        url: ico3,
        available: true,
      },
      {
        url: ico4,
        available: false,
      },
      {
        url: ico5,
        available: false,
      },
      {
        url: ico6,
        available: true,
      },
    ],
    serviceTypes: [
      {
        title: "Chassis",
        icon: suspension_ico,
        services: {
          service1: "Chassis diagnostics/check",
          service2: "Ball joints, rods, bushings replacement",
          service3: "Silent blocks replacement",
          service4: "Wheel alignment",
          service5: "Brake discs and pads replacement",
          service6: "Replacement of brake discs and pads и колодок",
          service7: "Drive replacement",
          service8: "Joints/grenades replacement",
        },
      },
      {
        title: "Air conditioning",
        icon: ac_ico,
        services: {
          service1: "Air conditioning refill",
          service2: "Radiator replacement",
          service3: "Air conditioning tube repair",
          service4: "Промывка печки",
          service5: "Air conditioning hose repair",
        },
      },
      {
        title: "Filters",
        icon: car_filter_ico,
        services: {
          service1: "Fuel filter replacement",
          service2: "Air filter replacement",
          service3: "Cabin filter replacement",
          service4: "Oil filter replacement",
          service5: "Gearbox filter replacement",
        },
      },
      {
        title: "Oils and liquids",
        icon: oil_change_ico,
        services: {
          service1: "Engine oil change",
          service2: "Manual transmission oil change",
          service3: "Automatic transmission oil change",
          service4: "Power steering oil change",
          service5: "Tosol/antifreeze change",
          service6: "Brake fluid change",
        },
      },
      {
        title: "Disks/wheels",
        icon: wheel_ico,
        services: {
          service1: "Tire replacement (change of tires)",
          service2: "Wheel balancing",
          service3: "Wheel replacement",
        },
      },
      {
        title: "Shock absorbers/suspension",
        icon: absorber_ico,
        services: {
          service1: "Shock absorber replacement",
          service2: "Spring replacement",
        },
      },
    ],
  },
  {
    id: 6,
    url: "remont-motora-conditionera",
    name: "Repair of engine, air conditioner, chassis",
    image: simple_s_preview,
    bigImage: simple_s_big,
    description:
      "Our auto repair shop offers 3D wheel alignment, air conditioning refilling, chassis, gearbox and engine repair. We use advanced equipment and high-quality materials to ensure optimal operation of your car. Our specialists guarantee professional performance of the work, allowing you to enjoy comfort and confidence behind the wheel. Contact us for prompt and high-quality service.",
    phone: "+373 78 003 003",
    address: "Chisinau, sec. Ciocana, St. Uzinelor 141/1",
    regiune: ["Chisinau"],
    sector: ["Ciocana"],
    mapAddress: "https://maps.app.goo.gl/uQHx6AZRDh7UdDqJ7",
    views: 1462,
    orar: "Mon-Fri: 08:00 — 19:00, Sat: 08:00 — 15:00",
    gallery: [
      simple_s_img1,
      simple_s_img2,
      simple_s_img3,
      simple_s_img4,
      simple_s_img5,
    ],
    iframeSrc:
      "https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d680.2576661259757!2d28.89359395337314!3d47.000370853019646!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x40c97be5ba36e94b%3A0x8f3e46f76acf700a!2sAutoservice!5e0!3m2!1sen!2sus!4v1721561170671!5m2!1sen!2sus",
    icons: [
      {
        url: ico1,
        available: false,
      },
      {
        url: ico2,
        available: true,
      },
      {
        url: ico3,
        available: false,
      },
      {
        url: ico4,
        available: true,
      },
      {
        url: ico5,
        available: false,
      },
      {
        url: ico6,
        available: true,
      },
    ],
    serviceTypes: [
      {
        title: "Chassis",
        icon: suspension_ico,
        services: {
          service1: "Chassis diagnostics/check",
          service2: "Ball joints, rods, bushings replacement",
          service3: "Silent blocks replacement",
          service4: "Wheel alignment",
          service5: "Brake discs and pads replacement",
          service6: "Replacement of brake discs and pads и колодок",
          service7: "Drive replacement",
          service8: "Joints/grenades replacement",
        },
      },
      {
        title: "Gearbox/Transmission",
        icon: car_manual_gearbox_ico,
        services: {
          service1: "Gearbox repair (manual)",
          service2: "Manual gearbox diagnostics",
          service3: "Clutch replacement (manual)",
          service4: "Clutch adjustment (manual)",
          service5: "Gear handle repair (shift linkage)",
        },
      },
      {
        title: "Engine (Motor)",
        icon: engine_ico,
        services: {
          service1: "Engine diagnostics",
          service2: "Engine (motor) overhaul",
          service3: "Checking spark plugs",
          service4: "Ignition system diagnostics",
          service5: "Spark plug replacement",
          service6: "Engine replacement",
          service7: "Timing chain/belt replacement",
          service8: "Cooling system diagnostics and repair",
          service9: "Valve adjustment",
          service10: "Cylinder head gasket replacement",
          service11: "Crankcase gasket replacement",
        },
      },
      {
        title: "Oils and liquids",
        icon: oil_change_ico,
        services: {
          service1: "Engine oil change",
          service2: "Manual transmission oil change",
          service3: "Automatic transmission oil change",
          service4: "Power steering oil change",
          service5: "Tosol/antifreeze change",
          service6: "Brake fluid change",
        },
      },
      {
        title: "Brake system",
        icon: brakes_ico,
        services: {
          service1: "Brake fluid change",
          service2: "Brake pad replacement",
          service3: "Handbrake repair",
        },
      },
      {
        title: "Shock absorbers/suspension",
        icon: absorber_ico,
        services: {
          service1: "Shock absorber replacement",
          service2: "Spring replacement",
        },
      },
      {
        title: "Турбина/Турбокомрессор",
        icon: turbine_ico,
        services: {
          service1: "Замена турбокомпрессора",
          service2: "Проверка трубок системы наддува",
        },
      },
      {
        title: "Filters",
        icon: car_filter_ico,
        services: {
          service1: "Fuel filter replacement",
          service2: "Air filter replacement",
          service3: "Cabin filter replacement",
          service4: "Oil filter replacement",
          service5: "Gearbox filter replacement",
        },
      },
      {
        title: "Computer diagnostics",
        icon: PC_diagnostics_ico,
        services: {
          service1: "Full diagnostics of electronic vehicle systems",
          service2: "Error removal",
        },
      },
      {
        title: "Steering rack",
        icon: volan_ico,
        services: {
          service1: "Steering rack diagnostics",
          service2: "Steering rack replacement",
          service3: "Steering rack repair",
        },
      },
      {
        title: "Air conditioning",
        icon: ac_ico,
        services: {
          service1: "Air conditioning refill (with freon)",
        },
      },
    ],
  },
  {
    id: 7,
    url: "force-car-service",
    name: "Force Car Service — comprehensive car maintenance services",
    image: fcs_preview,
    bigImage: fcs_big,
    description:
      "Expert repair of car engines. Complete diagnostics and repair of engines of any complexity. Our experienced mechanics use modern technologies and high-quality spare parts. We guarantee excellent performance and durability of your engine.",
    phone: "+373 68 584 828",
    address: "Chisinau, sec. Riscani, St. Ismail 103",
    regiune: ["Chisinau"],
    sector: ["Riscani"],
    mapAddress: "https://maps.app.goo.gl/B2uZZKuz6H3m3brX6",
    views: 1462,
    orar: "Mon-Fri: 08:00 — 19:00, Sat: 08:00 — 15:00",
    gallery: [fcs_img1, fcs_img2, fcs_img3, fcs_img4, fcs_img5],
    iframeSrc:
      "https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d4574.106868060407!2d28.86533113289558!3d47.024918985334835!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x40c97df17df45481%3A0x1e0185095d8b8fe2!2sForce_car!5e0!3m2!1sen!2s!4v1721539511957!5m2!1sen!2s",
    icons: [
      {
        url: ico1,
        available: true,
      },
      {
        url: ico2,
        available: true,
      },
      {
        url: ico3,
        available: true,
      },
      {
        url: ico4,
        available: true,
      },
      {
        url: ico5,
        available: false,
      },
      {
        url: ico6,
        available: true,
      },
    ],
    serviceTypes: [
      {
        title: "Engine (Motor)",
        icon: engine_ico,
        services: {
          service1: "Engine diagnostics",
          service2: "Engine (motor) overhaul",
          service3: "Checking spark plugs",
          service4: "Ignition system diagnostics",
          service5: "Spark plug replacement",
          service6: "Engine replacement",
          service7: "Timing chain/belt replacement",
          service8: "Cooling system diagnostics and repair",
          service9: "Valve adjustment",
          service10: "Cylinder head gasket replacement",
          service11: "Crankcase gasket replacement",
        },
      },
      {
        title: "Chassis",
        icon: suspension_ico,
        services: {
          service1: "Chassis diagnostics/check",
          service2: "Ball joints, rods, bushings replacement",
          service3: "Silent blocks replacement",
          service4: "Wheel alignment",
          service5: "Brake discs and pads replacement",
          service6: "Replacement of brake discs and pads и колодок",
          service7: "Drive replacement",
          service8: "Joints/grenades replacement",
        },
      },
      {
        title: "Gearbox/Transmission",
        icon: car_manual_gearbox_ico,
        services: {
          service1: "Gearbox repair (manual)",
          service2: "Manual gearbox diagnostics",
          service3: "Clutch replacement (manual)",
          service4: "Clutch adjustment (manual)",
        },
      },
      {
        title: "Diagnostics",
        icon: diagnostic_ico,
        services: {
          service1: "Engine diagnostics",
          service2: "Chassis diagnostics",
          service3: "Brake system diagnostics",
        },
      },
      {
        title: "Steering rack",
        icon: volan_ico,
        services: {
          service1: "Steering rack diagnostics",
          service2: "Steering rack replacement",
          service3: "Steering rack repair",
        },
      },
      {
        title: "Filters",
        icon: car_filter_ico,
        services: {
          service1: "Fuel filter replacement",
          service2: "Air filter replacement",
          service3: "Cabin filter replacement",
          service4: "Oil filter replacement",
          service5: "Gearbox filter replacement",
        },
      },
      {
        title: "Computer diagnostics",
        icon: PC_diagnostics_ico,
        services: {
          service1: "Full diagnostics of electronic vehicle systems",
          service2: "Error removal",
        },
      },
      {
        title: "Shock absorbers/suspension",
        icon: absorber_ico,
        services: {
          service1: "Shock absorber replacement",
          service2: "Spring replacement",
        },
      },
      {
        title: "Oils and liquids",
        icon: oil_change_ico,
        services: {
          service1: "Engine oil change",
          service2: "Manual transmission oil change",
          service3: "Automatic transmission oil change",
          service4: "Power steering oil change",
          service5: "Tosol/antifreeze change",
          service6: "Brake fluid change",
        },
      },
      {
        title: "Detailing/Polishing",
        icon: detailing_ico,
        services: {
          service1: "Body polishing",
          service2: "Disc cleaning and polishing",
          service3: "Headlight polishing",
          service4: "Power steering oil change",
          service5: "Tosol/antifreeze change",
          service6: "Brake fluid change",
        },
      },
      {
        title: "Painting",
        icon: paint_ico,
        services: {
          service1: "Body painting",
          service2: "Wheel rim painting",
        },
      },
      {
        title: "Disks/wheels",
        icon: wheel_ico,
        services: {
          service1: "Disk repair (welding)",
          service2: "Disk repair (welding)",
          service3: "Headlight polishing",
        },
      },
    ],
  },
  {
    id: 8,
    url: "auto-fast",
    name: "Vulcanization AutoFast",
    image: autof_preview,
    bigImage: autof_big,
    description:
      "We offer professional tire vulcanization and repair services. We restore tires using high-quality materials. Our specialists will do everything quickly, efficiently and carefully.",
    phone: "+373 69 256 301",
    address: "Chisinau, sec. Botanica, St. Grenoble 134/2",
    regiune: ["Chisinau"],
    sector: ["Botanica"],
    mapAddress: "https://maps.app.goo.gl/WtVvkCgiSrCBGDtM8",
    views: 1462,
    orar: "Mon-Sun: 08:00 — 22:00",
    gallery: [autof_img1, autof_img2, autof_img3, autof_img4, autof_img5],
    iframeSrc:
      "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d680.6106075041856!2d28.84922053172323!3d46.97264403616815!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x40c97932187254e7%3A0xc2fbe1509b894ea1!2sVulcanizare%20AutoFast!5e0!3m2!1sen!2s!4v1722011278559!5m2!1sen!2s",
    icons: [
      {
        url: ico1,
        available: false,
      },
      {
        url: ico2,
        available: true,
      },
      {
        url: ico3,
        available: true,
      },
      {
        url: ico4,
        available: false,
      },
      {
        url: ico5,
        available: false,
      },
      {
        url: ico6,
        available: true,
      },
    ],
    serviceTypes: [
      {
        title: "Disks/wheels",
        icon: wheel_ico,
        services: {
          service1: "Wheel balancing",
          service2: "Tire replacement (change of tires)",
          service3: "Tire repair",
        },
      },
    ],
  },
  {
    id: 9,
    url: "complex",
    name: "Complex — engine and chassis repair",
    image: complex_preview,
    bigImage: complex_big,
    description:
      "We offer full engine diagnostics and repairs, as well as replacement of worn chassis parts, and other service work for your car. Our specialists use only high-quality spare parts and modern repair methods. We guarantee the reliability and long-term nature of the work performed.",
    phone: "+373 68 946 666",
    address: "Chisinau, sec. Riscani, St. Petricani 21",
    regiune: ["Chisinau"],
    sector: ["Riscani"],
    mapAddress: "https://maps.app.goo.gl/6b9gQTj1HoagyyMs7",
    views: 1462,
    orar: "Mon-Fri: 09:00 — 18:00, Sat: 09:00 — 14:00",
    gallery: [
      complex_img1,
      complex_img2,
      complex_img3,
      complex_img4,
      complex_img5,
    ],

    iframeSrc:
      "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d961.1039627085811!2d28.817781729580087!3d47.05175769035963!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x40c97d9f1fcf3851%3A0x63e353bbd8071d7d!2zU3RyYWRhIFBldHJpY2FuaSAyMSwgTUQtMjA2OCwgQ2hpyJlpbsSDdSwg0JzQvtC70LTQvtCy0LA!5e0!3m2!1sru!2s!4v1725964755502!5m2!1sru!2s",
    icons: [
      {
        url: ico1,
        available: true,
      },
      {
        url: ico2,
        available: true,
      },
      {
        url: ico3,
        available: true,
      },
      {
        url: ico4,
        available: true,
      },
      {
        url: ico5,
        available: false,
      },
      {
        url: ico6,
        available: true,
      },
    ],
    serviceTypes: [
      {
        title: "Chassis",
        icon: suspension_ico,
        services: {
          service1: "Chassis diagnostics/check",
          service2: "Ball joints, rods, bushings replacement",
          service3: "Silent blocks replacement",
          service4: "Wheel alignment",
          service5: "Brake discs and pads replacement",
          service6: "Replacement of brake discs and pads и колодок",
          service7: "Drive replacement",
          service8: "Joints/grenades replacement",
        },
      },
      {
        title: "Gearbox/Transmission",
        icon: car_manual_gearbox_ico,
        services: {
          service1: "Gearbox repair (manual)",
          service2: "Manual gearbox diagnostics",
          service3: "Clutch replacement (manual)",
          service4: "Clutch adjustment (manual)",
        },
      },
      {
        title: "Diagnostics",
        icon: diagnostic_ico,
        services: {
          service1: "Engine diagnostics",
          service2: "Chassis diagnostics",
          service3: "Brake system diagnostics",
        },
      },
      {
        title: "Steering rack",
        icon: volan_ico,
        services: {
          service1: "Steering rack diagnostics",
          service2: "Steering rack replacement",
          service3: "Steering rack repair",
        },
      },
      {
        title: "Shock absorbers/suspension",
        icon: absorber_ico,
        services: {
          service1: "Shock absorber replacement",
          service2: "Spring replacement",
        },
      },
      {
        title: "Disks/wheels",
        icon: wheel_ico,
        services: {
          service1: "Tire replacement (change of tires)",
          service2: "Wheel balancing",
        },
      },
      {
        title: "Engine (Motor)",
        icon: engine_ico,
        services: {
          service1: "Engine diagnostics",
          service2: "Engine (motor) overhaul",
          service3: "Checking spark plugs",
          service4: "Ignition system diagnostics",
          service5: "Spark plug replacement",
          service6: "Engine replacement",
          service7: "Timing chain/belt replacement",
          service8: "Cooling system diagnostics and repair",
          service9: "Valve adjustment",
          service10: "Cylinder head gasket replacement",
          service11: "Crankcase gasket replacement",
        },
      },
      {
        title: "Air conditioning",
        icon: ac_ico,
        services: {
          service1: "Air conditioning refill (with freon)",
          service2: "Radiator replacement",
          service3: "Interior fan replacement/repair",
          service4: "Flushing the heater radiator",
          service5: "Repair/replacement of the damper servo drive",
          service6:
            "Evaporator replacement, repair, expansion valve expansion valve of the air conditioning system",
          service7: "Repair, replacement of dampers, heater box, drainage hole",
          service8: "Air conditioning motor repair",
        },
      },
      {
        title: "Computer diagnostics",
        icon: PC_diagnostics_ico,
        services: {
          service1: "Full diagnostics of electronic vehicle systems",
          service2: "Error removal",
        },
      },
      {
        title: "Filters",
        icon: car_filter_ico,
        services: {
          service1: "Fuel filter replacement",
          service2: "Air filter replacement",
          service3: "Cabin filter replacement",
          service4: "Oil filter replacement",
          service5: "Gearbox filter replacement",
        },
      },
      {
        title: "Oils and liquids",
        icon: oil_change_ico,
        services: {
          service1: "Engine oil change",
          service2: "Manual transmission oil change",
          service3: "Automatic transmission oil change",
          service4: "Power steering oil change",
          service5: "Tosol/antifreeze change",
          service6: "Brake fluid change",
        },
      },
    ],
  },
  {
    id: 10,
    url: "SST-Chiptuning",
    name: "SST-Chiptuning — chip tuning and EGR removal",
    image: sst_preview,
    bigImage: sst_big,
    description:
      "SST-CHIPTUNING is a specialized tuning workshop in Moldova for retuning and customizing cars. We focus on chip tuning and car modification. We also offer solutions for disabling various engine systems and solving ecology-related problems such as DPF (diesel particulate filter), EGR, AdBlue and similar problems. In our studio, we offer a file service for professionals and service centers, where we modify files according to your needs. We specialize in professional solutions to achieve the best results. Our services are designed for demanding customers who value their cars and strive for optimal performance. All our work is performed on original and expensive equipment, guaranteeing safety and stability.",
    phone: "+373 60 705 050",
    address: "Chisinau, sec. Riscani, St. Doina 189",
    regiune: ["Chisinau"],
    sector: ["Riscani"],
    mapAddress: "https://maps.app.goo.gl/g5xXfgTwgKq6JV5J7",
    views: 1462,
    orar: "Mon-Fri: 08:00 — 18:00, Sat: 08:00 — 15:00",
    gallery: [sst_img1, sst_img2, sst_img3, sst_img4, sst_img5],
    iframeSrc:
      "https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d3843.7735174838863!2d28.83485292678854!3d47.06066795455975!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x40c97db8df0443df%3A0x9f247feeec426036!2sSST-CHIPTUNING%20%26%20Autoservice!5e0!3m2!1sru!2sus!4v1722012759000!5m2!1sru!2sus",
    icons: [
      {
        url: ico1,
        available: true,
      },
      {
        url: ico2,
        available: true,
      },
      {
        url: ico3,
        available: true,
      },
      {
        url: ico4,
        available: true,
      },
      {
        url: ico5,
        available: false,
      },
      {
        url: ico6,
        available: true,
      },
    ],
    serviceTypes: [
      {
        title: "EGR and particulate filter",
        icon: catalyst_ico,
        services: {
          service1: "EGR valve diagnostics",
          service2: "EGR valve computer disabling",
          service3: "Particulate filter removal",
        },
      },
      {
        title: "Chip tuning",
        icon: chip_tuning_ico,
        services: {
          service1: "Chip tuning",
          service2: "Chip tuning stage 1",
          service3: "Chip tuning stage 2",
        },
      },
    ],
  },
  {
    id: 11,
    url: "starter-plus",
    name: "Starter plus — Repair of starters and generators",
    image: starterp_preview,
    bigImage: starterp_big,
    description:
      "Our company is one of the first companies in Moldova specializing in repair of starters and generators of cars and trucks. Thanks to many years of experience, we can confidently guarantee you the quality and professionalism of the services we provide! Trust your car only to professionals!",
    phone: "+373 79 307 708",
    address: "Chisinau, sec. Botanica, St. Uzinelor 8/1",
    regiune: ["Chisinau"],
    sector: ["Botanica"],
    mapAddress: "https://maps.app.goo.gl/N21G9Jky8gLG1beg6",
    views: 1462,
    orar: "Mon-Fri: 08:00 — 18:00, Sat: 08:00 — 15:00",
    gallery: [
      starterp_img1,
      starterp_img2,
      starterp_img3,
      starterp_img4,
      starterp_img5,
    ],
    iframeSrc:
      "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d1360.2263084389472!2d28.87217893894353!3d47.01171999278539!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x40c97c0930d302c9%3A0x7aa475426a8ec60c!2sStarter%20Plus%20SRL!5e0!3m2!1sen!2s!4v1722015185460!5m2!1sen!2s",
    icons: [
      {
        url: ico1,
        available: true,
      },
      {
        url: ico2,
        available: true,
      },
      {
        url: ico3,
        available: true,
      },
      {
        url: ico4,
        available: true,
      },
      {
        url: ico5,
        available: false,
      },
      {
        url: ico6,
        available: true,
      },
    ],
    serviceTypes: [
      {
        title: "Auto electrics",
        icon: energy_ico,
        services: {
          service1: "Wiring diagnostics",
          service2: "Wiring repair",
          service3: "Replacing fuses and relays",
          service4: "Checking and replacing the battery",
          service5: "Generator diagnostics",
          service6: "Generator repair",
          service7: "Generator replacement",
          service8: "Starter diagnostics",
          service9: "Starter repair",
          service10: "Starter replacement",
          service11:
            "Installation and configuration of additional equipment (camera, android auto)",
        },
      },
      {
        title: "Computer diagnostics",
        icon: PC_diagnostics_ico,
        services: {
          service1: "Diagnostics of electronic systems of the car",
          service2: "Error removal",
        },
      },
    ],
  },
  {
    id: 12,
    url: "mireco-service",
    name: "Mireco Service — engine, chassis, electrics",
    image: mireco_preview,
    bigImage: mireco_big,
    description:
      "Mireco offers a wide range of services for maintenance and repair of cars of all famous brands.",
    phone: "+373 61 183 183",
    address: "Chisinau, sec. Centru, St. Columna 170",
    regiune: ["Chisinau"],
    sector: ["Centru"],
    mapAddress: "https://maps.app.goo.gl/zxVvfncStsnxuwEQ9",
    views: 1462,
    orar: "Mon-Sat 08:00 — 18:00",
    gallery: [mireco_img1, mireco_img2, mireco_img3, mireco_img4, mireco_img5],
    iframeSrc:
      "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d1359.5584315322353!2d28.8199121!3d47.0379376!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x40c97d001193326b%3A0xbecaafe7577c297c!2sMireco%20Auto-Service%2FVulcanizare!5e0!3m2!1sen!2s!4v1722053883140!5m2!1sen!2s",
    icons: [
      {
        url: ico1,
        available: true,
      },
      {
        url: ico2,
        available: true,
      },
      {
        url: ico3,
        available: true,
      },
      {
        url: ico4,
        available: true,
      },
      {
        url: ico5,
        available: true,
      },
      {
        url: ico6,
        available: true,
      },
    ],
    serviceTypes: [
      {
        title: "Engine (Motor)",
        icon: engine_ico,
        services: {
          service1: "Engine diagnostics",
          service2: "Engine (motor) overhaul",
          service3: "Checking spark plugs",
          service4: "Ignition system diagnostics",
          service5: "Spark plug replacement",
          service6: "Engine replacement",
          service7: "Timing chain/belt replacement",
          service8: "Cooling system diagnostics and repair",
          service9: "Valve adjustment",
          service10: "Cylinder head gasket replacement",
          service11: "Crankcase gasket replacement",
          service11: "Decoking and engine flushing",
        },
      },
      {
        title: "Auto electrics",
        icon: energy_ico,
        services: {
          service1: "Wiring diagnostics",
          service2: "Wiring repair",
          service3: "Replacing fuses and relays",
          service4: "Checking and replacing the battery",
          service5: "Generator diagnostics",
          service6:
            "Installation and configuration of additional equipment (camera, android auto)",
          service7: "Generator replacement",
          service8: "Starter diagnostics",
          service9: "Starter replacement",
        },
      },
      {
        title: "Chassis",
        icon: suspension_ico,
        services: {
          service1: "Chassis diagnostics/check",
          service2: "Ball joints, rods, bushings replacement",
          service3: "Silent blocks replacement",
          service4: "Wheel alignment",
          service5: "Brake discs and pads replacement",
          service6: "Replacement of brake discs and pads и колодок",
          service7: "Drive replacement",
          service8: "Joints/grenades replacement",
        },
      },
      {
        title: "Gearbox/Transmission",
        icon: car_manual_gearbox_ico,
        services: {
          service1: "Gearbox repair (manual)",
          service2: "Manual gearbox diagnostics",
        },
      },
      {
        title: "Diagnostics",
        icon: diagnostic_ico,
        services: {
          service1: "Engine diagnostics",
          service2: "Chassis diagnostics",
          service3: "Brake system diagnostics",
        },
      },
      {
        title: "Steering rack",
        icon: volan_ico,
        services: {
          service1: "Steering rack diagnostics",
          service2: "Steering rack replacement",
          service3: "Steering rack repair",
        },
      },
      {
        title: "Filters",
        icon: car_filter_ico,
        services: {
          service1: "Fuel filter replacement",
          service2: "Air filter replacement",
          service3: "Cabin filter replacement",
          service4: "Oil filter replacement",
        },
      },
      {
        title: "Computer diagnostics",
        icon: PC_diagnostics_ico,
        services: {
          service1: "Diagnostics of electronic systems of the car",
          service2: "Error removal",
        },
      },
      {
        title: "Shock absorbers/suspension",
        icon: absorber_ico,
        services: {
          service5: "Shock absorber replacement",
        },
      },
    ],
  },
  {
    id: 13,
    url: "eco-tuning",
    name: "Eco Tuning — Disabling the particulate filter, Adblue",
    image: ecot_preview,
    bigImage: ecot_big,
    description:
      "We provide services for disabling and removing the particulate filter, EGR, Adblue, and also perform high-quality chip tuning of your car, increasing its power by 20-30%.",
    phone: "+373 78 092 869",
    address: "Chisinau, Airport, St. Dacia 65",
    regiune: ["Chisinau"],
    sector: ["Airport"],
    mapAddress: "https://maps.app.goo.gl/gHQCBaMi8uFMxhjM8",
    views: 1462,
    orar: "Mon-Fri: 09:00 — 18:00",
    gallery: [ecot_img1, ecot_img2, ecot_img3, ecot_img4, ecot_img5],
    iframeSrc:
      "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d680.9467846790748!2d28.922636686010943!3d46.94622255198563!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x40c9790bbacb48eb%3A0x5c1e41e4321b16ff!2sDacia%20Blvd%2065%2C%20MD-2026%2C%20Chi%C8%99in%C4%83u%2C%20Moldova!5e0!3m2!1sen!2s!4v1722057163728!5m2!1sen!2s",
    icons: [
      {
        url: ico1,
        available: true,
      },
      {
        url: ico2,
        available: true,
      },
      {
        url: ico3,
        available: true,
      },
      {
        url: ico4,
        available: true,
      },
      {
        url: ico5,
        available: false,
      },
      {
        url: ico6,
        available: true,
      },
    ],
    serviceTypes: [
      {
        title: "EGR and particulate filter",
        icon: catalyst_ico,
        services: {
          service1: "EGR valve diagnostics",
          service2: "EGR valve repair",
          service3: "EGR valve disabling",
          service4: "EGR valve computer disabling",
          service5: "Particulate filter removal (cutting)",
          service6: "Disabling Adblue",
        },
      },
      {
        title: "Computer diagnostics",
        icon: PC_diagnostics_ico,
        services: {
          service1: "Diagnostics of electronic systems of the car",
          service2: "Error removal",
        },
      },
    ],
  },
  {
    id: 14,
    url: "tonirovka",
    name: "Tonirovka.md - Glass tinting, interior dry cleaning",
    image: tonirovka_preview,
    bigImage: tonirovka_big,
    description:
      "Comprehensive services for detailing and car care: - Interior dry cleaning, headlight wrapping, ceiling re-upholstery, air conditioning refilling, dry fog, headlight polishing.",
    phone: "+373 60 196 199",
    address: "Chisinau, sec. Sculeni, St. Милано 19/1 (Box 260)",
    regiune: ["Chisinau"],
    sector: ["Sculeni"],
    mapAddress: "https://maps.app.goo.gl/KeWhrpP87y8sisVn6",
    views: 1462,
    orar: "Mon-Fri: 09:00 — 17:00, Sat: 09:00 — 14:00",
    gallery: [
      tonirovka_img1,
      tonirovka_img2,
      tonirovka_img3,
      tonirovka_img4,
      tonirovka_img5,
    ],
    iframeSrc:
      "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d961.3211161740801!2d28.790349193307353!3d47.039706475881545!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x40c97df2bd0fc545%3A0x8a40e96f98cfdd60!2s1%2C%20Strada%20Milano%2019%2C%20MD-2069%2C%20Chi%C8%99in%C4%83u%2C%20Moldova!5e0!3m2!1sen!2s!4v1725686769086!5m2!1sen!2s",
    icons: [
      {
        url: ico1,
        available: true,
      },
      {
        url: ico2,
        available: false,
      },
      {
        url: ico3,
        available: true,
      },
      {
        url: ico4,
        available: true,
      },
      {
        url: ico5,
        available: false,
      },
      {
        url: ico6,
        available: false,
      },
    ],
    serviceTypes: [
      {
        title: "Detailing/Polishing",
        icon: detailing_ico,
        services: {
          service1: "Body washing and degreasing",
          service2: "Protective coating of headlights",
          service3: "Optics (headlights) polishing",
          service4: "Interior dry cleaning",
          service5: "Interior ozonation",
          service6: "Tinting",
        },
      },
      {
        title: "Interior restoration/Upholstery",
        icon: salon_ico,
        services: {
          service1: "Ceiling restoration (ceiling covering)",
        },
      },
      {
        title: "Air conditioning",
        icon: ac_ico,
        services: {
          service1: "Air conditioning refill (with freon)",
        },
      },
    ],
  },
  {
    id: 15,
    url: "bamper-md",
    name: "Bamper.md — Repair of bumpers and plastic parts",
    image: bampermd_preview,
    bigImage: bampermd_big,
    description:
      "We perform all types of plastic welding, repair and restoration of any plastic auto parts, as well as repair of fiberglass parts. Repair and welding of plastic materials is carried out using appropriate materials and special equipment with a guarantee. We estimate repairs based on photos in Viber/WhatsApp, free consultation, experienced craftsmen. All types of plastic welding, we restore any plastic parts!",
    phone: "+373 78 784 808",
    address: "Chisinau, sec. Riscani, St. Petru Rares 62",
    regiune: ["Chisinau"],
    sector: ["Riscani"],
    mapAddress: "https://maps.app.goo.gl/AQKvroV3fAbZWNVe7",
    views: 1462,
    orar: "Mon-Sun: 08:00 — 20:00",
    gallery: [
      bampermd_img1,
      bampermd_img2,
      bampermd_img3,
      bampermd_img4,
      bampermd_img5,
    ],
    iframeSrc:
      "https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d285.81142520127275!2d28.841806572839012!3d47.03803610693516!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x40c97c52ad58e8d7%3A0xa8ef534de5fd6f7b!2sStrada%20Petru%20Rare%C8%99%2062%2C%20MD-2005%2C%20Chi%C8%99in%C4%83u%2C%20Moldova!5e0!3m2!1sen!2s!4v1722543813775!5m2!1sen!2s",
    icons: [
      {
        url: ico1,
        available: true,
      },
      {
        url: ico2,
        available: true,
      },
      {
        url: ico3,
        available: true,
      },
      {
        url: ico4,
        available: false,
      },
      {
        url: ico5,
        available: false,
      },
      {
        url: ico6,
        available: true,
      },
    ],
    serviceTypes: [
      {
        title: "Plastic",
        icon: bumper_ico,
        services: {
          service1: "Bumper repair",
          service2: "Plastic welding",
          service3: "Wing repair",
          service4: "Dent repair",
          service5: "Plastic fastener repair",
          service6: "Plastic mirror repair",
          service7: "Expansion tank welding",
          service8: "Grille repair",
          service9: "Fiberglass parts repair",
        },
      },
      {
        title: "Headlights/Optics",
        icon: far_ico,
        services: {
          service1: "Replacing bulbs",
          service2: "Headlight polishing",
          service3: "Headlight repair",
          service4: "Headlight replacement",
        },
      },
      {
        title: "Painting",
        icon: paint_ico,
        services: {
          service1: "Bumper painting",
        },
      },
    ],
  },
  {
    id: 16,
    url: "old-style",
    name: "Old Style — Polishing headlights and bodywork",
    image: olds_preview,
    bigImage: olds_big,
    description:
      "If your visibility has deteriorated when driving at night or your car no longer looks as aesthetically pleasing as before, we are waiting for you for the headlight renewal procedure.",
    phone: "+373 69 880 770",
    address: "Chisinau, Durlesti, St. Chimistilor 17",
    regiune: ["Chisinau"],
    sector: ["Durlesti"],
    mapAddress: "https://maps.app.goo.gl/9NjYKCx7BotUj6dVA",
    views: 1462,
    orar: "Mon-Fri: 10:00 — 19:00",
    gallery: [olds_img1, olds_img2, olds_img3, olds_img4, olds_img5],
    iframeSrc:
      "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d1359.8624754801483!2d28.773708388360575!3d47.0260036955912!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x40c97dfd4027c90d%3A0x27145a3ce05e6bd7!2sStrada%20Chimi%C8%99tilor%2017%2C%20Durle%C5%9Fti!5e0!3m2!1sen!2s!4v1722578261428!5m2!1sen!2s",
    icons: [
      {
        url: ico1,
        available: true,
      },
      {
        url: ico2,
        available: true,
      },
      {
        url: ico3,
        available: true,
      },
      {
        url: ico4,
        available: false,
      },
      {
        url: ico5,
        available: false,
      },
      {
        url: ico6,
        available: false,
      },
    ],
    serviceTypes: [
      {
        title: "Detailing/Polishing",
        icon: detailing_ico,
        services: {
          service1: "Body polishing",
          service2: "Body covering with protective film",
          service3: "Body covering with vinyl film",
          service4: "Headlight covering with protective film",
          service5: "Body coating with nanoceramics",
          service6: "Body coating with wax",
          service7: "Optics (headlights) polishing",
          service8: "Interior dry cleaning",
          service9: "Interior ozonation",
        },
      },
    ],
  },
  {
    id: 17,
    url: "detailing-lux-18",
    name: "Detailing Lux 18 — Painting, polishing of cars",
    image: detl_preview,
    bigImage: detl_big,
    description:
      "The defining aspect of the painting success is the preliminary painting stage, on which the painted surface is prepared. The quality and type of paint used are the main condition for obtaining paint that is stable over time and that withstands the entire application process. For more detailed information and registration, do not hesitate to contact us. We warmly welcome you. Polishing is performed using a professional tool and using special abrasive materials and polishing pastes. We are waiting for you without exception, we have delicious coffee and you are in a good mood!",
    phone: "+373 78 856 836",
    address: "Chisinau, sec. Botanica, St. Uzinelor 245/2",
    regiune: ["Chisinau"],
    sector: ["Botanica"],
    mapAddress: "https://maps.app.goo.gl/VAcR4LBfR6hCLKen7",
    views: 1462,
    orar: "Mon-Fri: 10:00 — 19:00",
    gallery: [detl_img1, detl_img2, detl_img3, detl_img4, detl_img5],
    iframeSrc:
      "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d1360.6960220776307!2d28.911720915151584!3d46.99327461453499!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x40c97becc21edbcb%3A0xb0459eebba3054c5!2sDetailing%20Service%20Studio!5e0!3m2!1sru!2s!4v1722606071208!5m2!1sru!2s",
    icons: [
      {
        url: ico1,
        available: true,
      },
      {
        url: ico2,
        available: true,
      },
      {
        url: ico3,
        available: true,
      },
      {
        url: ico4,
        available: false,
      },
      {
        url: ico5,
        available: false,
      },
      {
        url: ico6,
        available: false,
      },
    ],
    serviceTypes: [
      {
        title: "Detailing/Polishing",
        icon: detailing_ico,
        services: {
          service1: "Body polishing",
          service2: "Body covering with protective film",
          service3: "Body covering with vinyl film",
          service4: "Headlight covering with protective film",
          service5: "Body coating with nanoceramics",
          service6: "Body coating with wax",
          service7: "Optics (headlights) polishing",
          service8: "Interior dry cleaning",
          service9: "Interior ozonation",
        },
      },
      {
        title: "Disks/wheels",
        icon: wheel_ico,
        services: {
          service1: "Disk alignment (straightening)",
          service2: "Wheel replacement",
          service3: "Disk alignment (straightening)",
        },
      },
      {
        title: "Interior restoration/Upholstery",
        icon: salon_ico,
        services: {
          service1: "Steering wheel restoration (steering wheel covering)",
          service2: "Interior restoration (interior covering)",
          service3: "Ceiling restoration (ceiling covering)",
        },
      },
      {
        title: "Painting",
        icon: paint_ico,
        services: {
          service1: "Body painting",
          service2: "Bumper painting",
          service3: "Wheel rim painting",
        },
      },
    ],
  },
  {
    id: 18,
    url: "detail-garage",
    name: "Detail Garage — Auto and disc paint",
    image: detg_preview,
    bigImage: detg_big,
    description:
      "Our detail service provides you with such a service as cleaning the interior of the car, polishing the car, removing dents without painting, etc. The noise isolation of the car is the best way to make the car more premium and get maximum driving comfort.",
    phone: "+373 78 887 788",
    address: "Chisinau, sec. Botanica, St. Uzinelor 210/2",
    regiune: ["Chisinau"],
    sector: ["Botanica"],
    mapAddress: "https://maps.app.goo.gl/pd7sfXrEmS9nYAFd7",
    views: 1462,
    orar: "Mon-Fri: 09:00 — 18:00",
    gallery: [detg_img1, detg_img2, detg_img3, detg_img4, detg_img5],
    iframeSrc:
      "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d340.1903937835918!2d28.912810443835223!3d46.9906999441576!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x40c97b2e0cef32f5%3A0x7a02888ecf642536!2sgaraj!5e0!3m2!1sen!2s!4v1722575003113!5m2!1sen!2s",
    icons: [
      {
        url: ico1,
        available: true,
      },
      {
        url: ico2,
        available: true,
      },
      {
        url: ico3,
        available: true,
      },
      {
        url: ico4,
        available: false,
      },
      {
        url: ico5,
        available: false,
      },
      {
        url: ico6,
        available: false,
      },
    ],
    serviceTypes: [
      {
        title: "Detailing/Polishing",
        icon: detailing_ico,
        services: {
          service1: "Body polishing",
          service2: "Body covering with protective film",
          service3: "Body covering with vinyl film",
          service4: "Headlight covering with protective film",
          service5: "Body coating with nanoceramics",
          service6: "Disc cleaning and polishing",
          service7: "Optics (headlights) polishing",
          service8: "Interior dry cleaning",
          service9: "Engine cleaning (washing)",
          service10: "Tinting",
          service11: "Dent removal without painting",
        },
      },
      {
        title: "Disks/wheels",
        icon: wheel_ico,
        services: {
          service1: "Disk repair (welding)",
          service2: "Wheel replacement",
          service3: "Disk alignment (straightening)",
        },
      },
      {
        title: "Interior restoration/Upholstery",
        icon: salon_ico,
        services: {
          service1: "Steering wheel restoration (steering wheel covering)",
          service2: "Interior restoration (interior covering)",
          service3: "Ceiling restoration (ceiling covering)",
        },
      },
      {
        title: "Soundproofing",
        icon: noiseoff_ico,
        services: {
          service1: "Body soundproofing",
        },
      },
    ],
  },
  {
    id: 19,
    url: "bizon",
    name: "Bizon — Polishing of headlights and windshields",
    image: bizon_preview,
    bigImage: bizon_big,
    description:
      "We are one of the few on the Moldovan market that offers truly professional car window polishing. Even despite 10 years of experience in this field, we are constantly improving. We participate in the professional development seminar. We may not have the lowest prices, but we are responsible for quality, and that means for your safety!",
    phone: "+373 60 372 742",
    address: "Chisinau, sec. Botanica, St. Muncesti 81",
    regiune: ["Chisinau"],
    sector: ["Botanica"],
    mapAddress: "https://maps.app.goo.gl/inf5NuyMUzJbUFc89",
    views: 1462,
    orar: "Mon-Sat 09:00 — 18:00",
    gallery: [bizon_img1, bizon_img2, bizon_img3, bizon_img4, bizon_img5],
    iframeSrc:
      "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d572.0051828831514!2d28.87002268684609!3d47.002336938052295!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x40c97c00c723fcd9%3A0xba32e067d2760164!2zyJhvc2VhdWEgTXVuY2XImXRpIDgxLCBDaGnImWluxIN1LCBNb2xkb3Zh!5e0!3m2!1sen!2s!4v1722575653086!5m2!1sen!2s",
    icons: [
      {
        url: ico1,
        available: true,
      },
      {
        url: ico2,
        available: true,
      },
      {
        url: ico3,
        available: true,
      },
      {
        url: ico4,
        available: false,
      },
      {
        url: ico5,
        available: false,
      },
      {
        url: ico6,
        available: true,
      },
    ],
    serviceTypes: [
      {
        title: "Detailing/Polishing",
        icon: detailing_ico,
        services: {
          service1: "Optics (headlights) polishing",
          service2: "Car glass (windshield) polishing",
        },
      },
    ],
  },
  {
    id: 20,
    url: "reikamd",
    name: "Reika.md — Repair of steering racks of all brands of cars",
    image: reikamd_preview,
    bigImage: reikamd_big,
    description:
      "We perform restorative repairs of steering racks of all makes of cars. Overhaul of the steering rack is a more profitable alternative to buying a new one, but not inferior to it in quality, during the overhaul it is also possible to replace some elements with reinforced ones, more suitable for our operating conditions. We perform repairs of steering racks of all makes of cars at your request we can check the power steering pump, power steering hoses, remove, replace or install the steering rack on your car, exchange a faulty steering rack for a restored one or sell it to you. Steering rack repairs are performed on specialized equipment. Only original components from the following manufacturers are used: ZF; TRW; Koyo; Smi; NSK; Mando etc. If your steering rack is out of order, it is not necessary to buy a new one (sometimes the price of a new unit exceeds the wildest expectations). Your steering rack can be restored and it will serve you for many years to come!",
    phone: "+373 78 335 335",
    address: "Chisinau, sec. Riscani, St. Bucovinei 9",
    regiune: ["Chisinau"],
    sector: ["Riscani"],
    mapAddress: "https://maps.app.goo.gl/1kgWsfG7FBQmwZAQ7",
    views: 1462,
    orar: "Mon-Fri: 09:00 — 17:00, Sat: 09:00 — 13:00",
    gallery: [
      reikamd_img1,
      reikamd_img2,
      reikamd_img3,
      reikamd_img4,
      reikamd_img5,
    ],
    iframeSrc:
      "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d1358.7736198912671!2d28.870053585665588!3d47.068730126391266!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x40c97da41ce45305%3A0x5ed4a5d2ed0419b5!2sREIKA%20AUTOSERVICE!5e0!3m2!1sru!2s!4v1722946885324!5m2!1sru!2s",
    icons: [
      {
        url: ico1,
        available: true,
      },
      {
        url: ico2,
        available: true,
      },
      {
        url: ico3,
        available: true,
      },
      {
        url: ico4,
        available: false,
      },
      {
        url: ico5,
        available: false,
      },
      {
        url: ico6,
        available: false,
      },
    ],
    serviceTypes: [
      {
        title: "Steering rack",
        icon: volan_ico,
        services: {
          service1: "Steering rack diagnostics",
          service2: "Steering rack replacement",
          service3: "Steering rack repair",
        },
      },
      {
        title: "Chassis",
        icon: suspension_ico,
        services: {
          service1: "Chassis diagnostics/check",
          service2: "Wheel alignment",
        },
      },
    ],
  },
  {
    id: 21,
    url: "esapament-service",
    name: "Esapament Service — Muffler Repair",
    image: esapserv_preview,
    bigImage: esapserv_big,
    description:
      "Esapament Service, with over 20 years of experience and being the first specialized workshop in Moldova, will help you solve any problem related to exhaust systems, catalytic converters, particulate filters and much more. Throughout its activity, Esapament Service has pursued several goals and principles: to maintain the highest level of quality, not to leave any customer disappointed and not to have problems that they could not solve.",
    phone: "+373 67 440 371",
    address: "Chisinau, sec. Botanica, St. Fantanilor 21",
    regiune: ["Chisinau"],
    sector: ["Botanica"],
    mapAddress: "https://maps.app.goo.gl/LKCdrnSFeEkhgtLF8",
    views: 1462,
    orar: "Mon-Fri: 09:00 — 17.00",
    gallery: [
      esapserv_img1,
      esapserv_img2,
      esapserv_img3,
      esapserv_img4,
      esapserv_img5,
    ],
    iframeSrc:
      "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d680.1500069336213!2d28.856749851829246!3d47.00882599017623!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x40c97c1ba6c7560f%3A0x130f267a45d2085a!2zU3RyLiBGw6JudMOibmlsb3IgMjEsIENoaciZaW7Eg3UsIE1vbGRvdmE!5e0!3m2!1sen!2s!4v1723396608688!5m2!1sen!2s",
    icons: [
      {
        url: ico1,
        available: true,
      },
      {
        url: ico2,
        available: true,
      },
      {
        url: ico3,
        available: true,
      },
      {
        url: ico4,
        available: false,
      },
      {
        url: ico5,
        available: false,
      },
      {
        url: ico6,
        available: false,
      },
    ],
    serviceTypes: [
      {
        title: "Muffler",
        icon: ex_pipe_ico,
        services: {
          service1: "Muffler repair",
          service2: "Muffler welding",
          service3: "Muffler replacement",
          service4: "Muffler diagnostics",
          service5: "Muffler tuning (sports muffler)",
        },
      },
    ],
  },
  {
    id: 22,
    url: "turbo-profi",
    name: "Turbine repair — Turbo-profi",
    image: turbopro_preview,
    bigImage: turbopro_big,
    description:
      "Spare parts for cargo turbines and special equipment. We accept turbines from other cities, repair them and send them to the owner. We offer spare parts for all types of turbines. Repair of any type of turbines special equipment. Shortest terms. Low prices, quality guarantee.",
    phone: "+373 78 571 702",
    address: "Chisinau, sec. Riscani, St. Petricani 17/5",
    regiune: ["Chisinau"],
    sector: ["Riscani"],
    mapAddress: "https://maps.app.goo.gl/MMuXXM6aq78pmUuc9",
    views: 1462,
    orar: "Mon-Fri: 09:00 — 18:00, Sat: 09:00 — 14:00",
    gallery: [
      turbopro_img1,
      turbopro_img2,
      turbopro_img3,
      turbopro_img4,
      turbopro_img5,
    ],
    iframeSrc:
      "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d1359.3988205713763!2d28.83189352275421!3d47.04420149791443!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x40c97daf6869a6fb%3A0x73c5d3823d48f25e!2sPetricani%20St%2017%2C%20Chi%C8%99in%C4%83u%2C%20Moldova!5e0!3m2!1sen!2s!4v1723781414820!5m2!1sen!2s",
    icons: [
      {
        url: ico1,
        available: true,
      },
      {
        url: ico2,
        available: true,
      },
      {
        url: ico3,
        available: true,
      },
      {
        url: ico4,
        available: false,
      },
      {
        url: ico5,
        available: false,
      },
      {
        url: ico6,
        available: false,
      },
    ],
    serviceTypes: [
      {
        title: "Turbine/Turbocharger",
        icon: turbine_ico,
        services: {
          service1: "Turbine diagnostics",
          service2: "Checking and adjusting boost pressure",
          service3: "Turbine repair",
          service4: "Turbine replacement",
          service5: "Checking boost system tubes",
          service6: "Turbine geometry adjustment",
        },
      },
    ],
  },
  {
    id: 23,
    url: "bus-glass",
    name: "Auto glass - cutting and installation",
    image: busglass_preview,
    bigImage: busglass_big,
    description: "Auto glass for cars and minibuses, windshields, rear, side.",
    phone: "+373 68 623 085",
    address: "Chisinau, sec. Botanica, St. Sarmizegetusa 12",
    regiune: ["Chisinau"],
    sector: ["sec. Botanica"],
    mapAddress: "https://maps.app.goo.gl/xQasEUjUbXjDM4or6",
    views: 1462,
    orar: "Mon-Fri: 09:00 — 18:00",
    gallery: [
      busglass_img1,
      busglass_img2,
      busglass_img3,
      busglass_img4,
      busglass_img5,
    ],
    iframeSrc:
      "https://www.google.com/maps/embed?pb=!1m10!1m8!1m3!1d1360.738722232871!2d28.86506451861001!3d46.99159752994075!3m2!1i1024!2i768!4f13.1!5e0!3m2!1sru!2s!4v1725965207015!5m2!1sru!2s",
    icons: [
      {
        url: ico1,
        available: true,
      },
      {
        url: ico2,
        available: true,
      },
      {
        url: ico3,
        available: true,
      },
      {
        url: ico4,
        available: false,
      },
      {
        url: ico5,
        available: false,
      },
      {
        url: ico6,
        available: false,
      },
    ],
    serviceTypes: [
      {
        title: "Car glass",
        icon: car_wind_ico,
        services: {
          service1: "Windshield replacement",
          service2: "Înlocuirea ferestrelor auto",
          service3: "Car glass cutting",
        },
      },
    ],
  },
  {
    id: 24,
    url: "repairpe",
    name: "Peugeot rear beam repair. Chassis repair",
    image: repairpeu_preview,
    bigImage: repairpeu_big,
    description:
      "Restoration of the rear beam on Peugeot 206, Citroen Berlingo, Peugeor Partner, Xsara, Picasso. Complete restoration of the beam to the factory condition, replacement of pins, bearings, replacement of levers and torsion bars, silent blocks of levers, floating silent blocks, all spare parts are original, French, reasonable prices, call, we will agree. After repair, the beam is like new, while maintaining the quality of the factory.",
    phone: "+373 79 239 689",
    address: "Chisinau, sec. Botanica, St. Salcamilor 5A",
    regiune: ["Chisinau"],
    sector: ["Botanica"],
    mapAddress: "https://maps.app.goo.gl/QBU9c3s48GY7vVT87",
    views: 1462,
    orar: "Mon-Fri: 08:00 — 18:00",
    gallery: [
      repairpeu_img1,
      repairpeu_img2,
      repairpeu_img3,
      repairpeu_img4,
      repairpeu_img5,
    ],
    iframeSrc:
      "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d1361.098950475886!2d28.896941432183844!3d46.977447438869895!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x40c9796fc7062057%3A0x2875ca59df760c0!2zU3RyYWRhIFNhbGPDom1pbG9yIDUsIENoaciZaW7Eg3UsINCc0L7Qu9C00L7QstCw!5e0!3m2!1sru!2s!4v1725965408001!5m2!1sru!2s",
    icons: [
      {
        url: ico1,
        available: true,
      },
      {
        url: ico2,
        available: true,
      },
      {
        url: ico3,
        available: true,
      },
      {
        url: ico4,
        available: false,
      },
      {
        url: ico5,
        available: false,
      },
      {
        url: ico6,
        available: false,
      },
    ],
    serviceTypes: [
      {
        title: "Steering rack",
        icon: volan_ico,
        services: {
          service1: "Steering rack diagnostics",
          service2: "Steering rack replacement",
          service3: "Steering rack repair",
        },
      },
      {
        title: "Chassis",
        icon: suspension_ico,
        services: {
          service1: "Chassis diagnostics/check",
          service2: "Ball joints, rods, bushings replacement",
          service3: "Silent blocks replacement",
          service4: "Wheel alignment",
          service5: "Wheel bearings replacement",
          service6: "Brake discs and pads replacement",
          service7: "Drive replacement",
          service8: "Joints/grenades replacement",
          service9: "Beam repair",
          service10: "Replacing the beam",
        },
      },
      {
        title: "Engine (Motor)",
        icon: engine_ico,
        services: {
          service1: "Timing chain/belt replacement",
          service2: "Replacing the pump",
        },
      },
    ],
  },
  {
    id: 25,
    url: "resormd",
    name: "Resor.md — Car and truck repair",
    image: resormd_preview,
    bigImage: resormd_big,
    description:
      "We offer the best service for maintenance and repair of cars and trucks.",
    phone: "+373 60 522 222",
    address: "Chisinau, sec. Ciocana, St. Milescu Spatarul 38/1",
    regiune: ["Chisinau"],
    sector: ["Ciocana"],
    mapAddress: "https://maps.app.goo.gl/9ysGnwUHfRuafUHM8",
    views: 1462,
    orar: "Mon-Fri: 08:00 — 18:00, Sat: 08:00 — 14:00",
    gallery: [
      resormd_img1,
      resormd_img2,
      resormd_img3,
      resormd_img4,
      resormd_img5,
    ],
    iframeSrc:
      "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d12927.065634638155!2d28.89542305562332!3d47.06807322377689!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x40c97d149628de3b%3A0x4dff2a07a2cc40c4!2sRESOR.MD!5e0!3m2!1sen!2s!4v1725511659382!5m2!1sen!2s",
    icons: [
      {
        url: ico1,
        available: true,
      },
      {
        url: ico2,
        available: true,
      },
      {
        url: ico3,
        available: true,
      },
      {
        url: ico4,
        available: false,
      },
      {
        url: ico5,
        available: false,
      },
      {
        url: ico6,
        available: false,
      },
    ],
    serviceTypes: [
      {
        title: "Engine (Motor)",
        icon: engine_ico,
        services: {
          service1: "Engine diagnostics",
          service2: "Engine (motor) overhaul",
          service3: "Checking spark plugs",
          service4: "Ignition system diagnostics",
          service5: "Spark plug replacement",
          service6: "Engine replacement",
          service7: "Timing chain/belt replacement",
          service8: "Replacing the pump",
          service9: "Cooling system diagnostics and repair",
          service10: "Valve adjustment",
          service11: "Cylinder head gasket replacement",
        },
      },
      {
        title: "Brake system",
        icon: brakes_ico,
        services: {
          service1: "Brake fluid change",
          service2: "Brake pad replacement",
          service3: "Handbrake repair",
          service4: "Handbrake adjustment",
          service5: "Brake repair",
          service6: "Brake discs replacement",
          service7: "Brake disc boring",
          service8: "Brake system bleeding",
          service9: "Brake hose replacement",
          service10: "Brake cylinder replacement",
          service11: "Brake caliper repair",
        },
      },
      {
        title: "Gearbox/Transmission",
        icon: car_manual_gearbox_ico,
        services: {
          service1: "Manual gearbox diagnostics",
          service2: "Gearbox repair (manual)",
          service3: "Gearbox repair (automatic)",
          service4: "Gearbox replacement (manual)",
          service5: "Clutch replacement (manual)",
          service6: "Clutch adjustment (manual)",
          service7: "Gear handle repair (shift linkage)",
        },
      },
      {
        title: "Electrica auto",
        icon: energy_ico,
        services: {
          service1: "Checking and replacing the battery",
          service2: "Generator diagnostics",
          service3: "Generator repair",
          service4: "Generator replacement",
          service5: "Starter diagnostics",
          service6: "Starter repair",
          service7: "Starter replacement",
        },
      },
      {
        title: "Steering rack",
        icon: volan_ico,
        services: {
          service1: "Steering rack diagnostics",
          service2: "Steering rack replacement",
          service3: "Steering rack repair",
        },
      },
      {
        title: "Air conditioning",
        icon: ac_ico,
        services: {
          service1: "Air conditioning refill (with freon)",
        },
      },
      {
        title: "Filters",
        icon: car_filter_ico,
        services: {
          service1: "Fuel filter replacement",
          service2: "Air filter replacement",
          service3: "Cabin filter replacement",
          service4: "Oil filter replacement",
          service5: "Gearbox filter replacement",
        },
      },
    ],
  },
  {
    id: 26,
    url: "glass",
    name: "Auto glass replacement - We work only on callouts",
    image: glass_preview,
    bigImage: glass_big,
    description:
      "Replacement, dismantling, installation, sale and all types of services in the field of windshields, side and rear car windows. Selection and sale of glass to order, athermal glass (chameleon) and much more. We are working throughout Moldova, frequent customers get a good discount. Within the city, visit is free.",
    phone: "+373 68 900 488",
    address: "Chisinau, Only on callouts",
    regiune: ["Chisinau"],
    sector: ["Botanica"],
    mapAddress: "https://maps.app.goo.gl/cLrPwQHykkccB9K17",
    views: 1462,
    orar: "Mon-Sat: 08:00 — 18:00",
    gallery: [glass_img1, glass_img2, glass_img3, glass_img4, glass_img5],
    iframeSrc:
      "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d87055.34048087192!2d28.8382612059755!3d47.01119438650962!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x40c97c3628b769a1%3A0x37d1d6305749dd3c!2zQ2hpyJlpbsSDdSwgTW9sZG92YQ!5e0!3m2!1sen!2s!4v1725769275383!5m2!1sen!2s",
    icons: [
      {
        url: ico1,
        available: false,
      },
      {
        url: ico2,
        available: false,
      },
      {
        url: ico3,
        available: false,
      },
      {
        url: ico4,
        available: false,
      },
      {
        url: ico5,
        available: false,
      },
      {
        url: ico6,
        available: true,
      },
    ],
    serviceTypes: [
      {
        title: "Car glass",
        icon: car_wind_ico,
        services: {
          service1: "Windshield replacement",
          service2: "Înlocuirea ferestrelor auto",
        },
      },
    ],
  },
  {
    id: 27,
    url: "electric",
    name: "Auto electrician on call",
    image: electric_preview,
    bigImage: electric_big,
    description:
      "We provide a range of comprehensive auto electrics services: Auto diagnostics, error removal, alarm disabling, wiring restoration, fuse replacement, replacement of bulbs and headlights, plugs, connectors. Auto Electrics Repair Auto electronics, diagnostics, repair of ECU, ELV, BSM, ABS units, instrument panels, repair of underhood harnesses, instrument lighting replacement, data transfer, coding, adaptation, spare parts in stock, our own laboratory, 21 years in the business, we solve any problem at the hardware level. We solve the Red Steering Wheel problem on all VAG generations. I repair the KEY LESS system, repair fan control units, work with an oscilloscope, and solve dead-end situations. We find current leaks, including those associated with the CAN bus not sleeping. We will install any additional equipment. Call or write. Departure. Removal of component protection.",
    phone: "+373 78 284 508",
    address: "Chisinau, sec. Centru, St. Bucuresti 6",
    regiune: ["Chisinau"],
    sector: ["Centru"],
    mapAddress: "https://maps.app.goo.gl/Wod3sEzaQD9HyGwA9",
    views: 1462,
    orar: "Mon-Sat: 08:00 — 18:00",
    gallery: [
      electric_img1,
      electric_img2,
      electric_img3,
      electric_img4,
      electric_img5,
    ],
    iframeSrc:
      "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d961.8125211551273!2d28.8423942101532!3d47.01242659704182!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x40c97c23c00e1b69%3A0xd5f07948a2ecb039!2zU3RyYWRhIEJ1Y3VyZciZdGkgNiwgTUQtMjAwMSwgQ2hpyJlpbsSDdSwg0JzQvtC70LTQvtCy0LA!5e0!3m2!1sru!2s!4v1725965556147!5m2!1sru!2s",
    icons: [
      {
        url: ico1,
        available: true,
      },
      {
        url: ico2,
        available: false,
      },
      {
        url: ico3,
        available: true,
      },
      {
        url: ico4,
        available: true,
      },
      {
        url: ico5,
        available: false,
      },
      {
        url: ico6,
        available: false,
      },
    ],
    serviceTypes: [
      {
        title: "Auto electrics",
        icon: energy_ico,
        services: {
          service1: "Wiring diagnostics",
          service2: "Wiring repair",
          service3: "Replacing fuses and relays",
          service4: "Checking and replacing the battery",
          service5: "Generator diagnostics",
          service6: "Starter diagnostics",
          service7:
            "Installation and configuration of additional equipment (camera, android auto)",
          service8: "Checking and repairing lighting devices",
          service9: "Repairing the dashboard (speedometer)",
        },
      },
      {
        title: "EGR and particulate filter",
        icon: catalyst_ico,
        services: {
          service1: "EGR valve computer disabling",
          service2: "Particulate filter removal (cutting)",
        },
      },
    ],
  },
];
// randomizer
function shuffleArray(array) {
  let shuffledArray = array.slice();
  for (let i = shuffledArray.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1));
    [shuffledArray[i], shuffledArray[j]] = [shuffledArray[j], shuffledArray[i]];
  }
  return shuffledArray;
}
const servicesEn = shuffleArray(array);
export default servicesEn;
