import React, { createContext, useContext, useState } from 'react';

const DropDownContext = createContext();

const jsonData = {
  "orase": [
    {
      "nume": "Chisinau",
      "raioane": [
        "Botanica",
        "Centru",
        "Buiucani",
        "Riscani",
        "Ciocana"
      ]
    }
  ],
  "raioane": {
    "Anenii Noi": [
      "Anenii Noi",
      "Delacău",
      "Bulboaca",
      "Gura Bîcului",
      "Mereni",
      "Speia",
      "Șerpeni"
    ],
    "Criuleni": [
      "Criuleni",
      "Dolinnoe",
      "Maximovca",
      "Onițcani",
      "Slobozia-Dușca",
      "Boșcana",
      "Cimișeni"
    ],
    "Ialoveni": [
      "Ialoveni",
      "Costești",
      "Bardar",
      "Mileștii Mici",
      "Molești",
      "Țîpala",
      "Băcioi",
      "Cărbuna",
      "Dănceni",
      "Gangura",
      "Horodca",
      "Malcoci",
      "Nimoreni",
      "Pojăreni",
      "Ruseștii Noi",
      "Sociteni",
      "Suruceni",
      "Văsieni",
      "Zîmbreni"
    ],
    "Strășeni": [
      "Strășeni",
      "Vorniceni",
      "Scoreni",
      "Căpriana",
      "Cojușna",
      "Ghelăuza",
      "Micleușeni",
      "Negrești",
      "Rassvet",
      "Romănești",
      "Saca",
      "Voinova"
    ]
  }
};

export const DropDownProvider = ({ children }) => {
  const initialState = {
    jobType: [],
    regionOrCity: [],
    subOption: [],
    carBrand: []
  };

  const [selectedOptions, setSelectedOptions] = useState(initialState);

  const updateSelectedOption = (dropdown, option) => {
    setSelectedOptions((prev) => {
      let newOptions;
      const currentOptions = prev[dropdown];

      if (Array.isArray(option)) {
        if (option.length === 0) {
          newOptions = [];
          if (dropdown === 'regionOrCity') {
            clearSubOption();
          }
        } else {
          newOptions = option;
        }
      } else {
        if (Array.isArray(currentOptions)) {
          if (currentOptions.includes(option)) {
            newOptions = currentOptions.filter((item) => item !== option);
            if (dropdown === 'regionOrCity' && newOptions.length === 0) {
              clearSubOption();
            }
          } else {
            newOptions = [...currentOptions, option];
          }
        } else {
          newOptions = option;
        }
      }

      if (dropdown === 'regionOrCity') {
        const activeSubOptions = newOptions.flatMap(opt => getSubOptions([opt]));
        return { ...prev, [dropdown]: newOptions, subOption: prev.subOption.filter(subOpt => activeSubOptions.includes(subOpt)) };
      }

      return { ...prev, [dropdown]: newOptions };
    });
  };

  const clearSubOption = () => {
    setSelectedOptions((prev) => ({ ...prev, subOption: [] }));
  };

  const resetSelectedOptions = () => {
    setSelectedOptions(initialState);
  };

  const getSubOptions = (options) => {
    let subOptions = [];
    options.forEach(option => {
      if (jsonData.raioane[option]) {
        subOptions = [...subOptions, ...jsonData.raioane[option]];
      }
      const city = jsonData.orase.find(city => city.nume === option);
      if (city) {
        subOptions = [...subOptions, ...city.raioane];
      }
    });
    return subOptions;
  };

  return (
    <DropDownContext.Provider value={{ selectedOptions, updateSelectedOption, clearSubOption, resetSelectedOptions }}>
      {children}
    </DropDownContext.Provider>
  );
};

export const useDropDown = () => useContext(DropDownContext);
