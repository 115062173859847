import React, { useState, useEffect, useRef, useContext } from "react";
import { useTranslation } from "react-i18next";
import { Link, useLocation, useNavigate } from "react-router-dom";
import ico1 from "../../assets/img/ico1.svg";
import place from "../../assets/img/page.svg";
import right from "../../assets/img/right.svg";
import left from "../../assets/img/left.svg";
import One_Ad from "../../assets/img/One Ad.svg";
import custom_ad from "../../assets/img/custom-ad.png";
import ser2 from "../../assets/img/ser2.svg";
import alert from "../../assets/img/alert.svg";
import view from "../../assets/img/view.svg";
import elements from "../../assets/img/elements.svg";
import call from "../../assets/img/call (2).svg";
import verified from "../../assets/img/verified.svg";
import servicesRo from "../Data/ServiceRo.js";
import servicesRu from "../Data/ServiceRu.js";
import servicesEn from "../Data/ServiceEn.js";

import "./Services.css";
import DropDownFilters from "../dropDownFilters/dropDown.component.jsx";
import { useDropDown } from "../../contexts/dropDown.context.jsx";
import { LanguageContext } from "../../contexts/language.context.jsx";
const Services = ({ searchQuery }) => {
  const { selectedOptions } = useDropDown();
  const { t, i18n } = useTranslation();
  const location = useLocation();
  const navigate = useNavigate();

	const currentLanguage = i18n.language; 

  const itemsPerPage = 10;
  const query = new URLSearchParams(location.search);
  const initialPage = parseInt(query.get("page"), 10) || 1;

  const [currentPage, setCurrentPage] = useState(initialPage);

  useEffect(() => {
    setCurrentPage(initialPage);
  }, [initialPage]);

  useEffect(() => {
    if (currentPage !== initialPage) {
      query.set("page", currentPage);
      navigate(`${location.pathname}?${query.toString()}`, { replace: true });
    }
  }, [currentPage]);
  const [services, setServices] = useState([]);
 const { selectedLanguage, changeLanguage } = useContext(LanguageContext);
	useEffect(() => {
    // Determine the services array based on the language code in the URL
    const pathSegments = location.pathname.split('/');
    const lang = pathSegments[1];
    
    if (lang === 'ro') {
      setServices(servicesRo);
    } else if (lang === 'ru') {
      setServices(servicesRu);
    } else {
      setServices(servicesEn);
    }
  }, [location.pathname, selectedLanguage]);

  const dropDownRegionFilter = selectedOptions.regionOrCity;
  const dropDownSectorFilter = selectedOptions.subOption;
  const dropDownJobTypeFilter = selectedOptions.jobType;





  const filteredServices = services.filter((service) => {
    const lowercasedQuery = searchQuery.toLowerCase();

    const matchesNameOrDescription =
      service.name.toLowerCase().includes(lowercasedQuery) ||
      service.description.toLowerCase().includes(lowercasedQuery);

    const matchesServiceTypes = service.serviceTypes?.some((type) =>
      type.title.toLowerCase().includes(lowercasedQuery) ||
      Object.values(type.services).some((service) =>
        service.toLowerCase().includes(lowercasedQuery)
      )
    );

    const matchesJobTypeFilter =
      dropDownJobTypeFilter.length === 0 ||
      service.serviceTypes.some((type) =>
        dropDownJobTypeFilter.includes(type.title)
      );

    const matchesRegionTypeFilter =
      dropDownRegionFilter.length === 0 ||
      service.regiune.some((type) =>
        dropDownRegionFilter.includes(type)
      );

    const matchesSectorTypeFilter =
      dropDownSectorFilter.length === 0 ||
      service.sector.some((type) =>
        dropDownSectorFilter.includes(type)
      );

    return (matchesNameOrDescription || matchesServiceTypes) && matchesJobTypeFilter && matchesRegionTypeFilter && matchesSectorTypeFilter;
  });

  const totalPages = Math.ceil(filteredServices.length / itemsPerPage);

  const handleClick = (page) => {
    if (page >= 1 && page <= totalPages) {
      setCurrentPage(page);
    }
  };

  const displayedServices = filteredServices.slice(
    (currentPage - 1) * itemsPerPage,
    currentPage * itemsPerPage
  );

  const TruncatedDescription = ({ text }) => {
    const ref = useRef(null);
    const [truncatedText, setTruncatedText] = useState(text);

    useEffect(() => {
      const resizeListener = () => {
        const containerWidth = ref.current.clientWidth;
        const charsPerLine = Math.floor(containerWidth / 10);
        const maxChars = charsPerLine * 2;
        const shouldTruncate = text.length > maxChars;
        setTruncatedText(
          shouldTruncate ? `${text.substring(0, maxChars - 3)}...` : text
        );
      };

      resizeListener();
      window.addEventListener("resize", resizeListener);

      return () => {
        window.removeEventListener("resize", resizeListener);
      };
    }, [text]);

    return <p className="pubDescription" ref={ref}>{truncatedText}</p>;
  };

  return (
    <>
      <div className="main-services">
        <div className="blocks">
          <DropDownFilters />
          {displayedServices.length > 0 ? (
            displayedServices.map((service) => (
              <Link
                to={`/${currentLanguage}/${encodeURIComponent(service.url)}`}
                className="single-service ss"
                key={service.id}
              >
                <div className="inner-data">
                  <div className="service-img for-desk">
                    <img src={service.image} alt={service.name} />
                  </div>
                  <div className="service-detail">
                    <div className="col-det">
                      <p className="headf onlyeig">
                        {service.name} <img src={verified} alt="" />
                      </p>
                      <p className="comfort">
                        <div className="service-ico">
                          {service.icons.map((icon) => {
                            const iconStyle = {
                              opacity: icon.available ? 1 : 0.5,
                              filter: icon.available
                                ? "none"
                                : "grayscale(100%)", // Apply grayscale when not available
                            };
                            return (
                              <img
                                src={icon.url}
                                style={iconStyle}
                                alt="icon"
                                key={icon.url}
                              />
                            );
                          })}
                        </div>
                      </p>
                      <div className="service-img for-mbl">
                        <img src={service.image} alt={service.name} />
                      </div>
                      {service.alert ? (
                        <p className="alert ssa avb">
                          <img src={alert} alt="" />
                          {service.alert}
                        </p>
                      ) : (
                        ""
                      )}
                      <TruncatedDescription text={service.description} />
                      <p className="mail-ph">
                        <img src={call} alt="" />
                        <a href={`tel:${service.phone}`}>{service.phone}</a>
                      </p>
                      <span className="for-mbl divider"></span>
                      <a
                        href={service.mapAddress}
                        target="_blank"
                        className="mail-ph bllo m7"
                      >
                        <img src={elements} alt="" />
                        <span>
                          <span className="for-desk">{service.address}</span>
                          <span className="for-mbl">{service.address}</span>
                        </span>
                      </a>
                    </div>
                  </div>
                </div>
              </Link>
            ))
          ) : (
            <div className="no-service-found">
              <p>{t("homePage.services.notFound")}</p>
            </div>
          )}

          {filteredServices.length > itemsPerPage && (
            <div className="pagination">
              <div
                className="single-pg"
                onClick={() => handleClick(currentPage - 1)}
                disabled={currentPage === 1}
              >
                <img src={left} alt="Previous" />
              </div>
              {Array.from({ length: totalPages }, (_, index) => (
                <div
                  key={index + 1}
                  className={`single-pg ${
                    currentPage === index + 1 ? "active-pg" : ""
                  }`}
                  onClick={() => handleClick(index + 1)}
                >
                  {index + 1}
                </div>
              ))}
              <div
                className="single-pg"
                onClick={() => handleClick(currentPage + 1)}
                disabled={currentPage === totalPages}
              >
                <img src={right} alt="Next" />
              </div>
            </div>
          )}

          <img className="pga hide" src={place} alt="" />
          <p className="fixauto">{t("homePage.services.fixauto")}</p>
        </div>
        <div className="ads-area ">
          <a href="https://carkeys.md/" target="_blank">
            <img src={custom_ad} alt="" />
          </a>
          <img className="hide" src={One_Ad} alt="" />
        </div>
      </div>
    </>
  );
};

export default Services;
