import React, { useState, useEffect } from "react";
import search_01 from "../../assets/img/search-01.svg";
import logo_white from "../../assets/img/logo-white.svg";
import "./Banner.css";
import { useTranslation } from "react-i18next";
import { useNavigate } from 'react-router-dom';
import { useDropDown } from "../../contexts/dropDown.context";


const Banner = () => {


const{ resetSelectedOptions} = useDropDown();
  const navigate = useNavigate();
  const [searchTerm, setSearchTerm] = useState("");

  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value);
  };

  const handleSearchSubmit = (event) => {
  event.preventDefault();
resetSelectedOptions()
  const currentLanguage = i18n.language; 
  if (searchTerm) {
    navigate(`/${currentLanguage}/list?query=${searchTerm}`);
  }
};


  const { t } = useTranslation();
  const { i18n } = useTranslation();
  
  return (
    <div className="main-banner">
      <div className="main-cont">
        <img src={logo_white} alt="" />
        <p>{t('homePage.banner.description')}</p>
        <form className="search-input search-hm" onSubmit={handleSearchSubmit}>
          <img src={search_01} alt="" />
          <input
            className="searcher"
            value={searchTerm}
            onChange={handleSearchChange}
            type="text"
            placeholder={t('homePage.banner.searchPlaceholder')}
          />
          <input type="submit" className="search-btn" value={t('homePage.banner.searchBtn')} />
        </form>
      </div>
    </div>
  );
};

export default Banner;
