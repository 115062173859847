import styled from "styled-components";

export const DropDownWrapper = styled.div`
  display: flex;
  width: 100%;
  height: 60px;
  box-shadow: 0px 1px 4px 0px #0000001A;

  .firstElement {
    border-bottom-left-radius: 8px;
    border-top-left-radius: 8px;
	  @media (min-width: 0px) and (max-width: 999px) {
	 border-bottom-left-radius: 0px;
    border-top-left-radius: 8px;
	 border-top-right-radius: 8px;
  }
  }
  .lastElement {
    border-bottom-right-radius: 8px;
    border-top-right-radius: 8px;
	  @media (min-width: 0px) and (max-width: 999px) {
		border-bottom-right-radius: 8px;
    border-top-right-radius: 0px;
    border-bottom-left-radius: 8px;

	  }
  }

  @media (min-width: 0px) and (max-width: 999px) {
		flex-direction: column;
		height: 100%;
  }
`;

export const DropDownContainer = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  @media (min-width: 0px) and (max-width: 999px) {
	position: static;
  }
`;

export const DropDownHeader = styled.div`
  color: #8C8D8E;
  box-sizing: border-box;
  width: 100%;
  background-color: ${({ selected }) => (selected ? '#e0f7fa' : '#FFFFFF')};
  border-left: 1px solid #EFEFEF;
  height: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px;
  font-weight: 500;
  cursor: pointer;
  &:hover {
    background-color: #F3F7FF;
  }

  &.thereIsSomething{
	color:#2F80ED;
  }

  @media (min-width: 0px) and (max-width: 999px) {

  }
`;

export const DropDownList = styled.ul`
  position: absolute;
  background-color: white;
  border: 1px solid #ccc;
  border-radius: 4px;
  width: 100%;
  height: 300px;
  overflow-y: auto;
  top: 65px;
  margin: 0;
  padding: 4px 0px 20px 0px;
  list-style: none;
  	z-index: 21474836490;
  box-sizing: border-box;
  box-shadow: 0px 1px 40px 0px #0000001A;

  .dropDownMobileHeader, .mobileTypeFilter h1, .resultButton{
	display: none;
  }
  @media (min-width: 0px) and (max-width: 999px) {
	position: fixed;
	height: 100vh;
	width: 100vw;
	top: 0;
	left: 0;
	padding-bottom: 100px;
	 /* left: ${({ open }) => (open ? '100vw' : '100vw')}; */
	 transition: all 0.3s ease;
.resultButton{
	position: fixed;
	bottom: 3%;
	left: 50%;
	transform: translate(-50%);
	display: block;
	width: 90%;
	height: 50px;
	background-color: #1D2126;
	border-radius: 8px;
	z-index: 100;
	button{
		width: 100%;
		height: 100%;
		border: none;
		background-color: transparent;
		background: transparent;
		color: #FFFFFF;
		font-size: 14px;
		font-weight: 600;
		line-height: 20px;
	}
}
	 .mobileTypeFilter{
		display: flex;
		justify-content: space-between;
		padding-left: 20px;
		h1{
			display: block;
			font-size: 21px;
			line-height: 25.6px;
			font-weight: 600;
		}
		.removeFilter{
			padding: 0 0 0 0;
			font-size: 12px ;
			line-height: 20px;
			border-bottom: none;
			svg{
				margin-left: 6px;
			}
		}
	 }
	 .dropDownMobileHeader{
		display: flex;
		align-items: center;
		justify-content: center;
		position: relative;
		padding: 8px 0;
		border-bottom: 1px solid #E1E6ED;
		img{
			width: 55px;
		}
		.cancelButton{
			height: 40px;
			width: 40px;
			display: flex;
			align-items: center;
			justify-content: center;
			position: absolute;
			right: 10px;
			svg{
				path{

					stroke: #A1ABB2;
				}
			}
		}
	 }
  }
`;

export const ListItem = styled.li`
  padding: 11px 15px 11px 20px;
  display: flex;
  align-items: center;
	box-sizing: border-box;

  justify-content: space-between;
  cursor: pointer;
  div{
	width: 150px;
  }
  svg{
	margin-right: 5px;
	width: 20px;
	  path{
		stroke: #E51E1E;
	}
  }
  .mark{
	position: relative;
	background-color: none;
	display: flex;
	align-items: center;
	justify-content: center;
	width: 24px;
	height: 24px;
	box-sizing: border-box;
	border-radius: 8px;
	border: 1px solid #E1E6ED;
	svg{
		opacity: 0;
		margin-right: 0px;
		width: auto;
		path{
		stroke: #ffffff;
		}
	}
  }
  &.removeFilter{
	padding: 11px 0 11px 0;
	margin:  0 15px 0 20px;
	color: #E51E1E;
	border-bottom: 1px solid #EFEFEF
  }
  &:hover {
    .mark{
		background-color: #EBF3FF;
	 }
  }
  &.checked{
	font-weight: 500;
	.mark{
	background-color: #2F80ED;
		svg{
			opacity: 1;
		}
	}
  }

	

`;

export const Arrow = styled.div`
  transform: ${({ open }) => (open ? 'rotate(180deg)' : 'rotate(0deg)')};
  transition: transform 0.3s ease;
  @media (min-width: 0px) and (max-width: 999px) {
	transform: none;
	transform: rotate(-90deg);
  }
`;

export const Checkbox = styled.input`
  margin-right: 10px;
  position: absolute;
  left: 0;
  z-index: -1;
  opacity: 0;


`;
