import React, { useState, useContext, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { LanguageContext } from "../../contexts/language.context";
import Polygon from "../../assets/img/Polygon.svg";
import language from "../../assets/img/Vector.svg";
import logo_fixauto from "../../assets/img/logo-fixauto.svg";
import sparkles from "../../assets/img/sparkles.svg";
import "./header.css";

const Header = () => {
  const [isDrop, setIsDrop] = useState(false);
  const { t } = useTranslation();
  const { selectedLanguage, changeLanguage } = useContext(LanguageContext);
  const languages = [
    { code: "en", lang: "EN" },
    { code: "ro", lang: "RO" },
    { code: "ru", lang: "RU" },
  ];

  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    const currentPath = location.pathname;
    const pathSegments = currentPath.split("/").filter(Boolean);

    if (pathSegments[0] !== selectedLanguage) {
      const newPath = `/${selectedLanguage}/${pathSegments.slice(1).join("/")}`;
      navigate(newPath, { replace: true });
    }
  }, [selectedLanguage, location, navigate]);

  const handleLanguageChange = (lng) => {
    changeLanguage(lng);
    setIsDrop(false);
  };

  const toggleDrop = () => {
    setIsDrop(!isDrop);
  };

  return (
    <div className="main-header">
      <div className="top-banner">
        <a
          href="https://t.me/fixautomd"
          target="_blank"
          className="max-width for-desk banner-data"
        >
          <img src={sparkles} alt="" />
          <p className="plce">{t("header.topBanner")}</p>
          <p className="plce2">
            <span className="bn">→</span>
          </p>
        </a>
        <a
          href="https://t.me/fixautomd"
          target="_blank"
          className="max-width for-mbl fsw banner-data"
        >
          <p className="plce">{t("header.mobileBanner")}</p>
          <p className="plce2">→</p>
        </a>
      </div>
      <div className="nav-bar">
        <div className="max-width inner-bar">
          <a href="/" className="flexb">
            <img src={logo_fixauto} alt="Logo" />
          </a>
          <div className="drop-down">
            <div onClick={toggleDrop} className="lang-drop">
              <img src={language} alt="Language" />
              <span className="flex uppercase">
                {selectedLanguage.toUpperCase()}{" "}
                <img
                  src={Polygon}
                  className={isDrop ? "uactive" : "unactive"}
                  alt="Dropdown"
                />
              </span>
            </div>
            {isDrop && (
              <div className="active-drop">
                {languages.map((lng) => (
                  <div
                    key={lng.code}
                    onClick={() => handleLanguageChange(lng.code)}
                    className="selector"
                  >
                    {lng.lang}
                  </div>
                ))}
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Header;
