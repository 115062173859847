import shock from "../../assets/img/shock.jpg";
import comfort2 from "../../assets/img/comfort2.svg";
import ser2 from "../../assets/img/ser2.svg";
import ico1 from "../../assets/img/ico1.svg";
import ico2 from "../../assets/img/ico2.svg";
import ico3 from "../../assets/img/ico3.svg";
import ico4 from "../../assets/img/ico4.svg";
import ico5 from "../../assets/img/ico5.svg";
import ico6 from "../../assets/img/ico6.svg";
import ico7 from "../../assets/img/ico7.svg";
import ico8 from "../../assets/img/ico8.svg";
import about from "../../assets/img/about.png";
import gallery from "../../assets/img/gallery.png";
import detailing_ico from "../../assets/img/detailing-ico.svg";
import paint_ico from "../../assets/img/paint-ico.svg";
import suspension_ico from "../../assets/img/suspension-ico.svg";
import absorber_ico from "../../assets/img/absorber-ico.svg";
import engine_ico from "../../assets/img/engine-ico.svg";
import ac_ico from "../../assets/img/ac-ico.svg";
import brakes_ico from "../../assets/img/brakes-ico.svg";
import bumper_ico from "../../assets/img/bumper-ico.svg";
import far_ico from "../../assets/img/lights-ico.svg";
import noiseoff_ico from "../../assets/img/mute-ico.svg";
import salon_ico from "../../assets/img/salon-ico.svg";
import rihtovka_ico from "../../assets/img/rihtovka-ico.svg";
import atm_ico from "../../assets/img/automatic-transmission-ico.svg";
import car_filter_ico from "../../assets/img/car-filter-ico.svg";
import car_key_ico from "../../assets/img/car-key-ico.svg";
import car_manual_gearbox_ico from "../../assets/img/car-manual-gearbox-ico.svg";
import diagnostic_ico from "../../assets/img/diagnostic-ico.svg";
import hybrid_ico from "../../assets/img/hybrid-ico.svg";
import oil_change_ico from "../../assets/img/oil-change-ico.svg";
import PC_diagnostics_ico from "../../assets/img/PC-diagnostics-ico.svg";
import car_lock_ico from "../../assets/img/car-lock-ico.svg";
import turbine_ico from "../../assets/img/turbine-ico.svg";
import volan_ico from "../../assets/img/volan-ico.svg";
import wheel_ico from "../../assets/img/wheel-ico.svg";
import chip_tuning_ico from "../../assets/img/tuning-chip-ico.svg";
import catalyst_ico from "../../assets/img/catalyst-ico.svg";
import energy_ico from "../../assets/img/energy-ico.svg";
import ex_pipe_ico from "../../assets/img/ex-pipe-ico.svg";
import car_wind_ico from "../../assets/img/car-wind-ico.svg";
import img1 from "../../assets/img/img1.jpg";
import img2 from "../../assets/img/img2.jpg";
import img3 from "../../assets/img/img3.jpg";
import img4 from "../../assets/img/img2.jpg";
import img5 from "../../assets/img/img3.jpg";
import amort_preview from "../../assets/img/amort-preview.png";
import amort_big from "../../assets/img/amort-big.png";
import amort_img1 from "../../assets/img/amort-img1.png";
import amort_img2 from "../../assets/img/amort-img2.png";
import amort_img3 from "../../assets/img/amort-img3.png";
import amort_img4 from "../../assets/img/amort-img4.png";
import amort_img5 from "../../assets/img/amort-img5.png";
import ww_preview from "../../assets/img/ww-preview.png";
import ww_big from "../../assets/img/ww-big.png";
import ww_img1 from "../../assets/img/ww-img1.png";
import ww_img2 from "../../assets/img/ww-img2.png";
import ww_img3 from "../../assets/img/ww-img3.png";
import ww_img4 from "../../assets/img/ww-img4.png";
import ww_img5 from "../../assets/img/ww-img5.png";
import hl_preview from "../../assets/img/hl-preview.png";
import hl_big from "../../assets/img/hl-big.png";
import hl_img1 from "../../assets/img/hl-img1.png";
import hl_img2 from "../../assets/img/hl-img2.png";
import hl_img3 from "../../assets/img/hl-img3.png";
import hl_img4 from "../../assets/img/hl-img4.png";
import hl_img5 from "../../assets/img/hl-img5.png";
import key_auto_preview from "../../assets/img/key-auto-preview.png";
import key_auto_big from "../../assets/img/key-auto-big.png";
import key_auto_img1 from "../../assets/img/key-auto-img1.png";
import key_auto_img2 from "../../assets/img/key-auto-img2.png";
import key_auto_img3 from "../../assets/img/key-auto-img3.png";
import key_auto_img4 from "../../assets/img/key-auto-img4.png";
import key_auto_img5 from "../../assets/img/key-auto-img5.png";
import simple_s_preview from "../../assets/img/simple-s-preview.png";
import simple_s_big from "../../assets/img/simple-s-big.png";
import simple_s_img1 from "../../assets/img/simple-s-img1.png";
import simple_s_img2 from "../../assets/img/simple-s-img2.png";
import simple_s_img3 from "../../assets/img/simple-s-img3.png";
import simple_s_img4 from "../../assets/img/simple-s-img4.png";
import simple_s_img5 from "../../assets/img/simple-s-img5.png";
import fcs_preview from "../../assets/img/fcs-preview.png";
import fcs_big from "../../assets/img/fcs-big.png";
import fcs_img1 from "../../assets/img/fcs-img1.png";
import fcs_img2 from "../../assets/img/fcs-img2.png";
import fcs_img3 from "../../assets/img/fcs-img3.png";
import fcs_img4 from "../../assets/img/fcs-img4.png";
import fcs_img5 from "../../assets/img/fcs-img5.png";
import autof_preview from "../../assets/img/autof-preview.png";
import autof_big from "../../assets/img/autof-big.png";
import autof_img1 from "../../assets/img/autof-img1.png";
import autof_img2 from "../../assets/img/autof-img2.png";
import autof_img3 from "../../assets/img/autof-img3.png";
import autof_img4 from "../../assets/img/autof-img4.png";
import autof_img5 from "../../assets/img/autof-img5.png";
import complex_preview from "../../assets/img/complex-preview.png";
import complex_big from "../../assets/img/complex-big.png";
import complex_img1 from "../../assets/img/complex-img1.png";
import complex_img2 from "../../assets/img/complex-img2.png";
import complex_img3 from "../../assets/img/complex-img3.png";
import complex_img4 from "../../assets/img/complex-img4.png";
import complex_img5 from "../../assets/img/complex-img5.png";
import sst_preview from "../../assets/img/sst-preview.png";
import sst_big from "../../assets/img/sst-big.png";
import sst_img1 from "../../assets/img/sst-img1.png";
import sst_img2 from "../../assets/img/sst-img2.png";
import sst_img3 from "../../assets/img/sst-img3.png";
import sst_img4 from "../../assets/img/sst-img4.png";
import sst_img5 from "../../assets/img/sst-img5.png";
import starterp_preview from "../../assets/img/starterp-preview.png";
import starterp_big from "../../assets/img/starterp-big.png";
import starterp_img1 from "../../assets/img/starterp-img1.png";
import starterp_img2 from "../../assets/img/starterp-img2.png";
import starterp_img3 from "../../assets/img/starterp-img3.png";
import starterp_img4 from "../../assets/img/starterp-img4.png";
import starterp_img5 from "../../assets/img/starterp-img5.png";
import mireco_preview from "../../assets/img/mireco-preview.png";
import mireco_big from "../../assets/img/mireco-big.png";
import mireco_img1 from "../../assets/img/mireco-img1.png";
import mireco_img2 from "../../assets/img/mireco-img2.png";
import mireco_img3 from "../../assets/img/mireco-img3.png";
import mireco_img4 from "../../assets/img/mireco-img4.png";
import mireco_img5 from "../../assets/img/mireco-img5.png";
import ecot_preview from "../../assets/img/ecot-preview.png";
import ecot_big from "../../assets/img/ecot-big.png";
import ecot_img1 from "../../assets/img/ecot-img1.png";
import ecot_img2 from "../../assets/img/ecot-img2.png";
import ecot_img3 from "../../assets/img/ecot-img3.png";
import ecot_img4 from "../../assets/img/ecot-img4.png";
import ecot_img5 from "../../assets/img/ecot-img5.png";
import bogcar_preview from "../../assets/img/bogcar-preview.png";
import bogcar_big from "../../assets/img/bogcar-big.png";
import bogcar_img1 from "../../assets/img/bogcar-img1.png";
import bogcar_img2 from "../../assets/img/bogcar-img2.png";
import bogcar_img3 from "../../assets/img/bogcar-img3.png";
import bogcar_img4 from "../../assets/img/bogcar-img4.png";
import bogcar_img5 from "../../assets/img/bogcar-img5.png";
import bampermd_preview from "../../assets/img/bampermd-preview.png";
import bampermd_big from "../../assets/img/bampermd-big.png";
import bampermd_img1 from "../../assets/img/bampermd-img1.png";
import bampermd_img2 from "../../assets/img/bampermd-img2.png";
import bampermd_img3 from "../../assets/img/bampermd-img3.png";
import bampermd_img4 from "../../assets/img/bampermd-img4.png";
import bampermd_img5 from "../../assets/img/bampermd-img5.png";
import olds_preview from "../../assets/img/olds-preview.png";
import olds_big from "../../assets/img/olds-big.png";
import olds_img1 from "../../assets/img/olds-img1.png";
import olds_img2 from "../../assets/img/olds-img2.png";
import olds_img3 from "../../assets/img/olds-img3.png";
import olds_img4 from "../../assets/img/olds-img4.png";
import olds_img5 from "../../assets/img/olds-img5.png";
import detl_preview from "../../assets/img/detl-preview.png";
import detl_big from "../../assets/img/detl-big.png";
import detl_img1 from "../../assets/img/detl-img1.png";
import detl_img2 from "../../assets/img/detl-img2.png";
import detl_img3 from "../../assets/img/detl-img3.png";
import detl_img4 from "../../assets/img/detl-img4.png";
import detl_img5 from "../../assets/img/detl-img5.png";
import detg_preview from "../../assets/img/detg-preview.png";
import detg_big from "../../assets/img/detg-big.png";
import detg_img1 from "../../assets/img/detg-img1.png";
import detg_img2 from "../../assets/img/detg-img2.png";
import detg_img3 from "../../assets/img/detg-img3.png";
import detg_img4 from "../../assets/img/detg-img4.png";
import detg_img5 from "../../assets/img/detg-img5.png";
import bizon_preview from "../../assets/img/bizon-preview.png";
import bizon_big from "../../assets/img/bizon-big.png";
import bizon_img1 from "../../assets/img/bizon-img1.png";
import bizon_img2 from "../../assets/img/bizon-img2.png";
import bizon_img3 from "../../assets/img/bizon-img3.png";
import bizon_img4 from "../../assets/img/bizon-img4.png";
import bizon_img5 from "../../assets/img/bizon-img5.png";
import reikamd_preview from "../../assets/img/reikamd-preview.png";
import reikamd_big from "../../assets/img/reikamd-big.png";
import reikamd_img1 from "../../assets/img/reikamd-img1.png";
import reikamd_img2 from "../../assets/img/reikamd-img2.png";
import reikamd_img3 from "../../assets/img/reikamd-img3.png";
import reikamd_img4 from "../../assets/img/reikamd-img4.png";
import reikamd_img5 from "../../assets/img/reikamd-img5.png";
import esapserv_preview from "../../assets/img/esapserv-preview.png";
import esapserv_big from "../../assets/img/esapserv-big.png";
import esapserv_img1 from "../../assets/img/esapserv-img1.png";
import esapserv_img2 from "../../assets/img/esapserv-img2.png";
import esapserv_img3 from "../../assets/img/esapserv-img3.png";
import esapserv_img4 from "../../assets/img/esapserv-img4.png";
import esapserv_img5 from "../../assets/img/esapserv-img5.png";
import turbopro_preview from "../../assets/img/turbopro-preview.png";
import turbopro_big from "../../assets/img/turbopro-big.png";
import turbopro_img1 from "../../assets/img/turbopro-img1.png";
import turbopro_img2 from "../../assets/img/turbopro-img2.png";
import turbopro_img3 from "../../assets/img/turbopro-img3.png";
import turbopro_img4 from "../../assets/img/turbopro-img4.png";
import turbopro_img5 from "../../assets/img/turbopro-img5.png";
import busglass_preview from "../../assets/img/busglass-preview.png";
import busglass_big from "../../assets/img/busglass-big.png";
import busglass_img1 from "../../assets/img/busglass-img1.png";
import busglass_img2 from "../../assets/img/busglass-img2.png";
import busglass_img3 from "../../assets/img/busglass-img3.png";
import busglass_img4 from "../../assets/img/busglass-img4.png";
import busglass_img5 from "../../assets/img/busglass-img5.png";
import repairpeu_preview from "../../assets/img/repairpeu-preview.png";
import repairpeu_big from "../../assets/img/repairpeu-big.png";
import repairpeu_img1 from "../../assets/img/repairpeu-img1.png";
import repairpeu_img2 from "../../assets/img/repairpeu-img2.png";
import repairpeu_img3 from "../../assets/img/repairpeu-img3.png";
import repairpeu_img4 from "../../assets/img/repairpeu-img4.png";
import repairpeu_img5 from "../../assets/img/repairpeu-img5.png";
import resormd_preview from "../../assets/img/resormd-preview.png";
import resormd_big from "../../assets/img/resormd-big.png";
import resormd_img1 from "../../assets/img/resormd-img1.png";
import resormd_img2 from "../../assets/img/resormd-img2.png";
import resormd_img3 from "../../assets/img/resormd-img3.png";
import resormd_img4 from "../../assets/img/resormd-img4.png";
import resormd_img5 from "../../assets/img/resormd-img5.png";
import glass_preview from "../../assets/img/glass-preview.png";
import glass_big from "../../assets/img/glass-big.png";
import glass_img1 from "../../assets/img/glass-img1.png";
import glass_img2 from "../../assets/img/glass-img2.png";
import glass_img3 from "../../assets/img/glass-img3.png";
import glass_img4 from "../../assets/img/glass-img4.png";
import glass_img5 from "../../assets/img/glass-img5.png";
import electric_preview from "../../assets/img/electric-preview.png";
import electric_big from "../../assets/img/electric-big.png";
import electric_img1 from "../../assets/img/electric-img1.png";
import electric_img2 from "../../assets/img/electric-img2.png";
import electric_img3 from "../../assets/img/electric-img3.png";
import electric_img4 from "../../assets/img/electric-img4.png";
import electric_img5 from "../../assets/img/electric-img5.png";
import tonirovka_preview from "../../assets/img/tonirovka-preview.png";
import tonirovka_big from "../../assets/img/tonirovka-big.png";
import tonirovka_img1 from "../../assets/img/tonirovka-img1.png";
import tonirovka_img2 from "../../assets/img/tonirovka-img2.png";
import tonirovka_img3 from "../../assets/img/tonirovka-img3.png";
import tonirovka_img4 from "../../assets/img/tonirovka-img4.png";
import tonirovka_img5 from "../../assets/img/tonirovka-img5.png";
export const jsonDataRu = {
  orase: [
    {
      nume: "Кишинёв",
      raioane: [
        "Ботаника",
        "Центр",
        "Буюканы",
        "Скулянка",
        "Рышкановка",
        "Чеканы",
        "Аэропорт",
        "Дурлешты",
      ],
    },
  ],
  raioane: {},
};

const array = [
  {
    id: 1,
    url: "amortmd",
    name: "Amort.md — Ремонт амортизаторов",
    image: amort_preview,
    bigImage: amort_big,
    description:
      "Amort.md — ваш профессиональный сервисный партнер, специализирующийся на ремонте амортизаторов.Мы понимаем, что эффективная работа амортизационной системы играет ключевую роль в комфорте и безопасности вашего автомобиля. ",
    phone: "+373 67 309 001",
    address: "г. Кишинёв, Ботаника, ул. Мунчешть 255А",
    regiune: ["Кишинёв"],
    sector: ["Ботаника"],
    mapAddress: "https://maps.app.goo.gl/ABH69WASQcoF1oWs5",
    views: 1462,
    orar: "Пн-Пт: 08:30 — 17:30",
    gallery: [amort_img1, amort_img2, amort_img3, amort_img4, amort_img5],
    iframeSrc:
      "https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d240.58570989066718!2d28.89737042963562!3d46.98297288692172!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x40c9798f495a902d%3A0x17a872ae590bff95!2zQW1vcnQubWQg4oCUINCg0LXQvNC-0L3RgiDQsNC80L7RgNGC0LjQt9Cw0YLQvtGA0L7Qsg!5e0!3m2!1sen!2s!4v1721456456865!5m2!1sen!2s",
    icons: [
      {
        url: ico1,
        available: true,
      },
      {
        url: ico2,
        available: true,
      },
      {
        url: ico3,
        available: true,
      },
      {
        url: ico4,
        available: true,
      },
      {
        url: ico5,
        available: true,
      },
      {
        url: ico6,
        available: false,
      },
    ],
    serviceTypes: [
      {
        title: "Амортизаторы/подвеска",
        icon: absorber_ico,
        services: {
          service1: "Регулировка амортизаторов",
          service2: "Ремонт амортизаторов",
          service3: "Диагностика амортизаторов",
          service4: "Ремонт пневмоподвески",
          service5: "Замена амортизаторов",
          service6: "Ремонт пневмо систем ",
          service7: "Ремонт пневмо компрессора",
        },
      },
    ],
  },
  {
    id: 2,
    url: "ww",
    name: "White Wolf — ремонт двигателя и ходовой",
    image: ww_preview,
    bigImage: ww_big,
    description:
      "Мы предлагаем полную диагностику и ремонт двигателя, а также замену изношенных деталей ходовой части, и др. сервисные работы для вашего авто. Наши специалисты используют только качественные запчасти и современные методы ремонта. Гарантируем надежность и долгосрочность выполненных работ.",
    phone: "+373 79 480 316",
    address: "г. Кишинёв, Рышкановка, ул. Петрикань 141/1",
    regiune: ["Кишинёв"],
    sector: ["Рышкановка"],
    mapAddress: "https://maps.app.goo.gl/aVSsZ5bhHZZti9X96",
    views: 1462,
    orar: "Пн-Пт: 08:00 — 19:00, Сб: 08:00 — 15:00",
    gallery: [ww_img1, ww_img2, ww_img3, ww_img4, ww_img5],

    iframeSrc:
      "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d1359.271852208284!2d28.822486760031108!3d47.049183888776085!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x40c97d007ef27887%3A0x6b41219f514812a1!2zV2hpdGUgV29sZiAtINGA0LXQvNC-0L3RgiDQtNCy0LjQs9Cw0YLQtdC70Y8g0Lgg0YXQvtC00L7QstC-0Lk!5e0!3m2!1sen!2s!4v1725774321163!5m2!1sen!2s",
    icons: [
      {
        url: ico1,
        available: false,
      },
      {
        url: ico2,
        available: true,
      },
      {
        url: ico3,
        available: true,
      },
      {
        url: ico4,
        available: false,
      },
      {
        url: ico5,
        available: false,
      },
      {
        url: ico6,
        available: true,
      },
    ],
    serviceTypes: [
      {
        title: "Ходовая часть",
        icon: suspension_ico,
        services: {
          service1: "Диагностика/проверка",
          service2: "Замена шаровой, тяг, втулок",
          service3: "Замена сайлентблоков",
          service4: "Развал/схождение",
          service5: "Замена подшипников колёс",
          service6: "Замена тормозных дисков и колодок",
          service7: "Замена привода",
          service8: "Замена шарниров/гранаты",
        },
      },
      {
        title: "Коробка передач/Трансмиссия",
        icon: car_manual_gearbox_ico,
        services: {
          service1: "Ремонт коробки передач (механическая)",
          service2: "Диагностика механической коробки передачь (МКПП)",
          service3: "Замена сцепления (МКПП)",
          service4: "Регулировка сцепления (МКПП)",
        },
      },
      {
        title: "Диагностика",
        icon: diagnostic_ico,
        services: {
          service1: "Диагностика двигателя",
          service2: "Диагностика ходовой части",
          service3: "Диагностика тормозных систем",
        },
      },
      {
        title: "Рулевая рейка",
        icon: volan_ico,
        services: {
          service1: "Диагностика рулевой рейки",
          service2: "Замена рулевой рейки",
          service3: "Ремонт рулевой рейки",
        },
      },
      {
        title: "Амортизаторы/подвеска",
        icon: absorber_ico,
        services: {
          service1: "Замена амортизаторов",
          service2: "Замена пружин",
        },
      },
      {
        title: "Диски/колёса",
        icon: wheel_ico,
        services: {
          service1: "Замена шин (переобувка)",
          service2: "Балансировка колёс",
        },
      },
      {
        title: "Двигатель (Мотор)",
        icon: engine_ico,
        services: {
          service1: "Диагностика двигателя",
          service2: "Капитальный ремонт двигателя (мотора)",
          service3: "Проверка свечей зажигания",
          service4: "Диагностика системы зажигания",
          service5: "Замена свечей зажигания",
          service6: "Замена двигателя",
          service7: "Замена цепи/ремня ГРМ",
          service8: "Диагностика и ремонт системы охлаждения",
          service9: "Регулировка клапанов",
          service10: "Замена прокладки ГБЦ",
          service11: "Замена прокладки картера",
        },
      },
      {
        title: "Компьютерная диагностика",
        icon: PC_diagnostics_ico,
        services: {
          service1: "Полная диагностика электронных систем автомобиля",
          service2: "Удаление ошибок",
        },
      },
      {
        title: "Фильтра",
        icon: car_filter_ico,
        services: {
          service1: "Замена топливного фильтра",
          service2: "Замена воздушного фильтра",
          service3: "Замена фильтра салона",
          service4: "Замена масляного фильтра",
          service5: "Замена фильтра коробки передач",
        },
      },
      {
        title: "Масла и жидкости",
        icon: oil_change_ico,
        services: {
          service1: "Замена масла в двигателе",
          service2: "Замена масла в МКПП",
          service3: "Замена масла в АКПП",
          service4: "Замена жидкости гидроусилителя руля",
          service5: "Замена тосол/антифриз",
          service6: "Замена тормозной жидкости",
        },
      },
    ],
  },
  {
    id: 3,
    url: "hybrid-lider",
    name: "Hybrid Lider — ремонт гибридных и электро авто",
    image: hl_preview,
    bigImage: hl_big,
    description:
      "Hybrid Lider — первоклассный ремонт гибридных и электрических автомобилей. Полная диагностика и ремонт гибридных и электрических систем вашего авто. Высококвалифицированные специалисты используют новейшие технологии и оригинальные запчасти. Обеспечиваем надежность и превосходное качество выполненных работ.",
    phone: "+373 60 998 800",
    address: "г. Кишинёв, Ботаника, ул.Мунчешть 271а",
    regiune: ["Кишинёв"],
    sector: ["Ботаника"],
    mapAddress: "https://maps.app.goo.gl/ngyCHHQWbbjDU9YZA",
    views: 1462,
    orar: "Пн-Пт: 08:00 — 19:00",
    gallery: [hl_img1, hl_img2, hl_img3, hl_img4, hl_img5],
    iframeSrc:
      "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2722.0177886817805!2d28.901709076765968!3d46.98098523096903!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x40c979136072ec09%3A0x83088199f15e3d72!2sHibrit%20Lider!5e0!3m2!1sen!2s!4v1721555465695!5m2!1sen!2s",
    icons: [
      {
        url: ico1,
        available: false,
      },
      {
        url: ico2,
        available: true,
      },
      {
        url: ico3,
        available: true,
      },
      {
        url: ico4,
        available: true,
      },
      {
        url: ico5,
        available: true,
      },
      {
        url: ico6,
        available: false,
      },
    ],
    serviceTypes: [
      {
        title: "Гибридное/электрическое оборудование",
        icon: hybrid_ico,
        services: {
          service1: "Диагностика батареи (гибрид, плагин)",
          service2: "Ремонт батареи",
          service3: "Замена батарей",
          service4: "Калибровка, балансировка батарей/секций",
        },
      },
    ],
  },
  {
    id: 4,
    url: "key-auto",
    name: "Key Auto — ремонт автоключей",
    image: key_auto_preview,
    bigImage: key_auto_big,
    description:
      "Key Auto — сервис предоставляет широкий спектр услуг по ремонту автоключей, включая замену корпусов и нарезку ключей. Мы также предлагаем профессиональный ремонт ключей и замену батареек для всех типов автоключей.Также, мы занимаемся ремонтом и профилактикой замков, чтобы обеспечить безопасность и надежность вашего автомобиля. Наша команда экспертов готова помочь вам в любой ситуации, связанной с автоключами и замками.",
    phone: "+373 60 299 285",
    address: "г. Кишинёв, Ботаника, ул. Мелестиу 24/7",
    regiune: ["Кишинёв"],
    sector: ["Ботаника"],
    mapAddress: "https://maps.app.goo.gl/NHpmUUEXaBcRDL9s6",
    views: 1462,
    orar: "Пн-Пт: 09.00 — 18.00, Сб: 09.00 — 15:00",
    gallery: [
      key_auto_img1,
      key_auto_img2,
      key_auto_img3,
      key_auto_img4,
      key_auto_img5,
    ],
    iframeSrc:
      "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d680.2479198890907!2d28.84216909724483!3d47.00113633271606!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x40c97eb00068d995%3A0x9cafc86ac72127c3!2sCarKeys!5e0!3m2!1sen!2s!4v1721510493405!5m2!1sen!2s",
    icons: [
      {
        url: ico1,
        available: true,
      },
      {
        url: ico2,
        available: true,
      },
      {
        url: ico3,
        available: true,
      },
      {
        url: ico4,
        available: true,
      },
      {
        url: ico5,
        available: false,
      },
      {
        url: ico6,
        available: true,
      },
    ],
    serviceTypes: [
      {
        title: "Автоключи",
        icon: car_key_ico,
        services: {
          service1: "Замена корпуса ключа",
          service2: "Нарезка ключа",
          service3: "Ремонт ключей",
          service4: "Изготовление нового ключа",
          service5: "Замена батареек ключа",
          service6: "Программирование ключа",
          service7: "Дубликат ключа",
        },
      },
      {
        title: "Автозамки",
        icon: car_lock_ico,
        services: {
          service1: "Ремонт замка",
          service2: "Профилактика замка",
        },
      },
    ],
  },
  {
    id: 5,
    url: "bogcar-service",
    name: "BogCar - ремонт ходовой и автокондиционера",
    image: bogcar_preview,
    bigImage: bogcar_big,
    description:
      "Мы используем только проверенные запчасти, гарантируя качественные работы и оперативность. Доверьте нам свой автомобиль, и мы позаботимся о нем на высшем уровне! Специализируемся на ремонте ходовой части и полном ремонте кондиционеров. Наши опытные мастера обеспечат качественное обслуживание вашего автомобиля.",
    phone: "+373 79 225 502",
    address: "г. Кишинёв, Ботаника, ул. Дачия 2",
    regiune: ["Кишинёв"],
    sector: ["Ботаника"],
    mapAddress: "https://maps.app.goo.gl/Sdzwg9TB8WxYDvfA9",
    views: 1462,
    orar: "Пн-Пт: 09:00 — 18:00, Сб: 09:00 — 14:00",
    gallery: [bogcar_img1, bogcar_img2, bogcar_img3, bogcar_img4, bogcar_img5],

    iframeSrc:
      "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d1360.681714467699!2d28.845740577692002!3d46.993836547735356!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x40c97f88764d5029%3A0x11ff185429ab9487!2sAutoServise!5e0!3m2!1sen!2s!4v1722087989646!5m2!1sen!2s",
    icons: [
      {
        url: ico1,
        available: true,
      },
      {
        url: ico2,
        available: true,
      },
      {
        url: ico3,
        available: true,
      },
      {
        url: ico4,
        available: false,
      },
      {
        url: ico5,
        available: false,
      },
      {
        url: ico6,
        available: true,
      },
    ],
    serviceTypes: [
      {
        title: "Ходовая часть",
        icon: suspension_ico,
        services: {
          service1: "Диагностика/проверка",
          service2: "Замена шаровой, тяг, втулок",
          service3: "Замена сайлентблоков",
          service4: "Развал/схождение",
          service5: "Замена подшипников колёс",
          service6: "Замена тормозных дисков и колодок",
          service7: "Замена привода",
          service8: "Замена шарниров/гранаты",
        },
      },
      {
        title: "Кондиционер",
        icon: ac_ico,
        services: {
          service1: "Заправка кондиционера (заправка фреоном)",
          service2: "Замена радиатора",
          service3: "Ремонт трубок кондиционера",
          service4: "Промывка печки",
          service5: "Ремонт шлангов кондиционера",
        },
      },
      {
        title: "Фильтра",
        icon: car_filter_ico,
        services: {
          service1: "Замена топливного фильтра",
          service2: "Замена воздушного фильтра",
          service3: "Замена фильтра салона",
          service4: "Замена масляного фильтра",
          service5: "Замена фильтра коробки передач",
        },
      },
      {
        title: "Масла и жидкости",
        icon: oil_change_ico,
        services: {
          service1: "Замена масла в двигателе",
          service2: "Замена масла в МКПП",
          service3: "Замена масла в АКПП",
          service4: "Замена жидкости гидроусилителя руля",
          service5: "Замена тосол/антифриз",
          service6: "Замена тормозной жидкости",
        },
      },
      {
        title: "Диски/колёса",
        icon: wheel_ico,
        services: {
          service1: "Замена шин (переобувка)",
          service2: "Балансировка колёс",
          service3: "Замена колёс",
        },
      },
      {
        title: "Амортизаторы/подвеска",
        icon: absorber_ico,
        services: {
          service1: "Замена амортизаторов",
          service2: "Замена пружин",
        },
      },
    ],
  },
  {
    id: 6,
    url: "remont-motora-conditionera",
    name: "Ремонт мотора, кондиционера, ходовой ",
    image: simple_s_preview,
    bigImage: simple_s_big,
    description:
      "Наша автомастерская предлагает услуги 3D развала и схождения, заправку кондиционеров, ремонт ходовой части, коробки передач и моторов. Мы используем передовое оборудование и высококачественные материалы для обеспечения оптимальной работы вашего автомобиля. Наши специалисты гарантируют профессиональное выполнение работы, позволяя вам наслаждаться комфортом и уверенностью за рулем. Обращайтесь к нам для оперативного и качественного сервиса.",
    phone: "+373 78 003 003",
    address: "г. Кишинёв, Чеканы, ул. Заводская 141/1",
    regiune: ["Кишинёв"],
    sector: ["Чокана"],
    mapAddress: "https://maps.app.goo.gl/uQHx6AZRDh7UdDqJ7",
    views: 1462,
    orar: "Пн-Пт: 08:00 — 19:00, Сб: 08:00 — 15:00",
    gallery: [
      simple_s_img1,
      simple_s_img2,
      simple_s_img3,
      simple_s_img4,
      simple_s_img5,
    ],
    iframeSrc:
      "https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d680.2576661259757!2d28.89359395337314!3d47.000370853019646!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x40c97be5ba36e94b%3A0x8f3e46f76acf700a!2sAutoservice!5e0!3m2!1sen!2sus!4v1721561170671!5m2!1sen!2sus",
    icons: [
      {
        url: ico1,
        available: false,
      },
      {
        url: ico2,
        available: true,
      },
      {
        url: ico3,
        available: false,
      },
      {
        url: ico4,
        available: true,
      },
      {
        url: ico5,
        available: false,
      },
      {
        url: ico6,
        available: true,
      },
    ],
    serviceTypes: [
      {
        title: "Ходовая часть",
        icon: suspension_ico,
        services: {
          service1: "Диагностика/проверка",
          service2: "Замена шаровой, тяг, втулок",
          service3: "Замена сайлентблоков",
          service4: "Развал/схождение",
          service5: "Замена подшипников колёс",
          service6: "Замена тормозных дисков и колодок",
          service7: "Замена привода",
          service8: "Замена шарниров/гранаты",
        },
      },
      {
        title: "Коробка передач/Трансмиссия",
        icon: car_manual_gearbox_ico,
        services: {
          service1: "Ремонт коробки передач (механическая)",
          service2: "Диагностика механической коробки передачь (МКПП)",
          service3: "Замена сцепления (МКПП)",
          service4: "Регулировка сцепления (МКПП)",
          service5: "Ремонт ручки передач (кулисы)",
        },
      },
      {
        title: "Двигатель (Мотор)",
        icon: engine_ico,
        services: {
          service1: "Диагностика двигателя",
          service2: "Капитальный ремонт двигателя (мотора)",
          service3: "Проверка свечей зажигания",
          service4: "Диагностика системы зажигания",
          service5: "Замена свечей зажигания",
          service6: "Замена двигателя",
          service7: "Замена цепи/ремня ГРМ",
          service8: "Диагностика и ремонт системы охлаждения",
          service9: "Регулировка клапанов",
          service10: "Замена прокладки ГБЦ",
          service11: "Замена прокладки картера",
        },
      },
      {
        title: "Масла и жидкости",
        icon: oil_change_ico,
        services: {
          service1: "Замена масла в двигателе",
          service2: "Замена масла в МКПП",
          service3: "Замена масла в АКПП",
          service4: "Замена жидкости гидроусилителя руля",
          service5: "Замена тосол/антифриз",
          service6: "Замена тормозной жидкости",
        },
      },
      {
        title: "Тормозная система",
        icon: brakes_ico,
        services: {
          service1: "Замена тормозной жидкости",
          service2: "Замена колодок",
          service3: "Ремонт ручного тормоза (ручник)",
        },
      },
      {
        title: "Амортизаторы/подвеска",
        icon: absorber_ico,
        services: {
          service1: "Замена амортизаторов",
          service2: "Замена пружин",
        },
      },
      {
        title: "Турбина/Турбокомрессор",
        icon: turbine_ico,
        services: {
          service1: "Замена турбокомпрессора",
          service2: "Проверка трубок системы наддува",
        },
      },
      {
        title: "Фильтра",
        icon: car_filter_ico,
        services: {
          service1: "Замена топливного фильтра",
          service2: "Замена воздушного фильтра",
          service3: "Замена фильтра салона",
          service4: "Замена масляного фильтра",
          service5: "Замена фильтра коробки передач",
        },
      },
      {
        title: "Компьютерная диагностика",
        icon: PC_diagnostics_ico,
        services: {
          service1: "Полная диагностика электронных систем автомобиля",
          service2: "Удаление ошибок",
        },
      },
      {
        title: "Рулевая рейка",
        icon: volan_ico,
        services: {
          service1: "Диагностика рулевой рейки",
          service2: "Замена рулевой рейки",
          service3: "Ремонт рулевой рейки",
        },
      },
      {
        title: "Кондиционер",
        icon: ac_ico,
        services: {
          service1: "Заправка кондиционера (фреон)",
        },
      },
    ],
  },
  {
    id: 7,
    url: "force-car-service",
    name: "Force Car Service — комплексные услуги обслуживания авто",
    image: fcs_preview,
    bigImage: fcs_big,
    description:
      "Экспертный ремонт двигателей автомобилей. Полная диагностика и ремонт двигателей любой сложности. Наши опытные механики используют современные технологии и высококачественные запчасти. Гарантируем отличную производительность и долговечность вашего мотора.",
    phone: "+373 68 584 828",
    address: "г. Кишинёв, Рышкановка, ул. Измаил 103",
    regiune: ["Кишинёв"],
    sector: ["Рышкановка"],
    mapAddress: "https://maps.app.goo.gl/B2uZZKuz6H3m3brX6",
    views: 1462,
    orar: "Пн-Пт: 08:00 — 19:00, Сб: 08:00 — 15:00",
    gallery: [fcs_img1, fcs_img2, fcs_img3, fcs_img4, fcs_img5],
    iframeSrc:
      "https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d4574.106868060407!2d28.86533113289558!3d47.024918985334835!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x40c97df17df45481%3A0x1e0185095d8b8fe2!2sForce_car!5e0!3m2!1sen!2s!4v1721539511957!5m2!1sen!2s",
    icons: [
      {
        url: ico1,
        available: true,
      },
      {
        url: ico2,
        available: true,
      },
      {
        url: ico3,
        available: true,
      },
      {
        url: ico4,
        available: true,
      },
      {
        url: ico5,
        available: false,
      },
      {
        url: ico6,
        available: true,
      },
    ],
    serviceTypes: [
      {
        title: "Двигатель (Мотор)",
        icon: engine_ico,
        services: {
          service1: "Диагностика двигателя",
          service2: "Капитальный ремонт двигателя (мотора)",
          service3: "Проверка свечей зажигания",
          service4: "Диагностика системы зажигания",
          service5: "Замена свечей зажигания",
          service6: "Замена двигателя",
          service7: "Замена цепи/ремня ГРМ",
          service8: "Диагностика и ремонт системы охлаждения",
          service9: "Регулировка клапанов",
          service10: "Замена прокладки ГБЦ",
          service11: "Замена прокладки картера",
        },
      },
      {
        title: "Ходовая часть",
        icon: suspension_ico,
        services: {
          service1: "Диагностика/проверка",
          service2: "Замена шаровой, тяг, втулок",
          service3: "Замена сайлентблоков",
          service4: "Развал/схождение",
          service5: "Замена подшипников колёс",
          service6: "Замена тормозных дисков и колодок",
          service7: "Замена привода",
          service8: "Замена шарниров/гранаты",
        },
      },
      {
        title: "Коробка передач/Трансмиссия",
        icon: car_manual_gearbox_ico,
        services: {
          service1: "Ремонт коробки передач (механическая)",
          service2: "Диагностика механической коробки передачь (МКПП)",
          service3: "Замена сцепления (МКПП)",
          service4: "Регулировка сцепления (МКПП)",
        },
      },
      {
        title: "Диагностика",
        icon: diagnostic_ico,
        services: {
          service1: "Диагностика двигателя",
          service2: "Диагностика ходовой части",
          service3: "Диагностика тормозных систем",
        },
      },
      {
        title: "Рулевая рейка",
        icon: volan_ico,
        services: {
          service1: "Диагностика рулевой рейки",
          service2: "Замена рулевой рейки",
          service3: "Ремонт рулевой рейки",
        },
      },
      {
        title: "Фильтра",
        icon: car_filter_ico,
        services: {
          service1: "Замена топливного фильтра",
          service2: "Замена воздушного фильтра",
          service3: "Замена фильтра салона",
          service4: "Замена масляного фильтра",
          service5: "Замена фильтра коробки передач",
        },
      },
      {
        title: "Компьютерная диагностика",
        icon: PC_diagnostics_ico,
        services: {
          service1: "Полная диагностика электронных систем автомобиля",
          service2: "Удаление ошибок",
        },
      },
      {
        title: "Амортизаторы/подвеска",
        icon: absorber_ico,
        services: {
          service1: "Замена амортизаторов",
          service2: "Замена пружин",
        },
      },
      {
        title: "Масла и жидкости",
        icon: oil_change_ico,
        services: {
          service1: "Замена масла в двигателе",
          service2: "Замена масла в МКПП",
          service3: "Замена масла в АКПП",
          service4: "Замена жидкости гидроусилителя руля",
          service5: "Замена тосол/антифриз",
          service6: "Замена тормозной жидкости",
        },
      },
      {
        title: "Детейлинг",
        icon: detailing_ico,
        services: {
          service1: "Полировка кузова",
          service2: "Очистка и полировка дисков",
          service3: "Полировка фар",
          service4: "Замена жидкости гидроусилителя руля",
          service5: "Замена тосол/антифриз",
          service6: "Замена тормозной жидкости",
        },
      },
      {
        title: "Покраска",
        icon: paint_ico,
        services: {
          service1: "Покраска кузова",
          service2: "Покраска колёсных дисков",
        },
      },
      {
        title: "Диски/колёса",
        icon: wheel_ico,
        services: {
          service1: "Ремонт дисков",
          service2: "Сварка дисков",
          service3: "Полировка фар",
        },
      },
    ],
  },
  {
    id: 8,
    url: "auto-fast",
    name: "Вулканизация AutoFast",
    image: autof_preview,
    bigImage: autof_big,
    description:
      "Мы предлагаем профессиональные услуги по вулканизации и ремонту шин. Восстанавливаем шины используя качественные материалы. Наши специалисты сделают всё быстро, качественно и аккуратно.",
    phone: "+373 69 256 301",
    address: "г. Кишинёв, Ботаника, ул. Гренобле 134/2",
    regiune: ["Кишинёв"],
    sector: ["Ботаника"],
    mapAddress: "https://maps.app.goo.gl/WtVvkCgiSrCBGDtM8",
    views: 1462,
    orar: "Пн-Вс: 08:00 — 22:00",
    gallery: [autof_img1, autof_img2, autof_img3, autof_img4, autof_img5],
    iframeSrc:
      "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d680.6106075041856!2d28.84922053172323!3d46.97264403616815!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x40c97932187254e7%3A0xc2fbe1509b894ea1!2sVulcanizare%20AutoFast!5e0!3m2!1sen!2s!4v1722011278559!5m2!1sen!2s",
    icons: [
      {
        url: ico1,
        available: false,
      },
      {
        url: ico2,
        available: true,
      },
      {
        url: ico3,
        available: true,
      },
      {
        url: ico4,
        available: false,
      },
      {
        url: ico5,
        available: false,
      },
      {
        url: ico6,
        available: true,
      },
    ],
    serviceTypes: [
      {
        title: "Диски/колёса",
        icon: wheel_ico,
        services: {
          service1: "Балансировка колёс",
          service2: "Замена шин (переобувка)",
          service3: "Ремонт шин",
        },
      },
    ],
  },
  {
    id: 9,
    url: "complex",
    name: "Complex — ремонт двигателя и ходовой",
    image: complex_preview,
    bigImage: complex_big,
    description:
      "Мы предлагаем полную диагностику и ремонт двигателя, а также замену изношенных деталей ходовой части, и др. сервисные работы для вашего авто. Наши специалисты используют только качественные запчасти и современные методы ремонта. Гарантируем надежность и долгосрочность выполненных работ.",
    phone: "+373 68 946 666",
    address: "г. Кишинёв, Рышкановка, ул. Петрикань 21",
    regiune: ["Кишинёв"],
    sector: ["Рышкановка"],
    mapAddress: "https://maps.app.goo.gl/6b9gQTj1HoagyyMs7",
    views: 1462,
    orar: "Пн-Пт: 09:00 — 18:00, Сб: 09:00 — 14:00",
    gallery: [
      complex_img1,
      complex_img2,
      complex_img3,
      complex_img4,
      complex_img5,
    ],

    iframeSrc:
      "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d961.1039627085811!2d28.817781729580087!3d47.05175769035963!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x40c97d9f1fcf3851%3A0x63e353bbd8071d7d!2zU3RyYWRhIFBldHJpY2FuaSAyMSwgTUQtMjA2OCwgQ2hpyJlpbsSDdSwg0JzQvtC70LTQvtCy0LA!5e0!3m2!1sru!2s!4v1725964755502!5m2!1sru!2s",
    icons: [
      {
        url: ico1,
        available: true,
      },
      {
        url: ico2,
        available: true,
      },
      {
        url: ico3,
        available: true,
      },
      {
        url: ico4,
        available: true,
      },
      {
        url: ico5,
        available: false,
      },
      {
        url: ico6,
        available: true,
      },
    ],
    serviceTypes: [
      {
        title: "Ходовая часть",
        icon: suspension_ico,
        services: {
          service1: "Диагностика/проверка",
          service2: "Замена шаровой, тяг, втулок",
          service3: "Замена сайлентблоков",
          service4: "Развал/схождение",
          service5: "Замена подшипников колёс",
          service6: "Замена тормозных дисков и колодок",
          service7: "Замена привода",
          service8: "Замена шарниров/гранаты",
        },
      },
      {
        title: "Коробка передач/Трансмиссия",
        icon: car_manual_gearbox_ico,
        services: {
          service1: "Ремонт коробки передач (механическая)",
          service2: "Диагностика механической коробки передачь (МКПП)",
          service3: "Замена сцепления (МКПП)",
          service4: "Регулировка сцепления (МКПП)",
        },
      },
      {
        title: "Диагностика",
        icon: diagnostic_ico,
        services: {
          service1: "Диагностика двигателя",
          service2: "Диагностика ходовой части",
          service3: "Диагностика тормозных систем",
        },
      },
      {
        title: "Рулевая рейка",
        icon: volan_ico,
        services: {
          service1: "Диагностика рулевой рейки",
          service2: "Замена рулевой рейки",
          service3: "Ремонт рулевой рейки",
        },
      },
      {
        title: "Амортизаторы/подвеска",
        icon: absorber_ico,
        services: {
          service1: "Замена амортизаторов",
          service2: "Замена пружин",
        },
      },
      {
        title: "Диски/колёса",
        icon: wheel_ico,
        services: {
          service1: "Замена шин (переобувка)",
          service2: "Балансировка колёс",
        },
      },
      {
        title: "Двигатель (Мотор)",
        icon: engine_ico,
        services: {
          service1: "Диагностика двигателя",
          service2: "Капитальный ремонт двигателя (мотора)",
          service3: "Проверка свечей зажигания",
          service4: "Диагностика системы зажигания",
          service5: "Замена свечей зажигания",
          service6: "Замена двигателя",
          service7: "Замена цепи/ремня ГРМ",
          service8: "Диагностика и ремонт системы охлаждения",
          service9: "Регулировка клапанов",
          service10: "Замена прокладки ГБЦ",
          service11: "Замена прокладки картера",
        },
      },
      {
        title: "Кондиционер",
        icon: ac_ico,
        services: {
          service1: "Заправка кондиционера (фреон)",
          service2: "Замена радиатора",
          service3: "Замена и ремонт вентилятора салона",
          service4: "Промывка радиатора печки",
          service5: "Ремонт, замена сервопривода заслонок",
          service6: "Замена, ремонт испарителя, ТРВ системы кондиционера",
          service7:
            "Ремонт, замена заслонок, короба печки, дренажного отверстия",
          service8: "Ремонт моторчика",
        },
      },
      {
        title: "Компьютерная диагностика",
        icon: PC_diagnostics_ico,
        services: {
          service1: "Полная диагностика электронных систем автомобиля",
          service2: "Удаление ошибок",
        },
      },
      {
        title: "Фильтра",
        icon: car_filter_ico,
        services: {
          service1: "Замена топливного фильтра",
          service2: "Замена воздушного фильтра",
          service3: "Замена фильтра салона",
          service4: "Замена масляного фильтра",
          service5: "Замена фильтра коробки передач",
        },
      },
      {
        title: "Масла и жидкости",
        icon: oil_change_ico,
        services: {
          service1: "Замена масла в двигателе",
          service2: "Замена масла в МКПП",
          service3: "Замена масла в АКПП",
          service4: "Замена жидкости гидроусилителя руля",
          service5: "Замена тосол/антифриз",
          service6: "Замена тормозной жидкости",
        },
      },
    ],
  },
  {
    id: 10,
    url: "SST-Chiptuning",
    name: "SST-Chiptuning — чип-тюнинг и удаление EGR ",
    image: sst_preview,
    bigImage: sst_big,
    description:
      "SST-CHIPTUNING —  специализированная тюнинг-мастерская в Молдове для перенастройки и кастомизации автомобилей. Основное внимание мы уделяем чип-тюнингу и модификации автомобилей. Мы также предлагаем решения для отключения различных систем двигателя и решения проблем, связанных с экологией, таких как DPF (дизельный сажевый фильтр), EGR, AdBlue и аналогичные проблемы. В нашей студии мы предлагаем файловый сервис для профессионалов и сервисных центров, где мы модифицируем файлы в соответствии с вашими потребностями. Мы специализируемся на профессиональных решениях для достижения наилучших результатов. Наши услуги предназначены для взыскательных клиентов, которые ценят свои автомобили и стремятся к оптимальной производительности. Все наши работы выполняются на оригинальном и дорогом оборудовании, гарантирующем безопасность и стабильность.",
    phone: "+373 60 705 050",
    address: "г. Кишинёв, Рышкановка, ул. Дойна 189",
    regiune: ["Кишинёв"],
    sector: ["Рышкановка"],
    mapAddress: "https://maps.app.goo.gl/g5xXfgTwgKq6JV5J7",
    views: 1462,
    orar: "Пн-Пт: 08:00 — 18:00, Сб: 08:00 — 15:00",
    gallery: [sst_img1, sst_img2, sst_img3, sst_img4, sst_img5],
    iframeSrc:
      "https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d3843.7735174838863!2d28.83485292678854!3d47.06066795455975!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x40c97db8df0443df%3A0x9f247feeec426036!2sSST-CHIPTUNING%20%26%20Autoservice!5e0!3m2!1sru!2sus!4v1722012759000!5m2!1sru!2sus",
    icons: [
      {
        url: ico1,
        available: true,
      },
      {
        url: ico2,
        available: true,
      },
      {
        url: ico3,
        available: true,
      },
      {
        url: ico4,
        available: true,
      },
      {
        url: ico5,
        available: false,
      },
      {
        url: ico6,
        available: true,
      },
    ],
    serviceTypes: [
      {
        title: "EGR и сажевый фильтр",
        icon: catalyst_ico,
        services: {
          service1: "Диагностика клапана EGR",
          service2: "Компьютерное отключение клапана EGR",
          service3: "Удаление/вырезание сажевого фильтра",
        },
      },
      {
        title: "Чип-тюнинг",
        icon: chip_tuning_ico,
        services: {
          service1: "Чип-тюнинг ",
        },
      },
    ],
  },
  {
    id: 11,
    url: "starter-plus",
    name: "Starter plus — Ремонт стартеров и генераторов",
    image: starterp_preview,
    bigImage: starterp_big,
    description:
      "Наша фирма является одной из первых фирм в Молдове специализирующихся на ремонте стартеров и генераторов легковых и грузовых автомобилей. Благодаря многолетнему опыту работы, мы с уверенностью можем гарантировать Вам качество и профессионализм предоставляемых нами услуг! Доверяйте свой автомобиль только профессионалам!",
    phone: "+373 79 307 708",
    address: "г. Кишинёв, Ботаника, ул.Заводская 8/1",
    regiune: ["Кишинёв"],
    sector: ["Ботаника"],
    mapAddress: "https://maps.app.goo.gl/N21G9Jky8gLG1beg6",
    views: 1462,
    orar: "Пн-Пт: 08:00 — 18:00, Сб: 08:00 — 15:00",
    gallery: [
      starterp_img1,
      starterp_img2,
      starterp_img3,
      starterp_img4,
      starterp_img5,
    ],
    iframeSrc:
      "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d1360.2263084389472!2d28.87217893894353!3d47.01171999278539!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x40c97c0930d302c9%3A0x7aa475426a8ec60c!2sStarter%20Plus%20SRL!5e0!3m2!1sen!2s!4v1722015185460!5m2!1sen!2s",
    icons: [
      {
        url: ico1,
        available: true,
      },
      {
        url: ico2,
        available: true,
      },
      {
        url: ico3,
        available: true,
      },
      {
        url: ico4,
        available: true,
      },
      {
        url: ico5,
        available: false,
      },
      {
        url: ico6,
        available: true,
      },
    ],
    serviceTypes: [
      {
        title: "Автоэлектрика",
        icon: energy_ico,
        services: {
          service1: "Диагностика проводки",
          service2: "Ремонт проводки",
          service3: "Замена предохранителей и реле",
          service4: "Проверка и замена аккумулятора",
          service5: "Диагностика генератора",
          service6: "Ремонт генератора",
          service7: "Замена генератора",
          service8: "Диагностика стартера",
          service9: "Ремонт стартера",
          service10: "Замена стартера",
          service11:
            "Установка и настройка доп. оборудования (камера, android auto)",
        },
      },
      {
        title: "Компьютерная диагностика",
        icon: PC_diagnostics_ico,
        services: {
          service1: "Диагностика электронных систем автомобиля",
          service2: "Удаление ошибок",
        },
      },
    ],
  },
  {
    id: 12,
    url: "mireco-service",
    name: "Mireco Service — двигатель, ходовая, электрика",
    image: mireco_preview,
    bigImage: mireco_big,
    description:
      "Mireco предлагает широкий спектр услуг по техническому обслуживанию и ремонту автомобилей всех известных марок.",
    phone: "+373 61 183 183",
    address: "г. Кишинёв, Центр, ул. Колумна 170",
    regiune: ["Кишинёв"],
    sector: ["Центр"],
    mapAddress: "https://maps.app.goo.gl/zxVvfncStsnxuwEQ9",
    views: 1462,
    orar: "Пн-Сб: 08:00 — 18:00",
    gallery: [mireco_img1, mireco_img2, mireco_img3, mireco_img4, mireco_img5],
    iframeSrc:
      "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d1359.5584315322353!2d28.8199121!3d47.0379376!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x40c97d001193326b%3A0xbecaafe7577c297c!2sMireco%20Auto-Service%2FVulcanizare!5e0!3m2!1sen!2s!4v1722053883140!5m2!1sen!2s",
    icons: [
      {
        url: ico1,
        available: true,
      },
      {
        url: ico2,
        available: true,
      },
      {
        url: ico3,
        available: true,
      },
      {
        url: ico4,
        available: true,
      },
      {
        url: ico5,
        available: true,
      },
      {
        url: ico6,
        available: true,
      },
    ],
    serviceTypes: [
      {
        title: "Двигатель (Мотор)",
        icon: engine_ico,
        services: {
          service1: "Диагностика двигателя",
          service2: "Капитальный ремонт двигателя (мотора)",
          service3: "Проверка свечей зажигания",
          service4: "Диагностика системы зажигания",
          service5: "Замена свечей зажигания",
          service6: "Замена двигателя",
          service7: "Замена цепи/ремня ГРМ",
          service8: "Диагностика и ремонт системы охлаждения",
          service9: "Регулировка клапанов",
          service10: "Замена прокладки ГБЦ",
          service11: "Замена прокладки картера",
          service11: "Раскоксовка и промывка мотора",
        },
      },
      {
        title: "Автоэлектрика",
        icon: energy_ico,
        services: {
          service1: "Диагностика проводки",
          service2: "Ремонт проводки",
          service3: "Замена предохранителей и реле",
          service4: "Проверка и замена аккумулятора",
          service5: "Диагностика генератора",
          service6:
            "Установка и настройка доп. оборудования (камера, android auto)",
          service7: "Замена генератора",
          service8: "Диагностика стартера",
          service9: "Замена стартера",
        },
      },
      {
        title: "Ходовая часть",
        icon: suspension_ico,
        services: {
          service1: "Диагностика/проверка",
          service2: "Замена шаровой, тяг, втулок",
          service3: "Замена сайлентблоков",
          service4: "Развал/схождение",
          service5: "Замена подшипников колёс",
          service6: "Замена тормозных дисков и колодок",
          service7: "Замена привода",
          service8: "Замена шарниров/гранаты",
        },
      },
      {
        title: "Коробка передач/Трансмиссия",
        icon: car_manual_gearbox_ico,
        services: {
          service1: "Ремонт коробки передач (механическая)",
          service2: "Диагностика механической коробки передачь (МКПП)",
        },
      },
      {
        title: "Диагностика",
        icon: diagnostic_ico,
        services: {
          service1: "Диагностика двигателя",
          service2: "Диагностика ходовой части",
          service3: "Диагностика тормозных систем",
        },
      },
      {
        title: "Рулевая рейка",
        icon: volan_ico,
        services: {
          service1: "Диагностика рулевой рейки",
          service2: "Замена рулевой рейки",
          service3: "Ремонт рулевой рейки",
        },
      },
      {
        title: "Фильтра",
        icon: car_filter_ico,
        services: {
          service1: "Замена топливного фильтра",
          service2: "Замена воздушного фильтра",
          service3: "Замена фильтра салона",
          service4: "Замена масляного фильтра",
        },
      },
      {
        title: "Компьютерная диагностика",
        icon: PC_diagnostics_ico,
        services: {
          service1: "Диагностика электронных систем автомобиля",
          service2: "Удаление ошибок",
        },
      },
      {
        title: "Амортизаторы/подвеска",
        icon: absorber_ico,
        services: {
          service5: "Замена амортизаторов",
        },
      },
    ],
  },
  {
    id: 13,
    url: "eco-tuning",
    name: "Eco Tuning — Отключение сажевого фильтра, Adblue",
    image: ecot_preview,
    bigImage: ecot_big,
    description:
      "Предоставляем услуги по отключению и удалению сажевого фильтра, EGR, Adblue а также проводи качественный чип тюнинг вашего авто,  с увеличением его мощности 20-30%.",
    phone: "+373 78 092 869",
    address: "г. Кишинёв, Аэропорт, ул. Дачия 65",
    regiune: ["Кишинёв"],
    sector: ["Аэропорт"],
    mapAddress: "https://maps.app.goo.gl/gHQCBaMi8uFMxhjM8",
    views: 1462,
    orar: "Пн-Пт: 09:00 — 18:00",
    gallery: [ecot_img1, ecot_img2, ecot_img3, ecot_img4, ecot_img5],
    iframeSrc:
      "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d680.9467846790748!2d28.922636686010943!3d46.94622255198563!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x40c9790bbacb48eb%3A0x5c1e41e4321b16ff!2sDacia%20Blvd%2065%2C%20MD-2026%2C%20Chi%C8%99in%C4%83u%2C%20Moldova!5e0!3m2!1sen!2s!4v1722057163728!5m2!1sen!2s",
    icons: [
      {
        url: ico1,
        available: true,
      },
      {
        url: ico2,
        available: true,
      },
      {
        url: ico3,
        available: true,
      },
      {
        url: ico4,
        available: true,
      },
      {
        url: ico5,
        available: false,
      },
      {
        url: ico6,
        available: true,
      },
    ],
    serviceTypes: [
      {
        title: "EGR и сажевый фильтр",
        icon: catalyst_ico,
        services: {
          service1: "Диагностика клапана EGR",
          service2: "Ремонт клапана EGR",
          service3: "Отключение клапана EGR",
          service4: "Компьютерное отключение клапана EGR",
          service5: "Удаление (вырезание) сажевого фильтра",
          service6: "Отключение Adblue",
        },
      },
      {
        title: "Компьютерная диагностика",
        icon: PC_diagnostics_ico,
        services: {
          service1: "Диагностика электронных систем автомобиля",
          service2: "Удаление ошибок",
        },
      },
    ],
  },
  {
    id: 14,
    url: "tonirovka",
    name: "Tonirovka.md - Тонирование стекол, химчистка салона",
    image: tonirovka_preview,
    bigImage: tonirovka_big,
    description:
      "Комплексные услуги по детейлингу и уходу за вашим авто: химчистка салона, оклейка фар, перетяжка потолка, заправка кондиционера, сухой туман, полировка фар.",
    phone: "+373 60 196 199",
    address: "г. Кишинёв, Скулянка, ул. Милано 19/1 (Box 260)",
    regiune: ["Кишинёв"],
    sector: ["Скулянка"],
    mapAddress: "https://maps.app.goo.gl/KeWhrpP87y8sisVn6",
    views: 1462,
    orar: "Пн-Пн: 09:00 — 17:00, Сб: 09:00 — 14:00",
    gallery: [
      tonirovka_img1,
      tonirovka_img2,
      tonirovka_img3,
      tonirovka_img4,
      tonirovka_img5,
    ],
    iframeSrc:
      "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d961.3211161740801!2d28.790349193307353!3d47.039706475881545!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x40c97df2bd0fc545%3A0x8a40e96f98cfdd60!2s1%2C%20Strada%20Milano%2019%2C%20MD-2069%2C%20Chi%C8%99in%C4%83u%2C%20Moldova!5e0!3m2!1sen!2s!4v1725686769086!5m2!1sen!2s",
    icons: [
      {
        url: ico1,
        available: true,
      },
      {
        url: ico2,
        available: false,
      },
      {
        url: ico3,
        available: true,
      },
      {
        url: ico4,
        available: true,
      },
      {
        url: ico5,
        available: false,
      },
      {
        url: ico6,
        available: false,
      },
    ],
    serviceTypes: [
      {
        title: "Детейлинг/Полировка",
        icon: detailing_ico,
        services: {
          service1: "Мойка и обезжиривание кузова",
          service2: "Оклейка фар защитной пленкой",
          service3: "Полировка оптики (фар)",
          service4: "Химчистка салона",
          service5: "Озонирование салона",
          service6: "Тонировка",
        },
      },
      {
        title: "Восстановление салона/Обшивка",
        icon: salon_ico,
        services: {
          service1: "Восстановление потолка (обшивка потолка)",
        },
      },
      {
        title: "Кондиционер",
        icon: ac_ico,
        services: {
          service1: "Заправка кондиционера (заправка фреоном)",
        },
      },
    ],
  },
  {
    id: 15,
    url: "bamper-md",
    name: "Bamper.md — Ремонт бамперов и пластиковых деталей",
    image: bampermd_preview,
    bigImage: bampermd_big,
    description:
      "Выполняем все виды сварки пластика, ремонт и восстановление любых пластиковых автодеталей, а также ремонт деталей из стеклопластика. Ремонт и сварка пластиковых материалов осуществляется с использованием соответствующих материалов и специального оборудования с гарантией. Оцениваем ремонт по фотографиям в Viber/WhatsApp, бесплатная консультация, пытные мастера. Все виды сварки пластика, восстанавливаем любые пластиковые детали!",
    phone: "+373 78 784 808",
    address: "г. Кишинёв, Рышкановка, ул. Петру Рареш 62",
    regiune: ["Кишинёв"],
    sector: ["Рышкановка"],
    mapAddress: "https://maps.app.goo.gl/AQKvroV3fAbZWNVe7",
    views: 1462,
    orar: "Пн-Вс: 08:00 — 20:00",
    gallery: [
      bampermd_img1,
      bampermd_img2,
      bampermd_img3,
      bampermd_img4,
      bampermd_img5,
    ],
    iframeSrc:
      "https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d285.81142520127275!2d28.841806572839012!3d47.03803610693516!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x40c97c52ad58e8d7%3A0xa8ef534de5fd6f7b!2sStrada%20Petru%20Rare%C8%99%2062%2C%20MD-2005%2C%20Chi%C8%99in%C4%83u%2C%20Moldova!5e0!3m2!1sen!2s!4v1722543813775!5m2!1sen!2s",
    icons: [
      {
        url: ico1,
        available: true,
      },
      {
        url: ico2,
        available: true,
      },
      {
        url: ico3,
        available: true,
      },
      {
        url: ico4,
        available: false,
      },
      {
        url: ico5,
        available: false,
      },
      {
        url: ico6,
        available: true,
      },
    ],
    serviceTypes: [
      {
        title: "Пластик",
        icon: bumper_ico,
        services: {
          service1: "Ремонт бампера",
          service2: "Сварка пластика",
          service3: "Ремонт крыла",
          service4: "Ремонт вмятин",
          service5: "Ремонт креплений",
          service6: "Ремонт пластик зеркала",
          service7: "Сварка расширительных бачков",
          service8: "Ремонт решеток",
          service9: "Ремонт деталей из стекловолокна",
        },
      },
      {
        title: "Фары/Оптика",
        icon: far_ico,
        services: {
          service1: "Замена лампочек",
          service2: "Полировка фар",
          service3: "Ремонт фар",
          service4: "Замена фар",
        },
      },
      {
        title: "Покраска",
        icon: paint_ico,
        services: {
          service1: "Покраска бампера",
        },
      },
    ],
  },
  {
    id: 16,
    url: "old-style",
    name: "Old Style — Полировка фар и кузова",
    image: olds_preview,
    bigImage: olds_big,
    description:
      "Если у вас ухудшилась видимость при езде в темное время суток или ваш автомобиль уже не выглядит так эстетично, как раньше, ждём вас на процедуру обновления фар.",
    phone: "+373 69 880 770",
    address: "г. Кишинёв, Дурлешты, ул. Кимиштилор 17",
    regiune: ["Кишинёв"],
    sector: ["Буюканы"],
    mapAddress: "https://maps.app.goo.gl/9NjYKCx7BotUj6dVA",
    views: 1462,
    orar: "Пн-Пт: 10:00 — 19:00",
    gallery: [olds_img1, olds_img2, olds_img3, olds_img4, olds_img5],
    iframeSrc:
      "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d1359.8624754801483!2d28.773708388360575!3d47.0260036955912!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x40c97dfd4027c90d%3A0x27145a3ce05e6bd7!2sStrada%20Chimi%C8%99tilor%2017%2C%20Durle%C5%9Fti!5e0!3m2!1sen!2s!4v1722578261428!5m2!1sen!2s",
    icons: [
      {
        url: ico1,
        available: true,
      },
      {
        url: ico2,
        available: true,
      },
      {
        url: ico3,
        available: true,
      },
      {
        url: ico4,
        available: false,
      },
      {
        url: ico5,
        available: false,
      },
      {
        url: ico6,
        available: false,
      },
    ],
    serviceTypes: [
      {
        title: "Детейлинг/Полировка",
        icon: detailing_ico,
        services: {
          service1: "Полировка кузова",
          service2: "Оклейка кузова защитной пленкой",
          service3: "Оклейка кузова виниловой пленкой",
          service4: "Оклейка фар защитной пленкой",
          service5: "Покрытие кузова нанокерамикой",
          service6: "Покрытие кузова воском",
          service7: "Полировка оптики (фар)",
          service8: "Химчистка салона",
          service9: "Озонирование салона",
        },
      },
    ],
  },
  {
    id: 17,
    url: "detailing-lux-18",
    name: "Detailing Lux 18 — Покраска, полировка авто",
    image: detl_preview,
    bigImage: detl_big,
    description:
      "Определяющим аспектом успеха покраски является предварительная покрасочная стадия, на которой подготавливается окрашиваемая поверхность. Качество и тип используемой краски являются основным условием получения краски, устойчивой с течением времени и выдерживающей весь процесс ее нанесения. Для получения более подробной информации и регистрации, не стесняйтесь обращаться к нам. Мы тепло приветствуем вас. Полировка выполняется с помощью профессионального инструмента и с применением специальных абразивных материалов и полировочных паст.  Мы ждём вас без исключения, у нас вкусный кофе и у вас хорошее настроение!",
    phone: "+373 78 856 836",
    address: "г. Кишинёв, Ботаника, ул. Узинелор 245/2",
    regiune: ["Кишинёв"],
    sector: ["Ботаника"],
    mapAddress: "https://maps.app.goo.gl/VAcR4LBfR6hCLKen7",
    views: 1462,
    orar: "Пн-Пт: 10:00 — 19:00",
    gallery: [detl_img1, detl_img2, detl_img3, detl_img4, detl_img5],
    iframeSrc:
      "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d1360.6960220776307!2d28.911720915151584!3d46.99327461453499!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x40c97becc21edbcb%3A0xb0459eebba3054c5!2sDetailing%20Service%20Studio!5e0!3m2!1sru!2s!4v1722606071208!5m2!1sru!2s",
    icons: [
      {
        url: ico1,
        available: true,
      },
      {
        url: ico2,
        available: true,
      },
      {
        url: ico3,
        available: true,
      },
      {
        url: ico4,
        available: false,
      },
      {
        url: ico5,
        available: false,
      },
      {
        url: ico6,
        available: false,
      },
    ],
    serviceTypes: [
      {
        title: "Детейлинг/Полировка",
        icon: detailing_ico,
        services: {
          service1: "Полировка кузова",
          service2: "Оклейка кузова защитной пленкой",
          service3: "Оклейка кузова виниловой пленкой",
          service4: "Оклейка фар защитной пленкой",
          service5: "Покрытие кузова нанокерамикой",
          service6: "Покрытие кузова воском",
          service7: "Полировка оптики (фар)",
          service8: "Химчистка салона",
          service9: "Озонирование салона",
        },
      },
      {
        title: "Диски/колёса",
        icon: wheel_ico,
        services: {
          service1: "Ремонт дисков (сварка)",
          service2: "Замена колёс",
          service3: "Выравнивание дисков (рихтовка)",
        },
      },
      {
        title: "Восстановление салона/Обшивка",
        icon: salon_ico,
        services: {
          service1: "Восстановление руля (обшивка руля)",
          service2: "Восстановление салона (обшивка салона)",
          service3: "Восстановление потолка (обшивка потолка)",
        },
      },
      {
        title: "Покраска",
        icon: paint_ico,
        services: {
          service1: "Покраска кузова",
          service2: "Покраска бампера",
          service3: "Покраска колёсных дисков",
        },
      },
    ],
  },
  {
    id: 18,
    url: "detail-garage",
    name: "Detail Garage — Покраска авто и дисков",
    image: detg_preview,
    bigImage: detg_big,
    description:
      "Наш детейл-сервис предоставляет вам такую услугу, как перешив салона авто, полировка авто, удаление вмятин без покраски и др. Шумоизоляция автомобиля — самый лучший способ сделать из машины — более премиальную и получить максимальный комфорт от вождения.",
    phone: "+373 78 887 788",
    address: "г. Кишинёв, Ботаника, ул. Узинелор 210/2",
    regiune: ["Кишинёв"],
    sector: ["Ботаника"],
    mapAddress: "https://maps.app.goo.gl/pd7sfXrEmS9nYAFd7",
    views: 1462,
    orar: "Пн-Пт: 09:00 — 18:00",
    gallery: [detg_img1, detg_img2, detg_img3, detg_img4, detg_img5],
    iframeSrc:
      "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d340.1903937835918!2d28.912810443835223!3d46.9906999441576!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x40c97b2e0cef32f5%3A0x7a02888ecf642536!2sgaraj!5e0!3m2!1sen!2s!4v1722575003113!5m2!1sen!2s",
    icons: [
      {
        url: ico1,
        available: true,
      },
      {
        url: ico2,
        available: true,
      },
      {
        url: ico3,
        available: true,
      },
      {
        url: ico4,
        available: false,
      },
      {
        url: ico5,
        available: false,
      },
      {
        url: ico6,
        available: false,
      },
    ],
    serviceTypes: [
      {
        title: "Детейлинг/Полировка",
        icon: detailing_ico,
        services: {
          service1: "Полировка кузова",
          service2: "Оклейка кузова защитной пленкой",
          service3: "Оклейка кузова виниловой пленкой",
          service4: "Оклейка фар защитной пленкой",
          service5: "Покрытие кузова нанокерамикой",
          service6: "Очистка и полировка дисков",
          service7: "Полировка оптики (фар)",
          service8: "Химчистка салона",
          service9: "Чистка (мойка) мотора",
          service10: "Тонировка",
          service11: "Выравнивание вмятин без покраски",
        },
      },
      {
        title: "Диски/колёса",
        icon: wheel_ico,
        services: {
          service1: "Ремонт дисков (сварка)",
          service2: "Замена колёс",
          service3: "Выравнивание дисков (рихтовка)",
        },
      },
      {
        title: "Восстановление салона/Обшивка",
        icon: salon_ico,
        services: {
          service1: "Восстановление руля (обшивка руля)",
          service2: "Восстановление салона (обшивка салона)",
          service3: "Восстановление потолка (обшивка потолка)",
        },
      },
      {
        title: "Шумоизоляция",
        icon: noiseoff_ico,
        services: {
          service1: "Шумоизоляция кузова",
        },
      },
    ],
  },
  {
    id: 19,
    url: "bizon",
    name: "Bizon — Полировка фар и лобовых стёкл",
    image: bizon_preview,
    bigImage: bizon_big,
    description:
      "Мы одни из немногих на рынке Молдовы представляем по настоящему профессиональную полировку стекол. Даже не смотря на 10 летний опыт в данной сфере мы постоянно совершенствуемся. Посещаем учебные семинары. Возможно у нас не самые низкие цены но зато мы отвечаем за качество, а это значит за вашу безопастность! Дальше выбор только за ВАМИ экономия денег или безопастности!",
    phone: "+373 60 372 742",
    address: "г. Кишинёв, Ботаника, ул. Мунчешть 81",
    regiune: ["Кишинёв"],
    sector: ["Ботаника"],
    mapAddress: "https://maps.app.goo.gl/inf5NuyMUzJbUFc89",
    views: 1462,
    orar: "Пн-Сб: 09:00 — 18:00",
    gallery: [bizon_img1, bizon_img2, bizon_img3, bizon_img4, bizon_img5],
    iframeSrc:
      "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d572.0051828831514!2d28.87002268684609!3d47.002336938052295!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x40c97c00c723fcd9%3A0xba32e067d2760164!2zyJhvc2VhdWEgTXVuY2XImXRpIDgxLCBDaGnImWluxIN1LCBNb2xkb3Zh!5e0!3m2!1sen!2s!4v1722575653086!5m2!1sen!2s",
    icons: [
      {
        url: ico1,
        available: true,
      },
      {
        url: ico2,
        available: true,
      },
      {
        url: ico3,
        available: true,
      },
      {
        url: ico4,
        available: false,
      },
      {
        url: ico5,
        available: false,
      },
      {
        url: ico6,
        available: true,
      },
    ],
    serviceTypes: [
      {
        title: "Детейлинг/Полировка",
        icon: detailing_ico,
        services: {
          service1: "Полировка оптики (фар)",
          service2: "Полировка автостёкл (лобового стекла)",
        },
      },
    ],
  },
  {
    id: 20,
    url: "reikamd",
    name: "Reika.md — Ремонт рулевых реек всех марок автомобилей",
    image: reikamd_preview,
    bigImage: reikamd_big,
    description:
      "Производим восстановительный ремонт рулевых реек всех марок автомобилей. Переборка рулевой рейки - более выгодная альтернатива покупке новой, однако не уступающая ей по качеству, в процессе переборки возможна также замена некоторых элементов на усиленные, более подходящие для наших условий эксплуатации. Мы осуществляем ремонт рулевых реек всех марок автомобилей по вашему желанию мы можем проверить насос гур, шланги гур, произвести снятие ,замену или установку рулевой рейки на Вашем автомобиле обменять неисправную рулевую рейку на восстановленную или продать ее вам. Ремонт рулевой рейки производится на специализированном оборудовании. Используются только оригинальные комплектующие фирм производителей: ZF ; TRW ; Koyo ; Smi ; NSK ; Mando и др. Если у Вас вышла из строя рулевая рейка ,то не обязательно приобретать новую (порой цена нового узла превосходит самые смелые ожидания). Ваша рулевая рейка подлежит восстановлению и она послужит Вам еще не один год!",
    phone: "+373 78 335 335",
    address: "г. Кишинёв, Рышкановка, ул. Буковиней 9",
    regiune: ["Кишинёв"],
    sector: ["Рышкановка"],
    mapAddress: "https://maps.app.goo.gl/1kgWsfG7FBQmwZAQ7",
    views: 1462,
    orar: "Пн-Пт: 09:00 — 17:00, Сб: 09:00 — 13:00",
    gallery: [
      reikamd_img1,
      reikamd_img2,
      reikamd_img3,
      reikamd_img4,
      reikamd_img5,
    ],
    iframeSrc:
      "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d1358.7736198912671!2d28.870053585665588!3d47.068730126391266!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x40c97da41ce45305%3A0x5ed4a5d2ed0419b5!2sREIKA%20AUTOSERVICE!5e0!3m2!1sru!2s!4v1722946885324!5m2!1sru!2s",
    icons: [
      {
        url: ico1,
        available: true,
      },
      {
        url: ico2,
        available: true,
      },
      {
        url: ico3,
        available: true,
      },
      {
        url: ico4,
        available: false,
      },
      {
        url: ico5,
        available: false,
      },
      {
        url: ico6,
        available: false,
      },
    ],
    serviceTypes: [
      {
        title: "Рулевая рейка",
        icon: volan_ico,
        services: {
          service1: "Диагностика рулевой рейки",
          service2: "Замена рулевой рейки",
          service3: "Ремонт рулевой рейки",
        },
      },
      {
        title: "Ходовая часть",
        icon: suspension_ico,
        services: {
          service1: "Диагностика/проверка ходовой",
          service2: "Развал/схождение",
        },
      },
    ],
  },
  {
    id: 21,
    url: "esapament-service",
    name: "Esapament Service — Ремонт глушителя",
    image: esapserv_preview,
    bigImage: esapserv_big,
    description:
      "Esapament Service, имея опыт работы более 20 лет и являясь первой специализированной мастерской в ​​Молдове, поможет вам решить любую проблему, связанную с выхлопными системами, катализаторами, сажевыми фильтрами и многим другим... На протяжении всей своей деятельности Esapament Service преследовала несколько целей, и принципов: поддерживать высочайший уровень качества, не оставлять ни одного клиента разочарованным и не иметь проблем, которые они не могли бы решить.",
    phone: "+373 67 440 371",
    address: "г. Кишинёв, Ботаника, ул. Фынтынилор 21",
    regiune: ["Кишинёв"],
    sector: ["Ботаника"],
    mapAddress: "https://maps.app.goo.gl/LKCdrnSFeEkhgtLF8",
    views: 1462,
    orar: "Пн-Пт: 09:00 — 17.00",
    gallery: [
      esapserv_img1,
      esapserv_img2,
      esapserv_img3,
      esapserv_img4,
      esapserv_img5,
    ],
    iframeSrc:
      "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d680.1500069336213!2d28.856749851829246!3d47.00882599017623!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x40c97c1ba6c7560f%3A0x130f267a45d2085a!2zU3RyLiBGw6JudMOibmlsb3IgMjEsIENoaciZaW7Eg3UsIE1vbGRvdmE!5e0!3m2!1sen!2s!4v1723396608688!5m2!1sen!2s",
    icons: [
      {
        url: ico1,
        available: true,
      },
      {
        url: ico2,
        available: true,
      },
      {
        url: ico3,
        available: true,
      },
      {
        url: ico4,
        available: false,
      },
      {
        url: ico5,
        available: false,
      },
      {
        url: ico6,
        available: false,
      },
    ],
    serviceTypes: [
      {
        title: "Глушитель",
        icon: ex_pipe_ico,
        services: {
          service1: "Ремонт глушителя",
          service2: "Сварка глушителя",
          service3: "Замена глушителя",
          service4: "Диагностика глушителя",
          service5: "Тюнинг глушителя (спортивный глушитель)",
        },
      },
    ],
  },
  {
    id: 22,
    url: "turbo-profi",
    name: "Ремонт турбин — Turbo-profi",
    image: turbopro_preview,
    bigImage: turbopro_big,
    description:
      "Принимаем турбины из других городов, ремонтируем и отправляем их владельцу. Предлагаем запчасти на все виды турбин. Ремонт любых видов турбин спецтехника. Кратчайшие сроки. Низкие цены, гарантия качества.",
    phone: "+373 78 571 702",
    address: "г. Кишинёв, Рышкановка, ул. Петрикань 17/5",
    regiune: ["Кишинёв"],
    sector: ["Рышкановка"],
    mapAddress: "https://maps.app.goo.gl/MMuXXM6aq78pmUuc9",
    views: 1462,
    orar: "Пн-Пт: 09:00 — 18:00, Сб: 09:00 — 14:00",
    gallery: [
      turbopro_img1,
      turbopro_img2,
      turbopro_img3,
      turbopro_img4,
      turbopro_img5,
    ],
    iframeSrc:
      "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d1359.3988205713763!2d28.83189352275421!3d47.04420149791443!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x40c97daf6869a6fb%3A0x73c5d3823d48f25e!2sPetricani%20St%2017%2C%20Chi%C8%99in%C4%83u%2C%20Moldova!5e0!3m2!1sen!2s!4v1723781414820!5m2!1sen!2s",
    icons: [
      {
        url: ico1,
        available: true,
      },
      {
        url: ico2,
        available: true,
      },
      {
        url: ico3,
        available: true,
      },
      {
        url: ico4,
        available: false,
      },
      {
        url: ico5,
        available: false,
      },
      {
        url: ico6,
        available: false,
      },
    ],
    serviceTypes: [
      {
        title: "Турбина/Турбокомпрессор",
        icon: turbine_ico,
        services: {
          service1: "Диагностика турбины",
          service2: "Проверка и регулировка давления наддува",
          service3: "Ремонт турбины",
          service4: "Замена турбины",
          service5: "Проверка трубок системы наддува",
          service6: "Настройка геометрии турбины",
        },
      },
    ],
  },
  {
    id: 23,
    url: "bus-glass",
    name: "Автостекла - врезка и установка",
    image: busglass_preview,
    bigImage: busglass_big,
    description:
      "Автостекла для легковых авто и микроавтобусов, лобовые, задние, боковые.",
    phone: "+373 68 623 085",
    address: "г. Кишинёв, Ботаника, ул. Сармисежетуса 12",
    regiune: ["Кишинёв"],
    sector: ["Ботаника"],
    mapAddress: "https://maps.app.goo.gl/xQasEUjUbXjDM4or6",
    views: 1462,
    orar: "Пн-Пт: 09:00 — 18:00",
    gallery: [
      busglass_img1,
      busglass_img2,
      busglass_img3,
      busglass_img4,
      busglass_img5,
    ],
    iframeSrc:
      "https://www.google.com/maps/embed?pb=!1m10!1m8!1m3!1d1360.738722232871!2d28.86506451861001!3d46.99159752994075!3m2!1i1024!2i768!4f13.1!5e0!3m2!1sru!2s!4v1725965207015!5m2!1sru!2s",
    icons: [
      {
        url: ico1,
        available: true,
      },
      {
        url: ico2,
        available: true,
      },
      {
        url: ico3,
        available: true,
      },
      {
        url: ico4,
        available: false,
      },
      {
        url: ico5,
        available: false,
      },
      {
        url: ico6,
        available: false,
      },
    ],
    serviceTypes: [
      {
        title: "Автостекла",
        icon: car_wind_ico,
        services: {
          service1: "Замена лобового стекла",
          service2: "Замена автостекол",
          service3: "Вырезка автостекол",
          service4: "Ремонт лобового стекла",
        },
      },
    ],
  },
  {
    id: 24,
    url: "repairpe",
    name: "Ремонт задней балки Peugeot. Ремонт ходовой Peugeot",
    image: repairpeu_preview,
    bigImage: repairpeu_big,
    description:
      "Восстановление задней балки на peugeot 206, Citroen berlingo, Peugeor partner, Xsara, Picasso. Полная реставрация балки до заводского состояния, замена пальцев, подшипников, замена рычагов и торсионов, сайлентблоков рычагов, плавающих сайлентблоков , все запчасти оригинальные, французские, приемлемые цены, звоните договоримся. После ремонта, балка как новая, при этом, сохраняется качество заводской.",
    phone: "+373 79 239 689",
    address: "г. Кишинёв, Ботаника, ул. Салкымилор 5А",
    regiune: ["Кишинёв"],
    sector: ["Ботаника"],
    mapAddress: "https://maps.app.goo.gl/QBU9c3s48GY7vVT87",
    views: 1462,
    orar: "Пн-Пт: 08:00 — 18:00",
    gallery: [
      repairpeu_img1,
      repairpeu_img2,
      repairpeu_img3,
      repairpeu_img4,
      repairpeu_img5,
    ],
    iframeSrc:
      "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d1361.098950475886!2d28.896941432183844!3d46.977447438869895!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x40c9796fc7062057%3A0x2875ca59df760c0!2zU3RyYWRhIFNhbGPDom1pbG9yIDUsIENoaciZaW7Eg3UsINCc0L7Qu9C00L7QstCw!5e0!3m2!1sru!2s!4v1725965408001!5m2!1sru!2s",
    icons: [
      {
        url: ico1,
        available: true,
      },
      {
        url: ico2,
        available: true,
      },
      {
        url: ico3,
        available: true,
      },
      {
        url: ico4,
        available: false,
      },
      {
        url: ico5,
        available: false,
      },
      {
        url: ico6,
        available: false,
      },
    ],
    serviceTypes: [
      {
        title: "Рулевая рейка",
        icon: volan_ico,
        services: {
          service1: "Диагностика рулевой рейки",
          service2: "Замена рулевой рейки",
          service3: "Ремонт рулевой рейки",
        },
      },
      {
        title: "Ходовая часть",
        icon: suspension_ico,
        services: {
          service1: "Диагностика/проверка ходовой",
          service2: "Замена шаровой, тяг, втулок",
          service3: "Замена сайлентблоков",
          service4: "Развал/схождение",
          service5: "Замена подшипников колёс",
          service6: "Замена тормозных дисков и колодок",
          service7: "Замена привода",
          service8: "Замена шарниров/гранаты",
          service9: "Ремонт балки",
          service10: "Замена балки",
        },
      },
      {
        title: "Двигатель (Мотор)",
        icon: engine_ico,
        services: {
          service1: "Замена цепи/ремня ГРМ",
          service2: "Замена помпы",
        },
      },
    ],
  },
  {
    id: 25,
    url: "resormd",
    name: "Resor.md — Ремонт легковых и грузовых автомобилей",
    image: resormd_preview,
    bigImage: resormd_big,
    description:
      "Мы предлагаем лучший сервис по техническому обслуживанию и ремонту легковых и грузовых автомобилей.",
    phone: "+373 60 522 222",
    address: "г. Кишинёв, Чеканы, ул. Милеску Спэтарул 38/1",
    regiune: ["Кишинёв"],
    sector: ["Чеканы"],
    mapAddress: "https://maps.app.goo.gl/9ysGnwUHfRuafUHM8",
    views: 1462,
    orar: "Пн-Пт: 08:00 — 18:00, Сб: 08:00 — 14:00",
    gallery: [
      resormd_img1,
      resormd_img2,
      resormd_img3,
      resormd_img4,
      resormd_img5,
    ],
    iframeSrc:
      "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d12927.065634638155!2d28.89542305562332!3d47.06807322377689!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x40c97d149628de3b%3A0x4dff2a07a2cc40c4!2sRESOR.MD!5e0!3m2!1sen!2s!4v1725511659382!5m2!1sen!2s",
    icons: [
      {
        url: ico1,
        available: true,
      },
      {
        url: ico2,
        available: true,
      },
      {
        url: ico3,
        available: true,
      },
      {
        url: ico4,
        available: false,
      },
      {
        url: ico5,
        available: false,
      },
      {
        url: ico6,
        available: false,
      },
    ],
    serviceTypes: [
      {
        title: "Двигатель (Мотор)",
        icon: engine_ico,
        services: {
          service1: "Диагностика двигателя",
          service2: "Капитальный ремонт двигателя (мотора)",
          service3: "Проверка свечей зажигания",
          service4: "Диагностика системы зажигания",
          service5: "Замена свечей зажигания",
          service6: "Замена двигателя",
          service7: "Замена цепи/ремня ГРМ",
          service8: "Замена помпы",
          service9: "Диагностика и ремонт системы охлаждения",
          service10: "Регулировка клапанов",
          service11: "Замена прокладки ГБЦ",
        },
      },
      {
        title: "Тормозная система",
        icon: brakes_ico,
        services: {
          service1: "Замена тормозной жидкости",
          service2: "Замена колодок",
          service3: "Ремонт ручного тормоза (ремонт ручника)",
          service4: "Регулировка ручного тормоза (регулировка ручника)",
          service5: "Ремонт тормозов",
          service6: "Замена тормозных дисков",
          service7: "Расточка тормозных дисков",
          service8: "Прокачка тормозной системы",
          service9: "Замена тормозных шлангов",
          service10: "Замена тормозных цилиндров",
          service11: "Ремонт тормозных суппортов",
        },
      },
      {
        title: "Коробка передач/Трансмиссия",
        icon: car_manual_gearbox_ico,
        services: {
          service1: "Диагностика механической коробки передачь (МКПП)",
          service2: "Ремонт коробки передач (МКПП)",
          service3: "Ремонт коробки передач (АКПП)",
          service4: "Замена коробки передач (МКПП)",
          service5: "Замена сцепления (МКПП)",
          service6: "Регулировка сцепления (МКПП)",
          service7: "Ремонт ручки передач (кулисы)",
        },
      },
      {
        title: "Автоэлектрика",
        icon: energy_ico,
        services: {
          service1: "Проверка и замена аккумулятора",
          service2: "Диагностика генератора",
          service3: "Ремонт генератора",
          service4: "Замена генератора",
          service5: "Диагностика стартера",
          service6: "Ремонт стартера",
          service7: "Замена стартера",
        },
      },
      {
        title: "Рулевая рейка",
        icon: volan_ico,
        services: {
          service1: "Диагностика рулевой рейки",
          service2: "Замена рулевой рейки",
          service3: "Ремонт рулевой рейки",
        },
      },
      {
        title: "Кондиционер",
        icon: ac_ico,
        services: {
          service1: "Заправка кондиционера (заправка фреоном)",
        },
      },
      {
        title: "Фильтра",
        icon: car_filter_ico,
        services: {
          service1: "Замена топливного фильтра",
          service2: "Замена воздушного фильтра",
          service3: "Замена фильтра салона",
          service4: "Замена масляного фильтра",
          service5: "Замена фильтра коробки передач",
        },
      },
    ],
  },
  {
    id: 26,
    url: "glass",
    name: "Замена автостекол - Работаем только на выезд",
    image: glass_preview,
    bigImage: glass_big,
    description:
      "Замена, демонтаж, монтаж, реализация и все виды услуг в сфере лобовых боковых и задних автомобильных стекол. Подбор и реализация стёкла на заказ, атермальные стёкла (хамелеон) и многое другое. Выезжаем по всей Молдове, постоянным клиентам хорошая скидка. В пределах города выезд бесплатный.",
    phone: "+373 68 900 488",
    address: "г. Кишинёв, Только на выезд",
    regiune: ["Кишинёв"],
    sector: ["Ботаника"],
    mapAddress: "https://maps.app.goo.gl/cLrPwQHykkccB9K17",
    views: 1462,
    orar: "Пн-Сб: 08:00 — 18:00",
    gallery: [glass_img1, glass_img2, glass_img3, glass_img4, glass_img5],
    iframeSrc:
      "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d87055.34048087192!2d28.8382612059755!3d47.01119438650962!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x40c97c3628b769a1%3A0x37d1d6305749dd3c!2zQ2hpyJlpbsSDdSwgTW9sZG92YQ!5e0!3m2!1sen!2s!4v1725769275383!5m2!1sen!2s",
    icons: [
      {
        url: ico1,
        available: false,
      },
      {
        url: ico2,
        available: false,
      },
      {
        url: ico3,
        available: false,
      },
      {
        url: ico4,
        available: false,
      },
      {
        url: ico5,
        available: false,
      },
      {
        url: ico6,
        available: true,
      },
    ],
    serviceTypes: [
      {
        title: "Автостекла",
        icon: car_wind_ico,
        services: {
          service1: "Замена лобового стекла",
          service2: "Замена автостекол",
        },
      },
    ],
  },
  {
    id: 27,
    url: "electric",
    name: "Автоэлектрик с выездом",
    image: electric_preview,
    bigImage: electric_big,
    description:
      "Предоставляем ряд комплексных услуг по автоэлектрике: Автодиагностика, удаление ошибок, отключение сигнализаций, восстановление проводки, замена предохранителей, замена лампочек и фар, штекеров, разъёмов. Ремонт Авто электрики Автоэлектроники, диагностика, ремонт блоков ECU, ELV, BSM, ABS, панелей приборов, ремонт подкапотных жгутов, смена подсветки приборов, перенос данных, кодирование, адаптация,  запчасти в наличии, своя лаборатория, в теме 21 год, решаем любую проблему на аппаратном уровне. Делаем проблему Красного руля на всех поколениях VAG. Ремонтирую систему KEY LESS, ремонтирую блоки управления вентиляторами, работаю с осциллографом, решаю тупиковые ситуации. Находим утечку тока, в том числе связанную с незасыпанием CAN шины. Установим любое дополнительное оборудование. Звоните или пишите. Выезд. Снятие защиты компонентов.",
    phone: "+373 78 284 508",
    address: "г. Кишинёв, Центр, Букурешть 6",
    regiune: ["Кишинёв"],
    sector: ["Центр"],
    mapAddress: "https://maps.app.goo.gl/Wod3sEzaQD9HyGwA9",
    views: 1462,
    orar: "Пн-Сб: 08:00 — 18:00",
    gallery: [
      electric_img1,
      electric_img2,
      electric_img3,
      electric_img4,
      electric_img5,
    ],
    iframeSrc:
      "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d87055.34048087192!2d28.8382612059755!3d47.01119438650962!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x40c97c3628b769a1%3A0x37d1d6305749dd3c!2zQ2hpyJlpbsSDdSwgTW9sZG92YQ!5e0!3m2!1sen!2s!4v1725769275383!5m2!1sen!2s",
    icons: [
      {
        url: ico1,
        available: true,
      },
      {
        url: ico2,
        available: false,
      },
      {
        url: ico3,
        available: true,
      },
      {
        url: ico4,
        available: true,
      },
      {
        url: ico5,
        available: false,
      },
      {
        url: ico6,
        available: false,
      },
    ],
    serviceTypes: [
      {
        title: "Автоэлектрика",
        icon: energy_ico,
        services: {
          service1: "Диагностика проводки",
          service2: "Ремонт проводки",
          service3: "Замена предохранителей и реле",
          service4: "Проверка и замена аккумулятора",
          service5: "Диагностика генератора",
          service6: "Диагностика стартера",
          service7:
            "Установка и настройка доп. оборудования (камера, android auto)",
          service8: "Проверка и ремонт осветительных приборов",
          service9: "Ремонт приборной панели (спидометра)",
        },
      },
      {
        title: "EGR и сажевый фильтр",
        icon: catalyst_ico,
        services: {
          service1: "Компьютерное отключение клапана EGR",
          service2: "Удаление (вырезание) сажевого фильтра",
        },
      },
    ],
  },
];

// randomizer
function shuffleArray(array) {
  let shuffledArray = array.slice();
  for (let i = shuffledArray.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1));
    [shuffledArray[i], shuffledArray[j]] = [shuffledArray[j], shuffledArray[i]];
  }
  return shuffledArray;
}
const servicesRu = shuffleArray(array);

export default servicesRu;
