import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import "./components/i18";
import { DropDownProvider } from "./contexts/dropDown.context";
import { LanguageProvider } from "./contexts/language.context";
ReactDOM.createRoot(document.getElementById("root")).render(
  <React.StrictMode>
    <React.Suspense fallback="loading...">
		<LanguageProvider>
			<DropDownProvider>
				<App />
			</DropDownProvider>
		</LanguageProvider>
    </React.Suspense>
  </React.StrictMode>
);
