import React from 'react';
import Services from "../components/Home/Services";
import ServiceDetailHeader from "../layout/header/serviceDetailHeader";
import { useSearchParams } from "react-router-dom";
import { useTranslation } from 'react-i18next';

const ServiceList = () => {
  const [searchParams] = useSearchParams();
  const searchQuery = searchParams.get("query") || "";
  const { t } = useTranslation();

  return (
    <>
      <ServiceDetailHeader/>
      <div className="main-body">
        <div className="max-width">
          <h1 className="listHeader">{t("serviceListPage.listHeader")}</h1>
          <p className="listParagraph">{t("serviceListPage.listParagraph")}</p>
          <Services searchQuery={searchQuery} />
        </div>
      </div>
    </>
  );
};

export default ServiceList;
